<template>
  <PrintWrapper>
    <div class="waiBox mt10" id="printTest1">
      <div class="handle-box" v-if="isSendMail && !isInvoice">
        <button type="button" class="btn-send" @click="sendMail">
          {{ lang === 'en' ? 'Send Email' : '发送邮件' }}
        </button>
      </div>
      <div id="pdfDom">
        <!-- 头部 -->
        <div class="headBox clearfix">
          <div class="imgBox">
            <img v-if="detail.exportsRise == 'RG'" :src="RG" alt />
            <img v-else-if="detail.exportsRise == 'RP'" :src="RP" alt />
            <img v-else-if="detail.exportsRise == 'SW'" :src="SW" alt />
          </div>
          <div class="txtBox" v-if="detail">
            <p class="pTL">{{ isInvoice ? 'INVOICE' : 'PROFORMA INVOICE' }}</p>
            <p class="lblue">
              DATE:&nbsp;
              <i>{{ detail.date }}</i>
            </p>
            <p>
              PI NO:&nbsp;
              <em>{{ detail.piNO }}</em>
            </p>
            <p>
              CLIENT PO:&nbsp;
              <em>{{ detail.clientPO }}</em>
            </p>
          </div>
        </div>
        <!-- SHIP TO -->
        <div class="shipto clearfix">
          <h3>SHIP TO</h3>
          <div class="contBox" v-if="detail.addressVOS && detail.addressVOS.length">
            <div v-for="(item, i) in detail.addressVOS" :key="i">
              <p>{{ item.companyName }}</p>
              <p>{{ item.address }}</p>
              <p>{{ item.consigneeName }}</p>
              <p>{{ item.consigneePhone }}</p>
            </div>
          </div>
          <div v-else class="contBox" style="height: 64px"></div>
          <div class="cust" v-if="detail">
            <p>
              Cust Name:&nbsp;
              <i>{{ detail.custName }}</i>
            </p>
            <p>
              Attn:&nbsp;
              <i>{{ detail.linkman }}</i>
            </p>
            <p>
              Tel:&nbsp;
              <i>{{ detail.customerPhone }}</i>
            </p>
            <div>
              Add:&nbsp;
              <em>{{ detail.add }}</em>
            </div>
          </div>
        </div>
        <!-- 表格 -->
        <div>
          <table id="tableExcel" width="700px" border="1" cellspacing="0" cellpadding="5px">
            <thead>
              <tr>
                <th width="80px">Item Number</th>
                <th width="100px">Product Name</th>
                <th style="word-break: break-all">DESCRIPTION</th>
                <th>
                  QUANTITY
                  <br />
                  (units)
                </th>
                <th>
                  UNIT PRICE
                  <br />
                  (USD/unit)
                </th>
                <th>AMOUNT</th>
              </tr>
            </thead>

            <tbody v-for="(item, i) in tableData" :key="i">
              <tr>
                <td>
                  <p>{{ item.sku }}</p>
                </td>
                <td>
                  <p>{{ item.productEn }}</p>
                </td>
                <td style="text-align: left; word-break: break-all">
                  <p v-show="item.specification && item.specification != '/'">
                    Specification:{{ item.specification }};
                  </p>
                  <p class="text-warp">{{ item.productDescription }}</p>
                  <!-- <p>
                    <ErpWrapText :text="item.productDescription" />
                  </p> -->
                  <p class="text-warp">
                    {{ item.printingEffect }}
                    <!-- <ErpWrapText :text="item.printingEffect"   /> -->
                  </p>
                </td>
                <td>
                  <p>{{ item.amount }}</p>
                </td>
                <td>
                  <p v-if="isViewCost">{{ item.netPrice }}</p>
                  <NoViewCost v-else />
                </td>
                <td>
                  <p v-if="isViewCost">
                    {{ parseFloat(item.amount * item.netPrice).toFixed(2) }}
                  </p>
                  <NoViewCost v-else />
                </td>
              </tr>
              <tr v-show="item.setUpCharge">
                <td>Setup</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <span v-if="isViewCost">
                    {{ item.setUpCharge }}
                  </span>
                  <NoViewCost v-else />
                </td>
              </tr>
              <tr v-show="item.shippingFeeTotal > 0">
                <td>Shipping Fee</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <span v-if="isViewCost">
                    {{ item.shippingFeeTotal }}
                  </span>
                  <NoViewCost v-else />
                </td>
              </tr>
              <tr v-show="item.otherCharge">
                <td>Other Fee</td>
                <td></td>
                <td class="text-warp" style="text-align: left">
                  {{ item.chargeFees }}
                </td>
                <td></td>
                <td></td>
                <td>
                  <span v-if="isViewCost">
                    {{ item.otherCharge }}
                  </span>
                  <NoViewCost v-else />
                </td>
              </tr>
              <tr v-show="item.discountAmount">
                <td>Discount</td>
                <td></td>
                <td class="text-warp" style="text-align: left">
                  {{ item.discountReason }}
                </td>
                <td></td>
                <td></td>
                <td>
                  <span v-if="isViewCost">
                    {{ item.discountAmount > 0 ? '-' : ''
                    }}{{ item.discountAmount }}
                  </span>
                  <NoViewCost v-else />
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>TOTAL</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <div v-if="isViewCost">
                    <span v-if="detail.totle">
                      {{ parseFloat(detail.totle).toFixed(2) }}
                    </span>
                  </div>
                  <NoViewCost v-else />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <!-- 总计 -->
        <div class="tbxia">
          <p>
            Total:
            <i v-if="isViewCost">
              {{ detail.currency == '人民币' ? '￥' : '$' }}
              {{ detail.totle ? parseFloat(detail.totle).toFixed(2) : '0.00' }}
            </i>
            <NoViewCost v-else />
          </p>
          <p>
            Deposit:
            <i v-if="isViewCost">
              {{ detail.currency == '人民币' ? '￥' : '$' }}
              {{
              detail.deposit ? parseFloat(detail.deposit).toFixed(2) : '0.00'
              }}
            </i>
            <NoViewCost v-else />
          </p>
        </div>
        <!-- 左右列表 -->
        <div class="lrlist clearfix">
          <div class="llist" v-show="!isInvoice">
            <p style="font-size: 12px; font-weight: bold; line-height: 15px" v-show="detail.payNowLink">
              If paid by the credit card, click this link
              <a class="c_pointer" @click="payNow(detail.payNowLink)">
                Pay Now
              </a>
            </p>
            <br />
            <p>
              Warmly Reminder: 3% handling fee will be charged if paid by the
              credit card; You may avoid this charge by using the check or the
              wire transfer.
            </p>
          </div>

          <div v-if="detail.exportsRise == 'RG'" class="rlist">
            <p>Address for sending CHECK</p>
            <p>Rivers Garment Inc.</p>
            <p>13360 Molette St, Santa Fe Springs, CA 90670</p>
            <p>TEL: 1-562-968-0917&nbsp;Fax: 1-323-446-7173</p>
            <p>ATTN: Account Receiver</p>
          </div>
          <div v-else-if="detail.exportsRise == 'RP'" class="rlist">
            <p>Address for sending CHECK</p>
            <p>Rivers Promo Incorporation</p>
            <p>13360 Molette St, Santa Fe Springs, CA 90670</p>
            <p>TEL: 1-323-282-3208&nbsp;Fax: 1-323-446-7173</p>
            <p>ATTN: Account Receiver</p>
          </div>
          <div v-else-if="detail.exportsRise == 'SW'" class="rlist">
            <p>Address for sending CHECK</p>
            <p>Slight Worlds Inc.</p>
            <p>13360 Molette St, Santa Fe Springs, CA 90670</p>
            <p>Tel: (626)539-9965&nbsp;Fax: (626)552-3710</p>
            <p>ATTN: Account Receiver</p>
          </div>
        </div>
        <!-- 信息 -->
        <div class="ftTxt" v-show="!isInvoice">
          <p>Port of loading:&nbsp;{{ detail.portOfLoading }}</p>
          <p>Port of destination:&nbsp;{{ detail.destination }}</p>
          <p>Ship terms:&nbsp;{{ detail.shipTerms }}</p>
          <p>IN HANDS DATE:&nbsp;{{ detail.deliveryDate }}</p>
          <p>Payment Terms:&nbsp;{{ detail.paymentTerms }}</p>
        </div>
        <!-- name -->
        <div class="ftName">
          <label v-show="!isInvoice">Buyer</label>
          <input v-show="!isInvoice" type="text" readonly />
          <label>Sales</label>
          <input type="text" readonly :value="detail.sales" />
        </div>
        <!-- REMARK -->
        <div class="ftbb">
          <p>{{ detail.remark }}</p>
          <p></p>
        </div>
        <div v-if="detail.exportsRise == 'RG'" class="lastDiv">
          <p>
            By placing your order, you agree to the Rivers Garment’s Terms and
            Conditions.
          </p>
          <a style="text-decoration: underline">
            https://www.riversgarment.com/category.jhtm?cid=987
          </a>
        </div>
        <div v-else-if="detail.exportsRise == 'RP'" class="lastDiv">
          <p>
            By placing your order, you agree to the Rivers Promo’s Terms and
            Conditions.
          </p>
          <a style="text-decoration: underline">
            https://www.riverspromo.com/terms-and-conditions
          </a>
        </div>
        <div v-else-if="detail.exportsRise == 'SW'" class="lastDiv">
          <p>
            By placing your order, you agree to the Slight Worlds’s Terms and
            Conditions.
          </p>
          <a style="text-decoration: underline">
            http://www.slightworlds.com/category/979/Order-Terms.html
          </a>
        </div>
      </div>
    </div>
  </PrintWrapper>
</template>

<script>
  import { orderPrintOrder, orderPreviewOrder, orderpdf } from '@/api/order.js'
  import PrintWrapper from './printWrapper'
  import viewCost from '@/views/order/orderList/util'
  import NoViewCost from '@/views/purchase/purchaseOrder/components/no-view-cost'
  import { keyStr } from '@/config'
  import { base64Decode } from 'kits'
  import { mapGetters } from 'vuex'
  export default {
    props: ['isView'],
    data() {
      return {
        isViewCost: viewCost(),
        orderId: '',
        detail: {},
        RG: require('@/assets/images/RG.png'),
        RP: require('@/assets/images/RP.png'),
        SW: require('@/assets/images/SW.png'),
        isSendMail: true,
        custEmail: '',
        isInvoice: false,
        printObj: {
          id: 'printTest1',
          popTitle: '', //this.param.title
        },
        tableData: [],
      }
    },
    components: {
      PrintWrapper,
      NoViewCost,
    },
    created() {
      this.orderId = this.$route.query.orderId
      this.isInvoice = this.$route.query.isInvoice || false
      if (!this.isView) this.getPrintInfo()
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    methods: {
      // 获取打印信息
      getPrintInfo() {
        const params = {
          orderId: this.$route.query.orderId,
          printType: this.isInvoice ? 4 : 1,
          checkFlag: this.isViewCost ? 1 : 0,
        }
        if (params.orderId) {
          orderPrintOrder(params).then((res) => {
            if (res?.code === '000000') {
              this.detail = this.isInvoice
                ? res?.data?.printInvoiceVO
                : res?.data?.printContractVOEncode &&
                JSON.parse(base64Decode(res.data.printContractVOEncode, keyStr))
              this.custEmail = this.isInvoice
                ? this.detail.custEmail
                : this.detail.custEmail

              this.tableData = this.detail.orderProductVOS
              this.handleShippingFee()
            }

          })
        }
      },

      getPrintInfoView(data) {
        orderPreviewOrder(data).then((res) => {
          if (res?.code === '000000' && res?.data) {
            this.detail = res.data
            this.tableData = res.data.orderProductVOS
            this.handleShippingFee()
          }

        })
      },

      // 处理运费分摊，相同sku产品运费合并放在最下面
      handleShippingFee() {
        const list = this.tableData

        if (list?.length) {
          let [skus, de_weight] = [[], []]
          list.forEach((item) => skus.push(item.sku?.split('-')?.[0]))

          de_weight = Array.from(new Set(skus))
          if (de_weight.length != list.length) {
            let newArr = []
            for (let i = 0; i < de_weight.length; i++) {
              const filter_array = list.filter((item) =>
                item.sku.includes(de_weight[i])
              )

              if (filter_array.length > 1) {
                for (let i = 0; i < filter_array.length; i++) {
                  if (i === filter_array.length - 1) {
                    filter_array[i].shippingFeeTotal = this.utils.amountTotal(
                      filter_array,
                      'shippingFee',
                      2
                    )
                  }
                }
              } else if (filter_array.length === 1) {
                filter_array[0].shippingFeeTotal = filter_array[0].shippingFee
              }
              newArr = newArr.concat(filter_array)
            }

            this.tableData = newArr
          } else {
            this.tableData = list.map((item) => {
              return {
                ...item,
                shippingFeeTotal: item.shippingFee,
              }
            })
          }
        }
      },

      // 跳转
      payNow(href) {
        window.open(href, '_blank')
      },

      dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], filename, { type: mime })
      },

      // 获取pdf地址
      generatePdf() {
        orderpdf({
          orderId: this.orderId,
          printType: 1,
          checkFlag: this.isViewCost ? 1 : 0,
        }).then((res) => {
          if (res && res.code === '000000') {
            const _ = (obj, key) => (obj[key] ? obj[key] : '')

            let file = {
              enclosureName: `${this.detail.piNO}.pdf`,
              fileId: _(res.data, 'fileId'),
              enclosureSize: _(res.data, 'size'),
              enclosureUrl: _(res.data, 'path'),
              suffix: _(res.data, 'suffix'),
              uploadType: 1,
              mailEnclosureId: _(res.data, 'fileId'),
              source: 1,
            }
            let url = `${window.location.origin
              }/#/write?from=business&file=${encodeURI(
                JSON.stringify(file)
              )}&email=${this.custEmail}`
            this.utils.openWindow(url)
          }
        })
      },

      sendMail() {
        this.generatePdf()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .handle-box {
    padding: 10px;

    .btn-send {
      border: none;
      outline: none;
      padding: 6px 10px;
      color: #fff;
      background: #1790ff;
      border-radius: 3px;
      cursor: pointer;
      transition: all 0.4s;
    }

    .btn-send:hover {
      opacity: 0.8;
    }

    .check-images {
      margin-left: 10px;
    }
  }

  .waiBox {
    width: 700px;
    margin: 0 auto;
  }

  .imgBox {
    float: left;
    width: 274px;
    height: 134px;
  }

  .imgBox img {
    width: 347px;
    margin-left: 63px;
    height: auto;
  }

  .txtBox {
    width: 266px;
    float: left;
    margin-left: 156px;
  }

  .clearfix:before,
  .clearfix:after {
    content: '';
    display: block;
    clear: both;
  }

  .clearfix {
    zoom: 1;
  }

  .txtBox p {
    text-align: center;
    font-size: 15px;
  }

  .txtBox p em {
    color: red;
    font-size: 12px;
  }

  .pTL {
    font-size: 22px !important;

    line-height: 37px;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
  }

  /* SHIP TO */
  .shipto {
    margin-bottom: 20px;
  }

  .contBox {
    width: 377px;
    border: 1px solid #000000;
    line-height: 70px;
    float: left;
  }

  .contBox>div {
    border-bottom: 1px solid #000;
    padding: 5px;
  }

  .contBox>div:last-child {
    border-bottom: none;
  }

  .contBox p {
    line-height: 16px;
    font-size: 10px;
  }

  .cust {
    width: 264px;
    height: 118px;
    border: 1px solid #000000;
    background-color: #ffff66;
    margin-left: 54px;
    margin-top: -53px;
    float: left;
  }

  .cust p {
    font-size: 13px;
    line-height: 18px;
  }

  .cust p i {
    font-size: 10px;
    font-weight: normal;
    background-color: #ffff99;
    border-bottom: 1px solid #000;
  }

  .cust div {
    font-size: 13px;
    line-height: 19px;

    position: relative;
    top: 6px;
  }

  .cust div em {
    width: 227px;
    display: inline-block;
    background-color: hsla(30, 79%, 63%, 0.678);
    font-size: 10px;
    font-weight: normal;
    border-bottom: 1px solid #000;
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .cust p span {
    display: inline-block;
  }

  /* 表格 */
  #tableExcel {
    border-collapse: collapse;
  }

  #tableExcel tr td {
    height: 20px;
  }

  #tableExcel tbody tr td {
    font-size: 10px;
    text-align: center;
  }

  #tableExcel tfoot tr td {
    text-align: center;
  }

  .ftTxt {
    margin-top: 50px;
    font-size: 10px;
  }

  .ftName {
    text-align: right;
  }

  .ftName input {
    height: 20px;
    border: 0px;
    border-bottom: 1px solid #000000;
  }

  .tbxia {
    font-size: 14px;

    text-align: right;
    margin-top: 10px;
  }

  .tbxia i {
    font-weight: normal;
    margin-left: 10px;
  }

  /* 左右列表 */
  .lrlist {
    margin-top: 10px;
  }

  .llist {
    width: 345px;
    height: 130px;
    border: 1px solid #000000;
    float: left;
    border-radius: 5px;
  }

  .llist p {
    font-size: 12px;
  }

  .llist p span {
    color: red;
  }

  .lblue {
    color: blue;
  }

  .rlist {
    width: 345px;
    margin-left: 6px;
    height: 130px;
    border: 1px solid #000000;
    float: left;
    border-radius: 5px;
  }

  .rlist p {
    font-size: 13px;
    line-height: 1.5;

    text-align: right;
  }

  .rlist p:first-child {
    color: #663300;
  }

  /* REMARK */
  .ftbb {
    width: 100%;
    min-height: 100px;
    border: 1px solid #000000;
    margin-top: 10px;
    font-size: 14px;
  }

  .lastDiv p {
    font-size: 10px;
    text-align: left;
  }

  .lastDiv a {
    font-size: 10px;
    color: blue;

    text-align: left;
  }

  input {
    padding-left: 5px;
  }

  .text-warp {
    // white-space: pre-wrap;
    white-space: pre-line;
    text-align: left;
    word-break: break-word;
  }
</style>
