<!-- 销售订单退款申请 -->
<template>
  <el-dialog title="退款申请" width="95%" :visible.sync="modelVisible" :close-on-click-modal="false" :before-close="close">
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="收款类型" prop="financeType">
        <el-radio-group v-model="form.financeType">
          <el-radio :label="2" :disabled="true">销售订单</el-radio>
          <el-radio :label="1">销售退款</el-radio>
          <el-radio :label="3" :disabled="true">其他费用</el-radio>
        </el-radio-group>
      </el-form-item>

      <!-- 头部表格 -->
      <el-row>
        <el-table border class="input-table" :data="[1]" :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }">
          <el-table-column prop="date">
            <template slot="header">
              <i class="red">*</i>
              收款方
            </template>
            <template>
              <el-form-item prop="shroffAccountId">
                <el-input v-model="form.shroffAccountId" placeholder="请输入" clearable maxlength="50"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="date">
            <template slot="header">
              <i class="red">*</i>
              退款方式
            </template>
            <template>
              <el-form-item prop="financeMethodCode">
                <el-select v-model="form.financeMethodCode" placeholder="请选择" clearable @change="financeMethodChange">
                  <el-option v-for="(item, index) in paymentOptions" :key="index" :label="item.financeName"
                    :value="item.financeSetupId"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="票据单号">
            <template>
              <el-form-item prop="ticketNumber">
                <el-input v-model="form.ticketNumber" placeholder="请输入" clearable maxlength="50"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="date">
            <template slot="header">
              <i class="red">*</i>
              币种
            </template>
            <template>
              <el-form-item prop="currency">
                <el-select v-model="form.currency" placeholder="请选择" clearable @change="currencyChange">
                  <el-option v-for="(item, index) in currencyOptions" :key="index" :label="item.financeName"
                    :value="item.financeName"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="date">
            <template slot="header">
              <i class="red">*</i>
              退款总金额
            </template>
            <template>
              <el-form-item prop="amountCollected">
                <el-input v-model.trim="form.amountCollected" :min="0" :precision="3" placeholder="请输入" clearable
                  maxlength="10" @input="amountCollectedChange"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="date" width="240">
            <template slot="header">
              <i class="red">*</i>
              退款申请日期
            </template>
            <template>
              <el-form-item prop="amountTime">
                <el-date-picker size="small" v-model="form.amountTime" type="date" value-format="yyyy-MM-dd"
                  placeholder="年/月/日" clearable @change="changeAmountTime"></el-date-picker>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <!-- 中部表格 -->
      <el-row class="mt20">
        <el-row>
          <el-table class="input-table" :data="form.fovos" border :header-cell-style="{ textAlign: 'center' }"
            :cell-style="{ textAlign: 'center' }">
            <el-table-column prop="date" show-overflow-tooltip width="160">
              <template slot="header">
                <i class="red">*</i>
                订单编号
              </template>
              <template slot-scope="scope">
                <el-form-item :prop="'fovos.' + scope.$index + '.orderCode'" :rules="[
                    {
                      required: true,
                      message: '订单编号必填',
                      trigger: 'blur',
                    },
                  ]">
                  <el-input placeholder="请输入" v-model.trim="form.fovos[scope.$index].orderCode"
                    :disabled="true"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="客户姓名" show-overflow-tooltip width="120">
              <template slot-scope="scope">
                <div class="ell">
                  {{ scope.row.customerName || '--' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="公司名称" show-overflow-tooltip width="150">
              <template slot-scope="scope">
                <div class="ell">
                  {{ scope.row.companyName || '--' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="业务员" width="120">
              <template slot-scope="scope">
                <div class="ell">
                  {{ scope.row.businessName || '--' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="所属地区" width="120">
              <template slot-scope="scope">
                <div class="ell">
                  {{ scope.row.area || '--' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="币种" width="120">
              <template slot-scope="scope">
                {{ scope.row.currency || '--' }}
              </template>
            </el-table-column>
            <el-table-column label="订单金额" width="120">
              <template slot-scope="scope">
                <!-- 订单金额 -->
                <el-popover placement="bottom" width="800" trigger="click">
                  <orderMoneyDetail :ref="'orderMoneyDetail' + scope.$index" :currency="scope.row.currency"
                    :detailMsg="detailMsg" @showDetail="showDetail"></orderMoneyDetail>

                  <el-link type="primary" slot="reference" @click="handelTotalPriceClick(scope.row, scope.$index)">
                    {{
                    (scope.row.currency === '美元'
                    ? scope.row.totlePrice
                    : scope.row.rmbTotlePrice) | orderMoneyFormat
                    }}
                  </el-link>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="" width="120">
              <template slot="header">
                <p>已收金额</p>
                <p>（不含手续费）</p>
              </template>
              <template slot-scope="scope">
                {{ scope.row.amountReceived | orderMoneyFormat }}
              </template>
            </el-table-column>
            <el-table-column label="" width="120">
              <template slot="header">
                <p>实收金额</p>
                <p>（含手续费）</p>
              </template>
              <template slot-scope="scope">
                {{ scope.row.actualReceivedAmount | orderMoneyFormat }}
              </template>
            </el-table-column>
            <el-table-column label="退款金额" prop="amountCollected" width="150">
              <template slot="header">
                <i class="red">*</i>
                退款金额
              </template>
              <template slot-scope="scope">
                <el-form-item :prop="'fovos.' + scope.$index + '.amountCollected'" :rules="[
                    {
                      required: true,
                      message: '退款金额必填',
                      trigger: 'blur',
                    },
                    {
                      pattern: /(^[1-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/,
                      message: '>0且最多保留两位小数',
                      trigger: 'blur',
                    },
                  ]">
                  <el-input placeholder="请输入" maxlength="10" v-model.trim="form.fovos[scope.$index].amountCollected"
                    :min="0" :precision="2" clearable @change="fefundChange(scope.$index, scope.row)"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="退还手续费" width="120">
              <template slot-scope="scope">
                {{ scope.row.commissionAmount | orderMoneyFormat }}
              </template>
            </el-table-column>
            <el-table-column label="结算手续费" min-width="120">
              <template slot-scope="scope">
                {{form.financeType === 1 ? '--' : (scope.row.settleFee | orderMoneyFormat) }}
              </template>
            </el-table-column>
            <el-table-column label="实退金额" min-width="120">
              <template slot-scope="scope">
                {{
                (scope.row.amountCollected * 1 -
                scope.row.commissionAmount * 1)
                | orderMoneyFormat
                }}
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" min-width="120">
              <template slot-scope="scope">
                <el-button type="danger" plain icon="el-icon-delete" circle
                  @click="deletefovos(scope.$index, scope.row)"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <div class="money-sum">
          <span>合计</span>
          <span>退款金额:</span>
          -{{ moneySum[0] }}
          <span>退还手续费:</span>
          <span>-{{ moneySum[1] }}</span>
          <span>结算手续费:</span>
          -{{ form.financeType === 1 ? '--' : moneySum[2] }}
          <span>实退金额:</span>
          <span>-{{ moneySum[3] }}</span>
        </div>
      </el-row>
      <!-- 备注 -->
      <el-row :gutter="20" class="mt30">
        <el-col :span="10">
          <el-form-item label="退款原因" prop="remark">
            <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" v-model="form.remark" maxlength="500"
              show-word-limit placeholder="请填写退款原因，并提供客户收款信息" :rows="3"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="ml30">
          <el-form-item label="凭证/附件">
            <el-col :span="24" style="padding: 0">
              <ErpUpload ref="ErpUpload" :uploadParams="uploadParams" @uploadList="uploadList" @initUpload="initUpload">
              </ErpUpload>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 真實賬戶 -->
      <RefundRealAccount :accountInfo="realAccount" :financeMethod="form.financeMethod" ref="realAccount" />

      <el-row>
        <div class="mt50">
          <div slot="footer" class="dialog-footer text-center">
            <el-button type="primary" @click="save">确 认</el-button>
            <el-button @click="close">取 消</el-button>
          </div>
        </div>
      </el-row>
    </el-form>

    <!-- 审核模板 -->
    <AuditChoose ref="AuditChoose" @auditData="saveClick" />

    <!--收款单详情弹窗 -->
    <ReceiptDetailModelOut ref="ReceiptDetail" />
  </el-dialog>
</template>
<script>
  import { getFinanceDetailByInfoId } from '@/api/order'
  import {
    financeInsertPO,
    financeOrderPayVO,
    calculationServiceCharge,
  } from '@/api/finance/credit-note-add'
  import { selectOptions } from '@/api/finance/credit-manage-list'
  import orderMoneyDetail from '@/views/finance-center/credit-manage/components/order-money-detail.vue'
  import { mapGetters } from 'vuex'
  import AuditChoose from '@/components/AuditChoose'
  import ReceiptDetailModelOut from '@/views/finance-center/credit-manage/components/receipt-detail-model'
  import RefundRealAccount from '@/components/RefundRealAccount/index'
  import { accountFormData } from '@/components/RefundRealAccount/data'
  import { mult, deepCopy } from 'kits'
  const math = require('mathjs')
  export default {
    components: {
      AuditChoose,
      orderMoneyDetail,
      ReceiptDetailModelOut,
      RefundRealAccount
    },
    data() {
      return {
        type: null, // 1：销售订单列表入口，2：销售订单详情入口
        uploadParams: {
          amount: 9,
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        form: {
          entryType: 1,
          orderType: 1,
          overchargeType: 1,
          financeType: 1, // 收款类型
          shroffAccountId: '', // 付款方id
          financeMethod: '', // 收款方式名称
          financeMethodCode: '', // 收款方式标识
          ticketNumber: '', // 票据单号
          currency: '', // 币种
          amountCollected: '', // 退款金额
          amountTime: '', // 收款日期
          remark: '', // 备注
          fovos: [],
          realAccountFlag: 0
        },
        realAccount: {
          ...accountFormData
        },
        rules: {
          financeType: [
            { required: true, message: '请选择收款类型', trigger: 'change' },
          ],
          shroffAccountId: [
            { required: true, message: '请输入付款方', trigger: 'change' },
          ],
          financeMethodCode: [
            { required: true, message: '请选择收款方式', trigger: 'change' },
          ],
          currency: [
            { required: true, message: '请选择币种', trigger: 'change' },
          ],
          amountCollected: [
            { required: true, message: '请输入退款总金额', trigger: 'blur' },
            {
              pattern: /(^[1-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/,
              message: '>0且最多保留两位小数',
              trigger: 'blur',
            },
          ],
          amountTime: [
            { required: true, message: '请选择退款申请日期', trigger: 'blur' },
          ],
          remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
        },
        paymentOptions: [], // 收款方式列表
        currencyOptions: [], // 币种列表
        currentChartType: '',
        modelVisible: false,
        detailMsg: {},
        commissionAmount: 0, // 国际手续费
        settleFee: 0, // 结算手续费
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      moneySum() {
        //退款费用合计
        let vos = this.form.fovos
        const sum = []
        sum[0] = vos.reduce((prev, next) => {
          prev += next.amountCollected * 1000
          return prev
        }, 0)
        sum[1] = vos.reduce((prev, next) => {
          prev += next.commissionAmount * 1000
          return prev
        }, 0)
        sum[2] = vos.reduce((prev, next) => {
          prev += next.settleFee * 1000
          return prev
        }, 0)
        sum[3] = vos.reduce((prev, next) => {
          prev += next.amountCollected * 1000 - next.commissionAmount * 1000
          return prev
        }, 0)
        return sum.map((item) => {
          if (Number.isNaN(item) || item === 0) {
            return '-'
          } else {
            return (item / 1000).toFixed(2)
          }
        })
      },
    },
    methods: {
      // 初始化
      init(rows, type) {
        this.form.fovos = rows.map((item) => {
          return {
            orderId: item.orderId, // 订单Id
            orderCode: item.orderCode, // 订单编号
            customerName: item.customerName, // 客户姓名
            companyName: item.companyName, // 公司名称
            businessId: item.businessId, // 业务员id
            businessName: item.businessName, // 业务员
            area: item.area, // 所属地区
            currency: item.currency, // 币种
            totlePrice: (item.totlePrice - item.deductionAmount).toFixed(3), // 订单金额美元
            rmbTotlePrice: (item.rmbTotlePrice - item.deductionAmount).toFixed(
              3
            ), // 订单金额人民币
            amountReceived: item.actualReceivablePrice || 0, // 已收金额
            actualReceivedAmount:item.actualReceivedAmount || 0, //实收金额
            amountCollected: '', // 退款金额
            outstandingAmount: item.outstandingAmount, // 未收金额
            commissionAmount: 0, // 国际手续费
            settleFee: 0, // 结算手续费
            orderType: 1, //主订单
            infoId: item.infoId, //客户id
          }
        })

        this.getSelectOptions(1)
        this.getSelectOptions(3)
        // 根据infoid查找最后一次收款（出）信息
        this.getFinanceDetaill(rows[0].infoId)
        // 赋值入口来源，保存回调需要
        this.type = type
        this.modelVisible = true
        this.realAccount = {
          ...accountFormData
        }
      },

      // 获取所有下拉框数据
      async getSelectOptions(type) {
        // 1币种设置 2发票设置 3支付方式 4科目 5费用杂项
        let response = await selectOptions({
          financeType: Number(type),
        })
        if (response.code === '000000') {
          if (type === 1) {
            this.currencyOptions = response.data
          }
          if (type === 3) {
            this.paymentOptions = response.data
          }
        }
      },

      getFinanceDetaill(id) {
        getFinanceDetailByInfoId({ infoId: id }).then((res) => {
          let data = res?.data ?? {}

          this.form.shroffAccountId = data?.shroffAccountId
          this.form.financeMethod = data?.financeMethod
          this.form.currency = data?.currency
          this.form.financeMethodCode = data?.financeMethodCode
        })
      },

      // 获取收款方式中文名
      financeMethodChange(val) {
        let obj = this.paymentOptions.find(
          (item) => item.financeSetupId === val
        )
        this.form.financeMethod = obj?.financeName

        this.amountCollectedChange()
        this.$refs.realAccount.clearValidate()
      },

      matchCurrency(str) {
        return str === '美元' ? '$' : '￥'
      },

      deletefovos(index, val) {
        if (this.form.fovos.length === 1) {
          this.$message.warning('至少保留一条数据!')
          return false
        }
        this.$confirm(`是否确认删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.form.fovos.splice(index, 1)
          })
          .catch(() => { })
      },

      // 文件上传
      uploadList(fileList) {
        this.form.financeUrl = JSON.stringify(fileList)
      },

      initUpload(data) {
        this.$refs.ErpUpload.initUpload(data)
      },

      // 显示流程设置
      showFlowSet() {
        let params = {
          auditType: 13,
          status: 0,
          tenantId: this.userInfo.tenantId,
        }
        this.pubApi.getAuditTemplates(params).then((response) => {
          if (!response.data.length) {
            this.$baseMessage(
              '请联系管理员配置审核模板',
              'warning',
              false,
              'erp-hey-message-warning'
            )
          } else {
            this.$refs['AuditChoose'].showAddEdit(params)
          }
        })
      },

      // 保存审核模板信息
      saveClick(data) {
        this.form.auditObjectDTO = data
        this.refundSave()
      },

      //币种选择
      currencyChange(val) {
        this.currentChartType = val
        this.amountCollectedChange()
      },

      // 保存
      save() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            // 校验真实账号信息
            this.$refs.realAccount.validateAccountForm().then(valid2 => {
              if (valid2) {
                // 判断币种是否一致
                this.currentChartType = this.form.currency
                let st = this.form.fovos.every((item) => {
                  return item.currency == this.currentChartType
                })
                if (!st) {
                  this.$message.warning('订单币种与收款单币种不一致')
                  return
                }

                let totalAmountCollected = 0
                if (this.form.fovos.length > 1) {
                  totalAmountCollected = this.form.fovos.reduce((pre, cur) => {
                    return Number(pre.amountCollected) + Number(cur.amountCollected)
                  })
                } else {
                  totalAmountCollected = this.form.fovos[0].amountCollected
                }
                if (totalAmountCollected != this.form.amountCollected) {
                  this.$message.warning('订单退款金额与退款总金额不一致！')
                  return
                }
                // 实退金额不能大于已收金额
                let boo = this.form.fovos.every(
                  (item) =>
                    mult((item.amountCollected * 1).toFixed(3), 1000) -
                    mult((item.commissionAmount * 1).toFixed(3), 1000) >
                    mult((item.amountReceived * 1)?.toFixed(3), 1000)
                )
                if (boo) {
                  this.$message.warning('实退金额不能大于已收金额')
                  return false
                }
                // 显示审核弹窗
                this.showFlowSet()
              }
            })

          } else {
            return false
          }
        })
      },

      // 销售退款保存
      refundSave() {
        const orderCode = this.form.fovos[0].orderCode
        this.form.fovos = this.form.fovos.map((item) => {
          return {
            ...item,
            totlePrice:
              item.currency === '美元' ? item.totlePrice : item.rmbTotlePrice, // 根据币种判断totlePrice取对应的值
            amountReceivable: (
              item.amountCollected - item.commissionAmount
            ).toFixed(3),
          }
        })
        let form = deepCopy(this.form)
        // 处理真实账户信息
        form.realAccountFlag = this.realAccount.realAccountFlag
        form.realAccount = this.realAccount
        financeInsertPO(form).then((res) => {
          if (res?.code === '000000') {
            this.$message.success('操作成功！')
            this.close()
            if (this.type == 1) {
              this.$parent.initLoadingList()
            } else {
              this.$parent.getDetail(orderCode, '2')
            }
          }
        })
      },

      //处理点击订单金额显示详情
      handelTotalPriceClick(e, index) {
        this.detailMsg = {}
        let refName = `orderMoneyDetail${index}`
        financeOrderPayVO({ orderCode: e.orderCode }).then((res) => {
          if (res.code === '000000') {
            this.detailMsg = res.data
          }
        })
      },

      // 退款金额变动 退款金额不能大于已收金额
      fefundChange(index, val) {
        this.calcCommissionAmount()
      },

      // 点击收款单号回调
      showDetail(val) {
        this.$refs['ReceiptDetail'].showReceiptDetail(
          val.financeCode,
          val.financeStatus
        )
      },

      // 取消
      close() {
        this.form = {}
        this.modelVisible = false
        this.$refs.form.resetFields()
        this.form = this.$options.data().form
        this.$refs.ErpUpload.uploadListEmpty()
      },

      // 收款金额获取手续费
      amountCollectedChange() {
        if (!this.form.amountTime) return
        let params = {
          amountCollected: this.form.amountCollected,
          currency: this.form.currency,
          financeSetupId: this.form.financeMethodCode,
          entryType: this.form.entryType,
        }
        let bool = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/.test(
          params.amountCollected
        )
        if (Object.values(params).every((item) => !!item) && bool) {
          calculationServiceCharge({
            ...params,
            entryType: this.form.entryType,
          }).then((res) => {
            this.commissionAmount = res?.data?.commissionAmount // 国际手续费
            this.settleFee = res?.data?.settleFee // 结算手续费

            this.calcCommissionAmount()
          })
        }
      },

      // 改变收款时间
      changeAmountTime() {
        this.amountCollectedChange()
      },

      // 计算手续费金额
      calcCommissionAmount() {
        let commissionAmount = this.commissionAmount
        let settleFee = this.settleFee
        let fovos = this.form.fovos
        if (fovos.length && fovos.every((item) => item.amountCollected != '')) {
          const total = this.utils.amountTotal(fovos, 'amountCollected', 3)
          for (let i = 0; i < fovos.length; i++) {
            // 赋值国际手续费
            this.$set(
              fovos[i],
              'commissionAmount',
              commissionAmount > 0
                ? (
                  (fovos[i].amountCollected / total) *
                  commissionAmount
                ).toFixed(3)
                : 0
            )
            let dt = math.format((fovos[i].amountCollected / total) * settleFee)
            this.$set(
              fovos[i],
              'settleFee',
              settleFee > 0 ? Number(dt).toFixed(3) : 0
            )
          }
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-dialog__body {
      padding-top: 10px;
    }
  }

  .money-sum {
    color: #666;
    text-align: right;
    padding: 5px;

    span {
      margin: 0 5px;
    }
  }
</style>
