<!--
  功能：功能描述
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年07月11日 15:02:40
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <span>{{ NO_VIEW_COST_SHOW }}</span>
</template>

<script>
import { NO_VIEW_COST_SHOW } from '../utils'
export default {
  name: 'NoViewCost',
  data() {
    return {
      NO_VIEW_COST_SHOW,
    }
  },
}
</script> 

<style scoped>
</style>
