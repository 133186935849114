<template>
  <el-dialog
    :title="$t(title)"
    width="600px"
    class="address"
    :visible.sync="addressAddVisible"
    :close-on-click-modal="false"
    :before-close="close"
    :append-to-body="true"
    :modal-append-to-body="true"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      :label-position="lang === 'en' ? 'top' : 'left'"
    >
      <!-- 收货公司 -->
      <el-form-item
        prop="companyName"
        :label="$t('cusDetail.ReceivingCompany')"
        :rules="[
          {
            required: true,
            trigger: 'blur',
            message: $t('placeholder.plsInput'),
          },
        ]"
      >
        <el-input
          v-model="form.companyName"
          v-trim
          maxlength="500"
          type="textarea"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <!-- label="收货人名称" -->
      <el-form-item
        prop="consigneeName"
        :label="$t('cusDetail.Receiver')"
        :rules="[
          {
            required: true,
            trigger: 'blur',
            message: $t('placeholder.plsInput'),
          },
        ]"
      >
        <el-input
          v-model="form.consigneeName"
          v-trim
          maxlength="50"
          clearable
        ></el-input>
      </el-form-item>
      <!-- label="收货人电话" -->
      <el-form-item
        prop="consigneePhone"
        :label="$t('cusDetail.ContactNumber')"
        :rules="[
          {
            required: true,
            trigger: 'blur',
            message: $t('placeholder.plsInput'),
          },
        ]"
      >
        <el-input
          v-model="form.consigneePhone"
          v-trim
          maxlength="50"
          clearable
        ></el-input>
      </el-form-item>
      <!-- label="国家" -->
      <el-form-item
        prop="nation"
        :label="$t('cusDetail.Country')"
        :rules="[
          {
            required: true,
            trigger: 'blur',
            message: $t('placeholder.plsInput'),
          },
        ]"
      >
        <el-input v-model="form.nation"    v-trim maxlength="50" clearable></el-input>
      </el-form-item>
      <!-- label="邮编" -->
      <el-form-item
        prop="postcode"
        :label="$t('cusDetail.ZipCode')"
        :rules="[
          {
            required: true,
            trigger: 'blur',
            message: $t('placeholder.plsInput'),
          },
        ]"
      >
        <el-input v-model="form.postcode"    v-trim maxlength="50" clearable></el-input>
      </el-form-item>
      <!-- label="州/省" -->
      <el-form-item
        prop="province"
        :label="$t('cusDetail.StateProvince')"
        :rules="[
          {
            required: true,
            trigger: 'blur',
            message: $t('placeholder.plsInput'),
          },
        ]"
      >
        <el-input v-model="form.province"    v-trim  maxlength="50" clearable></el-input>
      </el-form-item>
      <!-- label="城市" -->
      <el-form-item
        prop="city"
        :label="$t('cusDetail.City')"
        :rules="[
          {
            required: true,
            trigger: 'blur',
            message: $t('placeholder.plsInput'),
          },
        ]"
      >
        <el-input v-model="form.city"    v-trim maxlength="50" clearable></el-input>
      </el-form-item>
      <!-- label="街道地址" -->
      <el-form-item
        prop="street"
        :label="$t('cusDetail.StreetAddress')"
        :rules="[
          {
            required: true,
            trigger: 'blur',
            message: $t('placeholder.plsInput'),
          },
        ]"
      >
        <el-input v-model="form.street" maxlength="200"    v-trim clearable></el-input>
      </el-form-item>
      <!-- label="单元号地址" -->
      <el-form-item prop="unit" :label="$t('cusDetail.UnitNoAddress')">
        <el-input v-model="form.unit" maxlength="50"    v-trim clearable></el-input>
      </el-form-item>
      <!-- label="完整地址" -->
      <el-form-item :label="$t('cusDetail.FullAddress')" prop="address">
        {{ addressComputed }}
      </el-form-item>
    </el-form>

    <div class="text-center">
      <!-- 确认 -->
      <el-button type="primary" @click="save">
        {{ $t('cusDetail.Confirm') }}
      </el-button>
      <!-- 取消 -->
      <el-button @click="close()">{{ $t('cusDetail.Cancel') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    props: ['addressList'],
    data() {
      return {
        title: 'dialogTitle.addreceivinginformation',
        form: {
          companyName: '',
          consigneeName: '',
          consigneePhone: '',
          nation: '',
          postcode: '',
          province: '',
          city: '',
          street: '',
          unit: '',
          address: '',
          defaultAdress: 0,
        },
        addressAddVisible: false,
        rules: {},
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      addressComputed() {
        let form = this.form
        let address =
          form.street +
          ' ' +
          (form.unit ? form.unit + ' ' : '') +
          form.city +
          ' ' +
          form.province +
          ' ' +
          form.postcode
        form.address = address
        return address
      },
    },
    methods: {
      // 保存
      save() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            const form = {
              addressConfigId: new Date().getTime(),
              configFlag: new Date().getTime(),
              ...this.form,
            }

            let [flag, list] = [false, this.addressList]
            for (let i = 0; i < list.length; i++) {
              if (
                list[i].address == form.address &&
                list[i].companyName === form.companyName &&
                list[i].consigneeName === form.consigneeName &&
                list[i].consigneePhone === form.consigneePhone
              ) {
                flag = true
                break
              }
            }

            if (flag) {
              const msg =
                this.lang === 'en'
                  ? 'The delivery address already exists!'
                  : '收货地址已存在！'
              this.$message.warning(msg)
            } else {
              this.$emit('addressConfigSave', form)
              this.close()
            }
          } else {
            return false
          }
        })
      },

      // 关闭弹窗重置表单
      close() {
        this.$refs.ruleForm.resetFields()
        this.addressAddVisible = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .address {
    .el-form-item {
      margin-bottom: 18px;
    }
  }
</style>
