<template>
  <div class="log-table-wrapper">
    <ErpTable
      :page-number.sync="page.pageNo"
      :page-size.sync="page.pageLe"
      :table-data="tableData"
      :total="total"
      :extra-height="extraHeight"
      class="table-fixed"
      @query="getList"
      @update:pageSize="page.pageNo = 1"
      @update:pageNumber="(val) => (page.pageNo = val)"
    >
      <el-table-column
        v-if="showModule"
        :label="$t('Documentary.Belongingmodule')"
        width="150"
        align="center"
        prop="moduleName"
        show-overflow-tooltip
      />
      <el-table-column
        v-for="(item, index) in columns"
        :key="index"
        :label="$t(item.tKey)"
        :width="item.width || 'auto'"
        :align="item.prop == 'operationContent' ? 'left' : 'center'"
        :prop="item.prop"
      >
        <template slot-scope="scope">
          <p v-if="item.prop != 'operationContent'" class="text-ellipsis">
            {{ scope.row[item.prop] || '--' }}
          </p>
          <div v-else class="text-ellipsis">
            <div>
              <p>【{{ getText(scope.row.operationType) }}】</p>
              <!-- <p v-html="scope.row.operationContent"></p> -->
              <p>
                {{ replaceBR(scope.row.operationContent) }}
              </p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('Documentary.operate')"
        width="120"
        align="center"
      >
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click="showDetail(scope.row)">
            详情
          </el-button>
        </template>
      </el-table-column>
    </ErpTable>
    <!-- 操作日志详情 -->
    <el-drawer :visible.sync="showDrawer" :title="$t('Documentary.OLD')">
      <LogDetail :log="currentLog" />
    </el-drawer>
  </div>
</template>

<script>
  import LogDetail from './operate-log-detail.vue'
  import { type2LogText } from './data'
  import { systemInteractor } from '@/core'
  const columns = [
    {
      label: '操作人',
      width: '120px',
      prop: 'operatorName',
      tKey: 'Documentary.Operator',
    },
    {
      label: '操作时间',
      width: '180px',
      prop: 'operationTime',
      tKey: 'Documentary.OPT',
    },
    {
      label: '操作记录',
      prop: 'operationContent',
      tKey: 'Documentary.OPR',
    },
  ]
  export default {
    name: 'OperateLogs',
    components: {
      LogDetail,
    },
    props: {
      showModule: {
        //是否展示模块归属名
        type: Boolean,
        default: false,
      },
      moduleType: {
        // 日志模块名称
        type: Number,
        default: 0,
      },
      extraHeight: {
        // 计算列表高度的额外高度
        type: Number,
        default: 60,
      },
      searchParams: {
        type: Object,
        default: () => ({}),
      },
      bizId: {
        //业务ID

        type: String,
        default: '',
      },
    },
    data() {
      return {
        columns: Object.freeze(columns), // 表格列
        page: {
          //分页数据
          pageNo: 1,
          pageLe: 10,
        },
        total: 100, // 列表总条数
        tableData: [], //列表数据
        currentLog: {}, //当前查看的日志
        showDrawer: false, //是否展示抽屉
      }
    },
    created() {
      this.getList()
    },

    methods: {
      replaceBR(content) {
        if (content && content.includes('<br/>')) {
          content = content.replace(/<br\s*\/?>/g, ';')
        }
        return content
      },
      // 获取操作日志列表
      getList() {
        const reqData = {
          ...this.searchParams,
          ...this.page,
          bizId: this.bizId,
        }
        systemInteractor.operationLogListPage(reqData).then((res) => {
          this.tableData = res?.data
          this.total = res?.total
        })
      },

      // 搜索
      search() {
        this.page = {
          pageNo: 1,
          pageLe: 10,
        }
        this.getList()
      },

      //参数处理
      _handleQueryParams() {},

      //操作
      showDetail(log) {
        this.currentLog = log
        this.$nextTick(() => {
          this.showDrawer = true
        })
      },

      getText(num) {
        return type2LogText[num]
      },
    },
  }
</script>

<style lang="scss" scoped>
  .operationContent {
    width: 100%;
    overflow: hidden;
    span {
      width: calc(100% - 20px);
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .text-ellipsis {
    -webkit-line-clamp: 3; /*（用来限制在一个块元素显示的文本的行数，3 表示最多显示3 行。为了实现该效果，它需要组合其他的 WebKit 属性）*/
    display: -webkit-box; /*（和 webkit-line-clamp 结合使用，将对象作为弹性伸缩盒子模型显示 ）*/
    -webkit-box-orient: vertical; /*（和 webkit-line-clamp 结合使用 ，设置或检索伸缩盒对象的子元素的排列方式 ）*/
    overflow: hidden; /*（文本溢出限定的宽度就隐藏内容）*/
    text-overflow: ellipsis; /*（多行文本的情况下，用省略号 “…” 隐藏溢出范围的文本)*/
  }
  ::v-deep {
    .el-table__header,
    .el-table__body {
      border-left: 1px solid #ebeef5;
    }
  }
</style>
