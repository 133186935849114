<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogCreditVisible"
    width="95%"
    @close="close"
    :close-on-click-modal="false"
    v-if="dialogCreditVisible"
    append-to-body
  >
    <div class="dialog-content">
      <el-row>
        <el-col :span="12" style="white-space: nowrap">
          <!-- <el-form-item> -->
          <span style="margin-right: 10px">
            <i style="color: #ff4d4f">*</i>
            收款类型
          </span>
          <el-radio-group
            :disabled="isResubmit"
            v-model="form.financeType"
            @change="radioChange"
          >
            <el-radio :label="1" :key="1">销售订单</el-radio>
            <el-radio :label="3" :key="3">销售退款</el-radio>
            <el-radio :label="2" :key="2">其他费用</el-radio>
          </el-radio-group>
          <!-- </el-form-item> -->
        </el-col>
      </el-row>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="80px"
        label-position="left"
        v-if="form.financeType !== 3"
      >
        <!-- 头部表格 -->
        <el-row class="top-table-content">
          <div class="c-table-wrap">
            <table class="custom-table mt10">
              <thead>
                <tr>
                  <th>
                    <span>
                      <i>*</i>
                      付款方
                    </span>
                  </th>
                  <th>
                    <span>
                      <i>*</i>
                      收款方式
                    </span>
                  </th>
                  <th>
                    <span>
                      <i v-if="ticketNumberRequired == 1">*</i>
                      票据单号
                    </span>
                  </th>
                  <th>
                    <span>
                      <i>*</i>
                      币种
                    </span>
                  </th>
                  <th>
                    <span>
                      <i>*</i>
                      收款金额
                    </span>
                  </th>
                  <th>
                    <span>
                      <i>*</i>
                      收款日期
                    </span>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <el-form-item prop="shroffAccountId">
                      <el-input
                        v-model="form.shroffAccountId"
                        placeholder="请输入"
                      ></el-input>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item prop="financeMethodCode">
                      <el-select
                        v-model="form.financeMethodCode"
                        placeholder="请选择"
                        @change="financeMethodChange"
                      >
                        <el-option
                          v-for="(item, index) in paymentOptions"
                          :key="index"
                          :label="item.financeName"
                          :value="item.financeSetupId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item prop="ticketNumber">
                      <el-input
                        v-model="form.ticketNumber"
                        placeholder="请输入"
                      ></el-input>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item prop="currency">
                      <el-select
                        v-model="form.currency"
                        placeholder="请选择"
                        @change="currencyChanage"
                      >
                        <el-option
                          v-for="(item, index) in currencyOptions"
                          :key="index"
                          :label="item.financeName"
                          :value="item.financeName"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item prop="amountCollected">
                      <el-input
                        v-model.trim="form.amountCollected"
                        controls-position="right"
                        :min="0"
                        :precision="3"
                        placeholder="请输入"
                        @input="amountCollectedChange"
                      ></el-input>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item prop="amountTime">
                      <el-date-picker
                        size="small"
                        v-model="form.amountTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="年/月/日"
                        @change="changeAmountTime"
                      ></el-date-picker>
                    </el-form-item>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </el-row>
        <!-- 中部表格 -->
        <el-row class="mt20" v-if="showMiddleTable">
          <el-row>
            <div class="c-table-wrap">
              <table class="custom-table mt10">
                <thead>
                  <tr>
                    <th width="150" minWidth="100">
                      <span>
                        <i
                          v-show="
                            form.fovos && form.fovos.length ? true : false
                          "
                        >
                          *
                        </i>
                        订单编号
                      </span>
                    </th>
                    <th width="100" minWidth="50">客户姓名</th>
                    <th width="150" minWidth="100">公司名称</th>
                    <th width="80" minWidth="50">客户代表</th>
                    <th width="80" minWidth="50">所属地区</th>
                    <th width="80" minWidth="50">币种</th>
                    <th width="120" minWidth="50">订单金额</th>
                    <th width="120" minWidth="50">未收金额</th>
                    <th width="150" minWidth="150">
                      <span>
                        <i
                          v-show="
                            form.fovos && form.fovos.length ? true : false
                          "
                        >
                          *
                        </i>
                        实收金额
                      </span>
                    </th>
                    <th width="150" minWidth="100">
                      <span>
                        <i
                          v-show="
                            form.fovos && form.fovos.length ? true : false
                          "
                        >
                          *
                        </i>
                        手续费金额
                      </span>
                    </th>
                    <th width="120" minWidth="50">应收金额</th>
                    <th width="50">操作</th>
                  </tr>
                </thead>
                <tbody v-if="form.fovos && form.fovos.length">
                  <tr v-for="(item, i) in form.fovos" :key="i">
                    <td>
                      <el-form-item
                        style="width: 150px"
                        :prop="'fovos.' + i + '.orderCode'"
                        :rules="[
                          {
                            required: form.fovos.length ? true : false,
                            message: '订单编号必填',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          v-model.trim="item.orderCode"
                          placeholder="请输入"
                          @change="
                            (val) => {
                              handleOrderIdInput(val, i)
                            }
                          "
                        ></el-input>
                      </el-form-item>
                    </td>
                    <!-- 客户姓名 -->
                    <td>{{ item.customerName || '--' }}</td>
                    <!-- 公司名称 -->
                    <td>{{ item.companyName || '--' }}</td>
                    <!-- 客户代表 -->
                    <td>{{ item.businessName || '--' }}</td>
                    <!-- 地区 -->
                    <td>{{ item.area || '--' }}</td>
                    <!-- 币种 -->
                    <td>{{ item.currency || '--' }}</td>
                    <td>
                      <!-- 订单金额 -->
                      <el-popover
                        placement="bottom"
                        width="800"
                        trigger="click"
                      >
                        <order-money-detail
                          :ref="'orderMoneyDetail' + i"
                          :detailMsg="detailMsg"
                          :currency="item.currency"
                          @showDetail="showDetail"
                        ></order-money-detail>
                        <span
                          slot="reference"
                          class="blue-text"
                          @click="
                            (e) => {
                              handelTotalPriceClick(item, i)
                            }
                          "
                        >
                          <span v-if="item.totlePrice">
                            {{ matchCurrency(item.currency) }}
                          </span>
                          <span>
                            {{ item.totlePrice | orderMoneyFormat }}
                          </span>
                        </span>
                      </el-popover>
                    </td>
                    <!-- 未收金额 -->
                    <td>
                      <span class="red">
                        <span v-if="item.outstandingAmount">
                          {{ matchCurrency(item.currency) }}
                        </span>
                        <span>
                          {{ item.outstandingAmount | orderMoneyFormat }}
                        </span>
                      </span>
                    </td>
                    <!-- 实收金额 -->
                    <td>
                      <el-form-item
                        :prop="'fovos.' + i + '.amountCollected'"
                        :rules="[
                          {
                            required: form.fovos.length ? true : false,
                            message: '实收金额必填',
                            trigger: 'blur',
                          },
                          {
                            pattern: /^\d+(\.\d{1,2})?$/,
                            message: $t('placeholder.decimals2'),
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          v-model.trim="item.amountCollected"
                          controls-position="right"
                          @blur="amountCollectedInput"
                          :min="0"
                          :precision="3"
                        ></el-input>
                      </el-form-item>
                    </td>
                    <!-- 手续费金额 -->
                    <td>
                      <el-form-item
                        :prop="'fovos.' + i + '.commissionAmount'"
                        :rules="[
                          {
                            required: form.fovos.length ? true : false,
                            message: '手续费金额必填',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <!-- <el-input v-model="item.commissionAmount" placeholder="请输入" :disabled="true" @change="
                            (val) => {
                              commissionAmountInput(val, i)
                            }
                          "></el-input> -->

                        <span>
                          {{ item.commissionAmount | orderMoneyFormat }}
                        </span>
                      </el-form-item>
                    </td>
                    <!-- 应收金额 -->
                    <td>
                      <span>
                        {{
                          (item.amountReceivable =
                            item.amountCollected * 1 -
                            item.commissionAmount * 1).toFixed(2)
                        }}
                      </span>
                    </td>
                    <td class="text-center">
                      <el-button
                        type="danger"
                        plain
                        icon="el-icon-delete"
                        circle
                        @click="deleteNewLine(i)"
                      ></el-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-row>
          <!-- 费用合计 -->
          <!-- 销售订单 -->
          <div v-if="form.financeType == 1" class="money-sum">
            <span>合计</span>

            <span>实收金额:</span>
            {{ moneySum[0] | orderMoneyFormat }}
            <span>手续费:</span>
            <span>
              {{ (moneySum[1] == '--' ? 0 : moneySum[1]) | orderMoneyFormat }}
            </span>
            <span>应收金额:</span>
            <span>{{ moneySum[2] | orderMoneyFormat }}</span>
          </div>

          <el-row>
            <el-col class="pt10 f_s_16 text-center">
              <span style="color: #409eff; cursor: pointer" @click="addOneLine">
                新增一行
              </span>
            </el-col>
          </el-row>
        </el-row>
        <!-- 收款账户 -->
        <el-row class="mt20">
          <el-col :span="10">
            <el-form-item label="收款账户">
              <el-autocomplete
                v-model="form.payer"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入"
                @select="handleSelect"
                style="width: 100%; padding-right: 10px;"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 备注 -->
        <el-row :gutter="20" class="mt30">
          <el-col :span="10" class="mr20">
            <el-form-item label="备注">
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 6 }"
                v-model="form.remark"
                maxlength="500"
                show-word-limit
                placeholder="请输入500个字符以内"
                :rows="3"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="ml30">
            <el-form-item label="凭证/附件">
              <el-col :span="24" style="padding: 0">
                <ErpUpload
                  ref="ErpUpload"
                  :uploadParams="uploadParams"
                  @uploadList="uploadList"
                  @initUpload="initUpload"
                ></ErpUpload>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 客户代表认领 -->
        <el-row
          class="mt30"
          v-show="
            (form.fovos && form.fovos.length === 0 && form.financeType === 1) ||
            form.claimedSalesId
          "
        >
          <p class="mb15">客户代表认领</p>
          <el-form-item>
            <el-select
              v-model="form.claimedSalesId"
              placeholder="请选择"
              multiple
              collapse-tags
              @change="claimeChange"
            >
              <el-option
                v-for="(item, index) in claimData"
                :key="index"
                :label="item.userName"
                :value="item.userId"
              ></el-option>
            </el-select>
          </el-form-item>
          <p class="mt10 claimSalesId-tip">
            没有销售单号时可以选择客户代表认领，不选择将推送给所有业务进行认领
          </p>
        </el-row>

        <el-row>
          <div class="mt50">
            <div slot="footer" class="dialog-footer text-center">
              <el-button type="primary" @click="save">确 认</el-button>
              <el-button @click="close2">取 消</el-button>
            </div>
          </div>
        </el-row>
      </el-form>
      <!-- 销售退款 -->
      <div v-if="form.financeType == 3" style="margin-top: 10px">
        <SaleFefund
          ref="saleFefund"
          :financeType="form.financeType"
          :paymentOptions="paymentOptions"
          :currencyOptions="currencyOptions"
          :ticketNumberRequired="ticketNumberRequired"
          :financeObj="form"
          @financeMethodChange="financeMethodChange"
          @currencyChanage="currencyChanage"
          @saleRefundClose="saleRefundClose"
          @refundSave="refundSave"
        ></SaleFefund>
      </div>

      <!-- 金额出入提示 -->
      <ConfirmMoneyCatchModel
        ref="ConfirmMoneyCatchModel"
        @closeParent="close2"
        :verifyBeforeSend="verifyBeforeSend"
      />
      <!--收款单详情弹窗 -->
      <ReceiptDetailModelOut ref="ReceiptDetail" />
    </div>
  </el-dialog>
</template>

<script>
  import {
    orderAmountListPage,
    getOrderByOrderCode,
    financeInsertPO,
    listByTicket,
    financeOrderPayVO,
    calculationServiceCharge,
    getReDetail,
    listShorffAccountPay,
  } from '@/api/finance/credit-note-add'
  import {
    confirmReceiptDetail,
    selectOptions,
    listUserByRoleName,
  } from '@/api/finance/credit-manage-list'
  import ConfirmMoneyCatchModel from './confirm-money-catch-model.vue'
  import SaleFefund from './sale-refund.vue'
  import { mapGetters } from 'vuex'
  import orderMoneyDetail from './order-money-detail.vue'
  import ReceiptDetailModelOut from '@/views/finance-center/credit-manage/components/receipt-detail-model'

  const math = require('mathjs')
  export default {
    name: 'CreditNoteAddModel',
    components: {
      ConfirmMoneyCatchModel,
      SaleFefund,
      orderMoneyDetail,
      ReceiptDetailModelOut,
    },
    data() {
      return {
        dialogCreditVisible: false,
        title: '',
        form: {
          overchargeType: 1,
          financeType: 1, // 收款类型 1销售订单,销售退款  2其他费用
          //payee: '', // 付款方
          shroffAccountId: '', // 付款方id
          financeMethod: '', // 收款方式名称
          financeMethodCode: '', // 收款方式标识
          ticketNumber: '', // 票据单号
          currency: '', // 币种
          amountCollected: '', // 收款金额
          amountTime: '', // 收款日期
          fovos: [],
          payerId: '',
          payer: '',
          remark: '', // 备注
          financeUrl: '', // 附件
          claimedSalesId: '', //认领客户代表id
          claimedSalesName: '', //认领客户代表名称
        },
        claimedSalesId: [], //认领客户代表id数组
        claimedSalesName: [], ////认领客户代表名称数组
        currencyOptions: [], // 货币列表
        paymentOptions: [], // 收款方式列表
        exportTitleOptions: [], // 出口抬头列表
        fileUrlList: [], // 上传的文件地址
        showMiddleTable: true,
        showOrderAmountTable: false,
        isResubmit: false,
        loading: null,
        rules: {
          shroffAccountId: [
            { required: true, message: '请输入付款方', trigger: 'change' },
          ],
          financeMethodCode: [
            { required: true, message: '请选择收款方式', trigger: 'change' },
          ],
          currency: [
            { required: true, message: '请选择币种', trigger: 'change' },
          ],
          amountCollected: [
            { required: true, message: '请输入收款金额', trigger: 'blur' },
            {
              pattern: /^\d+(\.\d{1,2})?$/,
              message: this.$t('placeholder.decimals2'),
              trigger: 'blur',
            },
          ],
          amountTime: [
            { required: true, message: '请选择收款日期', trigger: 'blur' },
          ],
          ticketNumber: [
            { required: true, message: '请输入票据单号', trigger: 'blur' },
          ],
        },
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        //客户代表认领
        claimData: [],
        fileList: [],
        ticketNumberRequired: 0, // 0票据单号不必填 1票据单号必填
        commissionAmount: 0, // 国际手续费
        settleFee: 0, // 结算手续费
        financeId: '',
        detailMsg: {},
        detailId: '',
        editNum: 0,
        financeCode: '', // 重新提交，打开弹框时，保存当前的收款单号
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      moneySum() {
        let vos = this.form.fovos
        const sum = []
        sum[0] = vos.reduce((prev, next) => {
          prev += +next.amountCollected * 1000
          return prev
        }, 0)
        sum[1] = vos.reduce((prev, next) => {
          prev += +next.commissionAmount * 1000
          return prev
        }, 0)
        sum[2] = vos.reduce((prev, next) => {
          prev += next.amountCollected * 1000 - next.commissionAmount * 1000
          return prev
        }, 0)
        return sum.map((item) => {
          if (Number.isNaN(item) || item === 0) {
            return '--'
          } else {
            return (item / 1000).toFixed(2)
          }
        })
      },
    },
    watch: {
      'form.financeType': {
        handler(newVal, oldVal) {
          if (newVal == 3 || oldVal == 3) {
            // 编辑收款单 第一次进入不清空，切换tab清空内容
            // editnum 等于 1 时 点击编辑第一次进入，切换tab editnum变为0
            if (this.detailId && this.editNum == 1) {
              this.editNum = 0
              return
            }
            for (const key in this.form) {
              this.form[key] = ''
              this.form.refundMoney = 0
              this.form.financeType = Number(newVal)
              this.form.overchargeType = 1
              this.form.ticketNumberRequired = 0
              this.form.fovos = []
            }
          }
        },
        // immediate: true,
      },
    },
    created() {},
    mounted() {},
    methods: {
      initSaleRefund() {},
      //权限-客户代表角色下拉
      async getListUserByRoleName() {
        let response = await listUserByRoleName({
          divideGroup: 0,
          name: '业务员',
          tenantId: this.userInfo.tenantId,
        })
        if (response.code === '000000') {
          this.claimData = response.data
        }
      },
      claimeChange(val) {
        this.claimedSalesName = []
        let claimeArr = val
        claimeArr.forEach((item, index) => {
          this.claimData.forEach((item2, index2) => {
            if (item === item2.userId) {
              this.claimedSalesName.push(item2.userName)
            }
          })
        })
      },
      matchCurrency(str) {
        return str === '美元' ? '$' : '￥'
      },
      // 新增、编辑
      showCreditAdd(row, str) {
        this.editNum = 1
        this.detailId = row?.financeId
        this.financeCode = row?.financeCode
        this.getSelectOptions(1) // 货币设置
        this.getSelectOptions(3) // 支付方式
        // this.getExportTitle() // 出口抬头
        this.getListUserByRoleName() //权限-客户代表角色
        this.showMiddleTable = true
        if (this.detailId) {
          if (str === 'resubmit') {
            this.title = '新增收款单'
            this.isResubmit = true
          } else {
            this.title = '编辑收款单'
            this.isResubmit = false
          }
          //this.form = {}
          this.getConfirmReceiptDetail(this.detailId)
        } else {
          this.title = '新增收款单'
        }
        this.dialogCreditVisible = true
      },
      resetType() {
        this.form.financeType = 1
      },
      // 设置账户信息
      setRealAccount(data) {
        setTimeout(() => {
          this.$refs.saleFefund && (this.$refs.saleFefund.realAccount = data)
        }, 300)

      },
      //编辑
      async getConfirmReceiptDetail(id) {
        let response
        if (this.isResubmit) {
          response = await getReDetail({ financeId: id })
        } else {
          response = await confirmReceiptDetail({ financeId: id })
        }
        if (response?.code === '000000') {
          this.form = Object.assign({}, response.data)
          // 重新提交时、退款总金额和退款金额清空、手续费等字段同步清空
          if (this.isResubmit) {
            this.form.amountCollected = ''
            this.form.fovos = this.form.fovos.map((item) => {
              return {
                ...item,
                amountCollected: '',
              }
            })
            if(this.form.entryType && this.form.entryType == 1 && this.form.realAccountFlag === 1) { // 销售退款
              this.setRealAccount({
                realAccountFlag:this.form.realAccountFlag,
                ...this.form.realAccount
              })
            }

          }
          if (this.form.entryType == 1 && this.form.financeType == 1) {
            this.form.financeType = 3
          } else {
            this.editNum = 0
          }
          //如果是驳回，且认领客户代表，那么不需要显示销售单
          if (
            (this.form.financeStatus === '3' ||
              this.form.financeStatus === '6') &&
            this.form.claimSalesName &&
            this.form.claimSalesId
          ) {
            this.form.fovos = []
          }

          if (this.form.claimedSalesId && this.form.claimedSalesName) {
            this.$set(
              this.form,
              'claimedSalesId',
              this.form.claimedSalesId.split(',')
            )
            this.$set(
              this.form,
              'claimedSalesName',
              this.form.claimedSalesName.split(',')
            )
          }
          if (this.form.financeType === 2) {
            this.showMiddleTable = false
          } else {
            this.showMiddleTable = true
          }

          //附件
          if (this.form.financeUrl) {
            setTimeout(() => {
              this.initUpload(this.form.financeUrl)
            }, 300)
          }
          // 判断票据单号是否必填
        }
      },
      initUpload(data) {
        this.$refs.ErpUpload?.initUpload(data)
      },

      // 获取所有下拉框数据
      async getSelectOptions(type) {
        // 1币种设置 2发票设置 3支付方式 4科目 5费用杂项
        let response = await selectOptions({
          financeType: Number(type),
        })
        if (response.code === '000000') {
          if (type === 1) {
            this.currencyOptions = response.data
          }
          if (type === 3) {
            this.paymentOptions = response.data
          }
        }
      },
      //收款类型的选择
      radioChange(val) {
        this.editNum = 0
        this.$forceUpdate()
        if (val === 2) {
          this.showMiddleTable = false
          this.form.claimedSalesId = ''
          this.form.claimedSalesName = ''
          this.claimedSalesId = []
          this.claimedSalesName = []
          this.form.fovos = []
        } else {
          this.showMiddleTable = true
        }
      },
      // 收款方式的选择
      financeMethodChange(id, type) {
        let paymentItem = this.paymentOptions.filter((item) => {
          return id === item.financeSetupId
        })

        this.form.financeMethod = paymentItem[0].financeName
        this.ticketNumberRequired = paymentItem[0].ticketNumberRequired
        if (type == 'sale-refund') return
        if (this.ticketNumberRequired == 1) {
          this.$refs.form.rules.ticketNumber[0].required = true
        } else {
          this.$refs.form.rules.ticketNumber[0].required = false
        }

        this.amountCollectedChange()
      },
      // 获取出口抬头列表
      async getExportTitle() {
        let response = await orderAmountListPage({ amountType: 1 })
        if (response.code === '000000') {
          response.data.forEach((item) => {
            if (item.amountStatus == 1) {
              this.exportTitleOptions.push({ ...item })
            }
          })
        }
      },
      // 出口抬头选择
      exportsRiseChange(id) {
        let exportItem = this.exportTitleOptions.filter((item) => {
          return id === item.amountId
        })
        this.form.exportsRise = exportItem[0].amountShort

        let orderCodeArr = this.form.fovos.map((item) =>
          item.orderCode.slice(0, 3)
        )
        let orderCodeDeWeight = [...new Set(orderCodeArr)]

        if (orderCodeDeWeight.length >= 2) {
          return this.$message.warning('订单编号抬头不一致！')
        }
        if (this.form.fovos.length) {
          if (!this.form.fovos[0].orderCode.includes(form.exportsRise)) {
            return this.$message.warning('出口抬头与订单编号不一致！')
          }
        }
      },
      // 订单编号输入回显处理
      handleOrderIdInput(val, index) {
        getOrderByOrderCode({ orderCode: val }).then((res) => {
          if (res?.code === '000000') {
            this.form.fovos[index].area = res.data.area
            this.form.fovos[index].businessId = res.data.businessId
            this.form.fovos[index].businessName = res.data.businessName
            this.form.fovos[index].claimTime = res.data.claimTime
            this.form.fovos[index].companyName = res.data.companyName
            this.form.fovos[index].currency = res.data.currency
            this.form.fovos[index].customerName = res.data.customerName
            this.form.fovos[index].financeId = res.data.financeId
            this.form.fovos[index].financeOrderId = res.data.financeOrderId
            this.form.fovos[index].infoId = res.data.infoId
            this.form.fovos[index].orderCode = res.data.orderCode
            this.form.fovos[index].orderId = res.data.orderId
            this.form.fovos[index].outstandingAmount =
              res.data.outstandingAmount
            this.form.fovos[index].totlePrice = res.data.totlePrice
              ? res.data.totlePrice
              : 0
            // this.form.fovos[index].amountCollected = res.data.amountCollected
            // 销售订单  收款金额为0   bug10809
            this.form.fovos[index].amountCollected = ''
            this.form.fovos[index].commissionAmount =
              res.data.commissionAmount || 0
            this.$forceUpdate()

            this.calcCommissionAmount()
          }

          let currencyArr = this.form.fovos.map((item) => item.currency)
          let deWeight = [...new Set(currencyArr)]
          if (deWeight.length >= 2) {
            return this.$message.warning('订单币种必须保持一致')
          }

          if (res?.data?.currency != this.form.currency && this.form.currency) {
            this.$message.warning('订单币种与收款单币种不一致')
          }
        })
        //  }
      },

      //币种选择
      currencyChanage(val) {
        if (this.form.fovos.length === 1) {
          if (
            this.form.fovos[0].currency != this.form.currency &&
            this.form.currency
          ) {
            this.$message.warning('订单币种与收款单币种不一致')
          }
        }
        if (this.form.fovos.length >= 2) {
          let currencyArr = this.form.fovos.map((item) => item.currency)
          let deWeight = [...new Set(currencyArr)]
          if (deWeight.length >= 2) {
            this.$message.warning('订单币种与收款单币种不一致')
          } else if (deWeight[0] != this.form.currency && this.form.currency) {
            this.$message.warning('订单币种与收款单币种不一致')
          }
        }

        this.amountCollectedChange()
      },
      // 收款金额输入处理
      amountCollectedInput() {
        this.calcCommissionAmount()
      },
      // 手续费的输入
      commissionAmountInput(val, i) {
        if (val == '') {
          // this.$message.warning('不能为空')
          return
        } else {
          if (isNaN(val)) {
            this.$message.warning('请填入数字')
          } else if (val < 0) {
            this.$message.warning('请输入正数')
          } else if (!/^\d+(\.\d{1,3})?$/.test(val)) {
            this.$message.warning('小数后最多留三位')
          } else {
            return
          }
        }
      },
      //处理点击订单金额显示详情
      handelTotalPriceClick(e, index) {
        this.detailMsg = {}
        let refName = `orderMoneyDetail${index}`
        financeOrderPayVO({ orderCode: e.orderCode }).then((res) => {
          if (res.code === '000000') {
            this.detailMsg = res.data
          }
        })
      },
      // 新增一行
      addOneLine() {
        if (!this.form.financeMethodCode) {
          return this.$message.warning('请选择收款方式')
        }
        if (!this.form.currency) {
          return this.$message.warning('请选择币种')
        }
        if (!this.form.amountCollected) {
          return this.$message.warning('请输入收款金额')
        }
        this.form.fovos.push({
          area: '',
          businessId: '',
          businessName: '',
          claimTime: '',
          companyName: '',
          currency: '',
          customerName: '',
          financeId: '',
          financeOrderId: '',
          infoId: '',
          orderCode: '',
          orderId: '',
          outstandingAmount: '',
          totlePrice: '',
          amountCollected: '',
          commissionAmount: '',
          orderType: 1, //主订单
        })

        this.form.claimedSalesId = ''
        this.form.claimedSalesName = ''
        this.claimedSalesId = []
        this.claimedSalesName = []
      },
      // 删除新增的订单
      deleteNewLine(i) {
        this.$confirm(`是否确认删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.form.fovos.splice(i, 1)
            this.calcCommissionAmount()
          })
          .catch(() => {})
      },
      // 文件上传
      uploadList(fileList) {
        this.form.financeUrl = JSON.stringify(fileList)
      },
      close() {
        this.exportTitleOptions = []
        this.claimedSalesId = []
        this.claimedSalesName = []
        // this.$refs['form']?.resetFields()
        this.form = {}
        this.$set(this.form, 'financeType', 1)
        this.$set(this.form, 'overchargeType', 1)
        this.$set(this.form, 'fovos', [])
        this.form.financeUrl = ''
        this.dialogCreditVisible = false
        this.commissionAmount = 0
        this.isResubmit = false
        // this.form.auditObjectDTO = null
      },
      close2() {
        this.ticketNumberRequired = 0
        this.close()
        this.$emit('updateList')
      },
      // 新增收款单
      async addCreditNote(params) {
        if (this.form.claimedSalesId && this.form.claimedSalesId.length) {
          params.claimedSalesId = this.form.claimedSalesId.join(',')

          if (this.claimedSalesName && this.claimedSalesName.length) {
            params.claimedSalesName = this.claimedSalesName.join(',')
          } else {
            params.claimedSalesName = this.form.claimedSalesName.join(',')
          }
        } else {
          delete params.claimedSalesId
          delete params.claimedSalesName
        }

        if (params.financeType === 2) {
          params.fovos = []
        }
        params.entryType = 0
        const sendRequest = async () => {
          let response = await financeInsertPO(params)
          if (response?.code === '000000') {
            this.$message.success('操作成功！')
            this.close()
            this.$emit('updateList')
          }
        }
        this.verifyBeforeSend(sendRequest)
      },

      /*
        发送接口前校验
          参数：
            sendRequest 函数，必传，校验成功后执行
            confirmMoneyCatchModelVisible 布尔值，选传，在confirm-money-catch-model组件中调用时需要传true
      */
      verifyBeforeSend(sendRequest, confirmMoneyCatchModelVisible) {
        this.getReceipt().then((data) => {
          if (data.length) {
            // 如果是confirm-money-catch-model组件中调用，需要将金额出入提示弹框关闭
            if (confirmMoneyCatchModelVisible) {
              this.$refs.ConfirmMoneyCatchModel.dialogFormVisible = false
            }
            // 如果getReceipt返回的数组有值，就显示提示
            const { shroffAccountId, ticketNumber } = data[0]
            const financeCodes = data.map((n) => n.financeCode).join(', ')
            const msg = `付款方：${shroffAccountId}的票据单号：${ticketNumber}，在系统中已有收款单：${financeCodes}`
            this.$confirm(msg, '提示', {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              customClass: 'add-tip',
            }).then(() => {
              sendRequest()
            })
          } else {
            // 如果getReceipt返回空数组，就直接调接口，不显示提示
            sendRequest()
          }
        })
      },

      // 根据票据单号查询收款单
      async getReceipt() {
        const {
          financeMethod,
          financeMethodCode,
          shroffAccountId,
          ticketNumber,
        } = this.form
        if (financeMethod !== '支票') return Promise.resolve([]) // 只有支票才需要调接口
        const params = {
          financeMethod,
          financeMethodCode,
          shroffAccountId,
          ticketNumber,
        }
        try {
          let { code, data } = await listByTicket(params)
          if (code === '000000' && Array.isArray(data)) {
            // 已驳回-重新提交，需要将当前收款单号过滤掉
            if (this.financeCode) {
              data = data.filter((n) => n.financeCode !== this.financeCode)
            }
            return Promise.resolve(data) // 如果接口返回的是空数组，直接调新增接口；如果返回的数组中有值，需要显示提示弹框，点击确定调新增接口
          }
        } catch (e) {
          console.log(e)
        }
      },
      save() {
        delete this.form.auditObjectDTO
        this.form.ticketNumberRequired = this.ticketNumberRequired
        if (this.form.ticketNumberRequired == 1) {
          this.$refs.form.rules.ticketNumber[0].required = true
        } else {
          this.$refs.form.rules.ticketNumber[0].required = false
        }
        this.form.overchargeType = 1
        let form = this.utils.deepCopy(this.form)
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            //每个订单编号币种需保持一致
            let currencyArr = form.fovos.map((item) => item.currency)
            let deWeight = [...new Set(currencyArr)]
            if (deWeight.length >= 2) {
              return this.$message.warning('订单币种必须保持一致')
            }

            //订单币种需与收款单币种保持一致
            if (deWeight.length === 1) {
              if (
                this.form.fovos[0].currency != this.form.currency &&
                this.form.currency
              ) {
                return this.$message.warning('订单币种与收款单币种不一致')
              }
            }

            //销售订单,不走认领
            if (this.form.financeType == 1 && this.form.fovos.length) {
              let money = 0
              let panduan = true
              form.fovos.forEach((item) => {
                money += Number(item.amountCollected)
              })
              money = Number(money.toFixed(2))

              if (panduan) {
                if (money > Number(form.amountCollected)) {
                  this.$message.warning('实收金额不能大于收款金额')
                } else if (money < Number(form.amountCollected)) {
                  //金额不符，弹框选择
                  this.$refs['ConfirmMoneyCatchModel'].showAddEdit(form)
                } else if (money == Number(form.amountCollected)) {
                  this.addCreditNote(form)
                }
              }
            } else {
              //其他费用，待认领的销售订单
              this.form.fovos = []
              this.addCreditNote(form)
            }
          }
        })
      },

      // 收款金额获取手续费
      amountCollectedChange() {
        if (!this.form.amountTime) return
        let params = {
          amountCollected: this.form.amountCollected,
          currency: this.form.currency,
          financeSetupId: this.form.financeMethodCode,
          amountTime: this.form.amountTime,
        }
        let boo = /(^[1-9](\d+)?(\.\d{1,3})?$)|(^\d\.\d{1,3}$)/.test(
          params.amountCollected
        )

        if (Object.values(params).every((item) => !!item) && boo) {
          calculationServiceCharge({
            ...params,
            entryType: 0,
          }).then((res) => {
            this.commissionAmount = res.data.commissionAmount // 国际手续费
            this.settleFee = res.data.settleFee // 结算手续费

            this.calcCommissionAmount()
          })
        }
      },
      changeAmountTime() {
        this.amountCollectedChange()
      },

      // 计算手续费金额
      calcCommissionAmount() {
        let commissionAmount = this.commissionAmount
        let settleFee = this.settleFee
        let fovos = this.form.fovos
        if (fovos.length && fovos.every((item) => item.amountCollected != '')) {
          const total = this.utils.amountTotal(fovos, 'amountCollected', 3)
          for (let i = 0; i < fovos.length; i++) {
            // 赋值国际手续费
            this.$set(
              fovos[i],
              'commissionAmount',
              commissionAmount > 0
                ? (
                    (fovos[i].amountCollected / total) *
                    commissionAmount
                  ).toFixed(2)
                : 0
            )
            // 赋值结算手续费
            // this.$set(
            //   fovos[i],
            //   'settleFee',
            //   settleFee > 0
            //     ? ((fovos[i].amountCollected / total) * settleFee).toFixed(3)
            //     : 0
            // )
            let dt = math.format((fovos[i].amountCollected / total) * settleFee)
            this.$set(
              fovos[i],
              'settleFee',
              settleFee > 0 ? Number(dt).toFixed(2) : 0
            )
          }
        }
      },
      // 销售退款关闭
      saleRefundClose() {
        this.dialogCreditVisible = false
        this.form.financeType = 1
        this.isResubmit = false
        // this.form = {}
        // this.form.fovos = []
      },
      // 销售退款保存
      refundSave(val) {
        let params = {
          ...val,
          financeType: 1,
        }
        financeInsertPO(params).then((res) => {
          if (res?.code === '000000') {
            this.$message.success('操作成功！')
            this.dialogCreditVisible = false
            this.$emit('updateList')
            this.$refs.saleFefund.close2()
            this.form.financeType = 1
            this.isResubmit = false
            // this.form.auditObjectDTO = null
          }
        })
      },
      showDetail(val) {
        this.$refs['ReceiptDetail'].showReceiptDetail(
          val.financeCode,
          val.financeStatus
        )
      },
      // 收款账户change
      handleSelect(item) {
        this.form.payerId = item.shroffAccountId
        this.form.payer =
          item.payee + '/ ' + item.bankDeposit + ' /' + item.bankAccount
      },
      // 收款账户下拉
      querySearchAsync(cusName, cb) {
        listShorffAccountPay({ cusName, shroffType: 3 }).then(
          (res) => {
            const results = res.data.map((item) => {
              return {
                ...item,
                value:
                  item.payee + '/' + item.bankDeposit + '/' + item.bankAccount,
              }
            })
            cb && cb(results)
          }
        )
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-dialog__wrapper {
    display: flex;
    /deep/.el-dialog {
      margin: auto !important;
    }
  }
  .p-tip {
    font-size: 12px;
    color: red;
  }

  .claimSalesId-tip {
    font-size: 12px;
    color: #999999;
  }

  .dialog-content /deep/ .el-form-item__content {
    margin-left: 0 !important;
  }

  /deep/ .el-form-item {
    margin-bottom: 0 !important;
  }

  .top-table-content /deep/ .el-input__prefix {
    left: 4px;
    top: 3px;
  }

  /deep/ .el-dialog__body {
    padding-top: 10px;
  }

  ::v-deep {
    .el-autocomplete-suggestion {
      width: 400px !important;
    }
  }

  .money-sum {
    color: #666;
    text-align: right;
    padding: 5px;

    span {
      margin: 0 5px;
    }
  }
</style>
<style lang="scss">
  .el-message-box.add-tip {
    .el-message-box__message p {
      word-break: break-all;
      text-align: justify;
      max-height: 450px;
      overflow: auto;
    }
  }
</style>
