<template>
  <div>
    <el-dialog title="手动完结" :visible.sync="showModel" width="600px">
      <div class="tips" v-if="manualData.type == 0 && manualData.reason">
        <i class="el-icon-warning"></i>
        <span style="font-size: 16px">{{ manualData.reason }}</span>
      </div>
      <div v-if="manualData.type == 0 && !manualData.reason">
        <p>还有以下单据未完成，无法手动完结:</p>
        <el-table
          :data="manualData.financeVOS"
          style="width: 100%"
          border
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column prop="date" label="#" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="businessNumber" label="单据号" width="180">
            <template slot-scope="scope">
              <span @click="showDetail(scope.row)" class="blue-text">
                {{ scope.row.businessNumber }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="类型">
            <template slot-scope="scope">
              <span>{{ scope.row.type | typeFilter }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <el-tag :type="checkState[scope.row.status].type">
                {{ checkState[scope.row.status].name }}
              </el-tag>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="manualData.type == 1">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="完结原因" prop="endStateReason">
            <el-input
              show-word-limit
              maxlength="500"
              type="textarea"
              v-model="ruleForm.endStateReason"
              placeholder="请输入手动完结原因，500字符"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModel" v-if="manualData.type == 1">
          取 消
        </el-button>
        <el-button type="primary" @click="submitModel">确 定</el-button>
      </span>
    </el-dialog>
    <receiptDetail ref="receiptDetail"></receiptDetail>
  </div>
</template>
<script>
import { manualEnd } from '@/api/order'
import info from '../util/data'
import receiptDetail from '@/views/finance-center/credit-manage/components/receipt-detail-model1.vue'
export default {
  name: 'manualEndModel',
  components: {
    receiptDetail,
  },
  props: {
    manualVisible: {
      default: false,
      type: Boolean,
    },
    orderId: {
      default: '',
      type: String,
    },
    manualData: {
      default: () => {},
      type: Object,
    },
  },
  watch: {
    manualVisible: {
      handler() {
        this.showModel = this.manualVisible
      },
      deep: true,
      immediate: true,
    },
    showModel() {
      if (!this.showModel) {
        this.closeModel()
      }
    },
  },
  filters: {
    typeFilter(val) {
      const typeMap = {
        1: '收款单',
      }
      return typeMap[val]
    },
  },
  data() {
    return {
      tableData: [],
      ruleForm: {
        endStateReason: '',
      },
      rules: {
        endStateReason: [
          { required: true, message: '完结原因', trigger: 'blur' },
        ],
      },
      showStep: 1, //1 判断是否签收 2 正在进行中的单据 3 满足完结条件
      checkState: info.statusFilter,
      showModel: false,
    }
  },

  methods: {
    closeModel() {
      this.ruleForm.endStateReason = ''
      this.$emit('closeModel')
    },
    submitModel() {
      if (this.manualData.type == 1) {
        this.submitReason()
      } else {
        this.$emit('closeModel')
      }
    },
    submitReason() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let dt = {
            ...this.ruleForm,
            orderId: this.orderId,
          }
          manualEnd(dt).then((res) => {
            if (res.code === '000000') {
              this.$message.success('操作成功')
              this.ruleForm.endStateReason = ''
              this.$emit('submitModel')
            }
          })
        } else {
          return false
        }
      })
    },
    showDetail(val) {
      this.$refs['receiptDetail'].showReceiptDetail(
        val.businessNumber,
        val.status,
        false
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.tips {
  display: flex;
  align-items: center;
}
.el-icon-warning {
  color: #e6a23c;
  font-size: 25px;
  margin-right: 15px;
}
</style>