var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dialogFormVisible)?_c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.dialogFormVisible,"width":"550px","append-to-body":"","close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogFormVisible=$event},"close":_vm.close},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('el-row',{staticClass:"text-center"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("确 认")]),_c('el-button',{on:{"click":_vm.close}},[_vm._v("取 消")])],1)]},proxy:true}],null,false,1370585784)},[_c('div',{staticClass:"dialog-content"},[_c('el-row',{staticClass:"mb15"},[_vm._v(" 订单收款总金额与收款单金额不符，请选择多出金额的分配方式 ")]),_c('el-radio-group',{on:{"change":_vm.radioChange},model:{value:(_vm.form.overchargeType),callback:function ($$v) {_vm.$set(_vm.form, "overchargeType", $$v)},expression:"form.overchargeType"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("当前订单收入")]),_c('el-radio',{attrs:{"label":2}},[_vm._v("其他订单抵扣")]),_c('el-radio',{attrs:{"label":3}},[_vm._v("存入客户账户")])],1),_c('el-form',{ref:"form",staticClass:"mt20 mb30",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"80px"}},[_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.overchargeType === 2),expression:"form.overchargeType === 2"}],attrs:{"label":"订单编号","prop":"orderCode","rules":[
          {
            required: _vm.form.overchargeType === 2 ? true : false,
            message: '请选择订单编号',
            trigger: 'change',
          } ]}},[_c('el-select',{staticClass:"w240",attrs:{"placeholder":"请选择"},on:{"change":_vm.orderCodeChange},model:{value:(_vm.form.orderCode),callback:function ($$v) {_vm.$set(_vm.form, "orderCode", $$v)},expression:"form.orderCode"}},_vm._l((_vm.orderCodeData),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.orderCode,"value":item.orderId}})}),1)],1),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.overchargeType === 3),expression:"form.overchargeType === 3"}],attrs:{"label":"客户账户","prop":"customerOrder","rules":[
          {
            required: _vm.form.overchargeType === 3 ? true : false,
            message: '请选择客户账户',
            trigger: 'change',
          } ]}},[_c('el-select',{staticClass:"w240",attrs:{"placeholder":"请选择"},on:{"change":_vm.customerOrderChange},model:{value:(_vm.form.customerOrder),callback:function ($$v) {_vm.$set(_vm.form, "customerOrder", $$v)},expression:"form.customerOrder"}},_vm._l((_vm.customerOrderData),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.orderCode +
              '-' +
              item.customerName +
              '-' +
              item.businessName,"value":item.orderCode}})}),1)],1)],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }