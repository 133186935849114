<!--
  功能：附件展示组件
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年06月21日 10:15:04
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <el-row>
    <el-col :span="24">
      <p>{{ label }}</p>
      <div v-show="fileList.length">
        <div class="flie-list" v-for="(item, i) in fileList" :key="i">
          <i class="el-icon-document"></i>
          <a class="page-link ml5">
            <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
              <span class="ell">
                <router-link :to="`/preview-file/index?name=${item.name}&url=${item.url}`" target="_blank">
                  {{ item.name }}
                </router-link>
              </span>
            </el-tooltip>
          </a>
          <span class="size ml10">
            {{ utils.calculateFileSize(item.size) }}
          </span>
          <span class="ml10">{{ item.time }}</span>
        </div>
      </div>
      <div v-show="!fileList.length">--</div>
    </el-col>
  </el-row>
</template>

<script>
  export default {
    name: 'CostFileList',
    props: {
      fileList: {
        type: [Array, String],
        default: () => [],
      },
      label: {
        type: String,
        default: '附件',
      },
    },
    components: {},
    // 组件状态值
    data() {
      return {}
    },
  }
</script>

<style scoped>
</style>
