import { isObject } from '@/utils/utils'
// 销售订单
export const orderLogFields = [
  'priceSheetCode', //报价单号
  'contractDate', //签约日期
  'exportsRise', //出口抬头
  'exportsRiseId', //出口抬头id
  'orderSourceId', //订单来源id
  'businessId', //客户代表id
  'fromBusinessId', //订单支持id
  'customerOrderCode', //客户订单号
  'infoId', // 客户信息id
  'customerName', //客户姓名
  'customerCode', //客户编码
  'customerEmail', //客户邮箱
  'companyName', //公司名称
  'linkman', //联系人
  'customerPhone', //联系电话
  'shippingMethodId', //货运方式
  'deliveryDate', //交货日期
  'shippingDate', //装运日期
  'companyAddress', //公司地址
  'payId', //金额区间id
  'amountInterval', //支付区间
  'paymentProportion', //支付比列
  'currency', //币种
  'usMoney', //抵扣金额
  'rmbMoney', //抵扣人民币
  'discountRemark', //抵扣说明
  'customerRemark', //客户备注
  'remark', //内部备注
  'quantType', // 产品集合
  'accessoryUrl', // 附件
  'portOfLoading', //装运港
  'destination', //目的港
  'updateRemark', //修改原因
  'abdDTOS', //抵扣ID和金额
  'orderProductDTOS', // 产品集合
  'receivingAddressConfigDTOS', //收货地址配置集合
  'receivingAddressDTOS', //收货地址集合
  'cargoDistributionDTOS', //货物分配集合
]

export const purchaseOrderLogFields = [
  'supplierId', //供应商
  'agreementEndDate', // 协议结束日期
  'supplierLinkmanId', //联系人
  'supplierLinkmanPhone', //联系电话
  'buyerId', //采购开发
  'merchandiserId', //qc
  'receivingAddress', //交货地址
  'transportWay', // 运输方式
  'freightPayer', //费用承担方
  'paymentMethodId', // 付款方式
  'currency', //币种
  'bankName', //开户行
  'bankAccount', //账户
  'accountName', //户名
  'dutyAccount', //税号
  'purchaseProductEditDTOList', //产品列表
  'financeSetupName', //发票信息
  'modelName', //采购合同模板
  'purchaser', //买方
  'marks', //箱唛
  'remark', // 备注
  'exchangeRate', //税率
]

export const productLogFields = [
  'productId', //产品id
  'productCode', //产品编码
  'nameCn', //产品中文名称
  'nameEn', //产品英文名称
  'standardCustomized', //产品类型
  'category',
  'categoryEn',
  'categoryId', //产品类别
  'supplierIds', //所有选中的供应商id
  'mainSupplierIds', //主要供应商id
  'mainSupplierName', //主要供应商名称
  'supplierNames', //所有选中的供应商
  'materialId', //材质id，（+类别id）查询印刷,
  'material', //材质
  'keyWord', //关键词
  'descriptionEn', //英文描述
  'printArea',
  'descriptionCn', //中文描述
  'specsType', //规格类型
  'specsValue', //规格值
  'specsSkuMax',
  'imgMain', // 产品图片
  'productionLine', //产线
  'productionLineEn',
  'productionLineOperationId',
  'productionLineOperation',
  'productionLineOperationEn',
  'sumSeparately',
  'overflowNum',
  'costPrice', //成本价
  'priceRate', //价格规则
  'taxRate', //税率
  'calcFreight', // 是否展示免运费
  'remark', //备注
  'proofingFee', //打样费
  'proofingDay', //打样周期
  'productUnit', // 单位 英文
  'productUnitCn', // 单位 中文
  'creditLimit', //客户信用额度适用
  'freightWay', //计算运费方式
  'createWay', //创建方式(0自行新增,1生产系统,2询盘生成)
  'auditProcess',
  'procuctStepForPrintingVOList',
  'belongerId', // 产品运营id
  'belonger', // 产品运营
  'belongerEn', // 产品运营英文
  'productionLineId',
  'creatorId', // 创建人
  'creator', // 创建人姓名
  'printingWaySelect', //计价方式选择
  'printingWaySelectEn',
  'printingWaySelectId',
  'waySelect', //计价名称选择
  'waySelectId',
  'specsSelect', //规格类型选择
  'freeFreightForPlatform',
  'exchange', //当前汇率
  'waySelectEn',
  'specsSelectEn',
  'skuSettingForProduct', //按规格设置
  'skuSettingForDeclare',
  'numberPerBox', // 装箱数量
  'boxWeightKgs', // 毛重
  'boxLengthCm', //装箱尺寸-长度
  'boxWidthCm', //装箱尺寸-宽度
  'boxHeightCm', //装箱尺寸-高度
  'boxLengthIn',
  'boxWidthIn',
  'boxHeightIn',
  'auditHistory', //是否审核过
  'downShelfSpecs', //已下架列表
  'specsSwitch', //是否开启多规格
  'productSpecsDTOList', //规格列表
  'productPricingStepDTOList', //计价列表
  'productSpecsAddPriceDTOList', //规格加价列表
  'productPrintingWayDTOList', //印刷列表
  'productPrintingPricingId', //印刷方式id
  'productFileDTOList', //产品轮播图
  'productKeyEditDTOList',
]

/**
 * @description 收集变更字段
 * @param {object} prevObj  初始对象
 * @param {object} currentObj  当前对象
 */
export function collectLogFields(prevObj, currentObj, logFields = []) {
  if (!isObject(prevObj) || !isObject(currentObj)) {
    throw new Error('prevObj or currentObj expects object type')
  }
  const cache = []
  logFields.forEach((field) => {
    if (
      (typeof prevObj[field] === 'object' && prevObj[field] != null) ||
      (typeof currentObj[field] === 'object' && currentObj[field] != null)
    ) {
      if (
        JSON.stringify(prevObj[field]) !== JSON.stringify(currentObj[field])
      ) {
        cache.push(field)
      }
    } else {
      // 过滤掉值为0, '', null, undefined仍然push的情况
      if (!prevObj[field] && !currentObj[field]) return false
      if (prevObj[field] !== currentObj[field]) {
        cache.push(field)
      }
    }
  })
  return cache
}
