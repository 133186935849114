<!-- 收款管理-收款单详情 -->
<!-- 销售退款 -->
<template>
  <el-dialog title="收款单详情" :visible.sync="dialogReceiptVisible" width="1280px" @close="close" append-to-body
    :close-on-click-modal="false">
    <div class="content">
      <!-- floor1 -->
      <el-row type="flex" :gutter="60" style="margin-bottom: 10px">
        <el-col :span="6">
          <label>收款单号</label>
          <p>
            {{ receiptData.financeCode }}
            <el-tag class="ml5" :type="status | statusFilter">
              <!-- {{ status | statusTextFilter }} -->
              {{ receiptData.financeStatus | statusTextFilter }}
            </el-tag>
          </p>
        </el-col>
        <el-col :span="6">
          <label>收款来源</label>
          <p>{{ receiptData.financeType | financeTypeFilter }}</p>
        </el-col>
        <el-col :span="6">
          <label>
            {{ receiptData.financeType == '1_1' ? '收款方' : '付款方' }}
          </label>
          <p>{{ receiptData.payee || '--' }}</p>
        </el-col>
        <!-- 销售订单-已完成（订单抵扣）（银行流水号） -->
        <el-col v-show="status === 4">
          <label>银行流水号</label>
          <p>{{ receiptData.serialNumber || '--' }}</p>
        </el-col>
        <!-- 销售订单-已作废（创建人，创建时间） -->
        <el-col v-show="status === 5">
          <label>创建人</label>
          <p>{{ receiptData.creatorName || '--' }}</p>
        </el-col>
        <el-col v-show="status === 5">
          <label>创建时间</label>
          <p>{{ receiptData.createTime || '--' }}</p>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center" :gutter="60" style="margin-top: 20px">
        <el-col :span="6">
          <label>
            {{ receiptData.financeType == '1_1' ? '退款方式' : '收款方式' }}
          </label>
          <p>
            {{ receiptData.financeMethod || '--' }}
            <span v-show="receiptData.ticketNumber" style="color: #666; font-size: 12px">
              (票据单号:{{ receiptData.ticketNumber }})
            </span>
          </p>
        </el-col>
        <el-col :span="6">
          <label>币种</label>
          <p>{{ receiptData.currency || '--' }}</p>
        </el-col>
        <el-col :span="6">
          <label>
            {{ receiptData.financeType == '1_1' ? '退款金额' : '收款金额' }}
          </label>
          <div style="white-space: nowrap">
            <span v-if="receiptData.entryType == 1" style="color: red; margin-right: -3px">
              -
            </span>
            <p :style="
                receiptData.entryType == 1
                  ? 'color:red;display: inline-block'
                  : 'display: inline-block'
              ">
              {{ receiptData.amountCollected | orderMoneyFormat }}
            </p>
            <div style="
                display: inline-block;
                font-size: 12px;
                color: #666666;
                margin-left: 10px;
              ">
              <span style="margin-right: -5px">
                汇率：{{ receiptData.exchangeRate }} &nbsp;&nbsp;RMB：
              </span>
              <span v-if="receiptData.entryType == 1" style="margin-right: -3px; color: red">
                -
              </span>
              <span :style="receiptData.entryType == 1 ? 'color:red' : ''">
                {{ receiptData.amountCollectedRMB | orderMoneyFormat }}
              </span>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <label>
            {{ receiptData.financeType == '1_1' ? '退款申请日期' : '收款日期' }}
          </label>
          <p>{{ receiptData.amountTime || '--' }}</p>
        </el-col>
      </el-row>

      <!-- floor2 收款table-->

      <!-- floor2 订单table（待确认，待处理-无需认领，已完成） 认领的不显示 -->
      <el-table border class="w100 mt10" :data="orderTableData" v-show="
          ([1, 3, 4, 7].includes(status) || receiptData.entryType == 1) &&
          !receiptData.claimSalesName
        ">
        <el-table-column type="index" align="center" label="#"></el-table-column>
        <el-table-column label="订单编号" align="center" prop="orderCode" width="130">
          <template slot-scope="scope">
            <span class="link" @click="orderCodeClick(scope.row.orderCode)">
              {{ scope.row.orderCode }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="客户姓名" align="center" prop="customerName" show-overflow-tooltip></el-table-column>
        <el-table-column label="公司名称" align="center" prop="companyName" show-overflow-tooltip></el-table-column>
        <!-- 待确认，待处理-无需认领（客户代表） -->
        <el-table-column label="客户代表" align="center" prop="businessName" show-overflow-tooltip
          v-show="status === 7 || status === 1"></el-table-column>
        <el-table-column label="所属地区" align="center" prop="area" show-overflow-tooltip></el-table-column>
        <el-table-column label="币种" align="center" prop="currency"></el-table-column>
        <el-table-column label="订单金额" align="center" prop="totlePrice">
          <template slot-scope="scope">
            <span>{{ scope.row.totlePrice | orderMoneyFormat}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="receiptData.financeType == '1_1' ? '退款金额' : '实收金额'" align="center" prop="">
          <template slot-scope="scope">
            <span v-if="receiptData.entryType == 1" style="color: red">
              -{{scope.row.amountCollected | orderMoneyFormat }}
            </span>
            <span v-else>
              {{ scope.row.amountCollected | orderMoneyFormat }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :label="
            receiptData.financeType == '1_1' ? '退还手续费' : '手续费金额'
          " align="center">
          <template slot-scope="scope">
            <span v-if="receiptData.entryType == 1" style="color: red">
              -{{ scope.row.commissionAmount | orderMoneyFormat }}
            </span>
            <span v-else>
              {{ scope.row.commissionAmount | orderMoneyFormat }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="结算手续费" align="center" v-if="settleFeeStatus" prop="settleFee">
          <template slot-scope="scope">
            <span v-if="receiptData.entryType == 1" style="color: red">
              -{{ scope.row.settleFee | orderMoneyFormat }}
            </span>
            <span v-else>
              {{ scope.row.settleFee | orderMoneyFormat }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :label="receiptData.financeType == '1_1' ? '实退金额' : '应收金额'" align="center"
          prop="amountReceivable">
          <template slot-scope="scope">
            <span v-if="receiptData.entryType == 1" style="color: red">
              -{{ scope.row.amountReceivable | orderMoneyFormat }}
            </span>
            <span v-else>
              {{scope.row.amountReceivable | orderMoneyFormat }}
            </span>
          </template>
        </el-table-column>
        <el-table-column v-if="
            orderTableData &&
            orderTableData.some((item) => item.salesConfirmTime)
          " label="业务确认时间" align="center" prop="salesConfirmTime" width="150"></el-table-column>
      </el-table>
      <!-- 合计 -->
      <el-row v-show="
          ([1, 4, 7].includes(status) || receiptData.entryType == 1) &&
          !receiptData.claimSalesName
        ">
        <div v-if="receiptData.financeType == 1 && receiptData.financeStatus != 2" class="money-sum">
          <span>合计</span>
          <span>实收金额:</span>
          {{ moneySum[0] |orderMoneyFormat }}
          <span>手续费:</span>
          <span>{{ moneySum[1] | orderMoneyFormat }}</span>
          <span>结算手续费:</span>
          <span>{{ moneySum[2] | orderMoneyFormat }}</span>
          <span>应收金额:</span>
          <span>{{ moneySum[3] | orderMoneyFormat}}</span>
        </div>
        <div v-if="
            receiptData.financeType == '1_1' && receiptData.financeStatus != 2
          " class="money-sum">
          <span>合计</span>
          <span>退款金额:</span>
          -{{ moneySum2[0] | orderMoneyFormat }}
          <span>退还手续费:</span>
          <span>-{{ moneySum2[1] | orderMoneyFormat }}</span>
          <span>结算手续费:</span>
          -{{ moneySum2[2] | orderMoneyFormat }}
          <span>实退金额:</span>
          <span>-{{ moneySum2[3] | orderMoneyFormat}}</span>
        </div>
      </el-row>

      <!-- floor3 备注等其他 -->
      <el-row :gutter="20" type="flex" class="mt30">
        <el-col :span="8">
          <label>
            {{ receiptData.financeType == '1_1' ? '退款原因' : '备注' }}
          </label>
          <tooltip-over v-if="receiptData.remark" class="c_pointer tooltipOver" :content="receiptData.remark"
            refName="tooltipOver5">
            <p>{{ receiptData.remark }}</p>
          </tooltip-over>
          <p v-else class="no-text">未填写</p>
        </el-col>
        <el-col :span="6" v-show="!fileList.length">
          <label>附件</label>
          <div v-show="fileList.length">
            <div class="flie-list" v-for="(item, i) in fileList" :key="i">
              <i class="el-icon-document"></i>
              <a class="page-link ml5">
                <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
                  <span class="ell">
                    <router-link :to="`/preview-file/index?name=${item.name}&url=${item.url}`" target="_blank">
                      {{ item.name }}
                    </router-link>
                  </span>
                </el-tooltip>
              </a>
              <span class="size ml10">
                {{ utils.calculateFileSize(item.size) }}
              </span>
              <span class="ml10">{{ item.time }}</span>
            </div>
          </div>
          <p v-show="!fileList.length" class="no-text">无</p>
        </el-col>
        <!-- 已作废-没有这个两个（创建人，创建时间） -->
        <el-col :span="5" v-show="status !== 5">
          <label>创建人</label>
          <p>{{ receiptData.creatorName || '--' }}</p>
        </el-col>
        <el-col :span="5" v-show="status !== 5">
          <label>创建时间</label>
          <p>{{ receiptData.createTime || '--' }}</p>
        </el-col>
      </el-row>
      <el-row class="mt20" v-show="fileList.length">
        <label>附件</label>
        <div v-show="fileList.length">
          <div class="flie-list" v-for="(item, i) in fileList" :key="i">
            <i class="el-icon-document"></i>
            <a class="page-link ml5">
              <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
                <span class="ell">
                  <router-link :to="`/preview-file/index?name=${item.name}&url=${item.url}`" target="_blank">
                    {{ item.name }}
                  </router-link>
                </span>
              </el-tooltip>
            </a>
            <span class="size ml10">
              {{ utils.calculateFileSize(item.size) }}
            </span>
            <span class="ml10">{{ item.time }}</span>
          </div>
        </div>
        <p v-show="!fileList.length" class="no-text">无</p>
      </el-row>

      <el-row class="row-line" v-show="
          status === 2 ||
          (status === 1 &&
            !receiptData.claimTime &&
            !receiptData.claimSalesName &&
            receiptData.financeType !== 2) ||
          status === 4 ||
          status !== 5 ||
          status !== 1
        "></el-row>

      <!-- 待认领 floor4 -->
      <el-row class="mt20" v-show="status === 2">
        <label>推送客户代表</label>
        <p>
          {{ receiptData.claimedSalesName || '--' }}
        </p>
      </el-row>

      <!-- 已完成，已完成-订单抵扣  floor5-->
      <el-row :gutter="20" type="flex" class="mt30">
        <el-col>
          <label>收款账户</label>
          <p>{{ receiptData.accountCredited || '--' }}</p>
        </el-col>
        <el-col>
          <label>银行入账日期</label>
          <p>{{ receiptData.bankAcceptanceTime || '--' }}</p>
        </el-col>
        <el-col>
          <label>确认人</label>
          <p>{{ receiptData.payeeConfirmName || '--' }}</p>
        </el-col>
        <el-col>
          <label>财务确认时间</label>
          <p>{{ receiptData.financeConfirmTime || '--' }}</p>
        </el-col>
      </el-row>

      <!-- 驳回不显示完成时间 -->
      <el-row class="mt20" v-show="status != 3">
        <label>完成时间</label>
        <p>{{ receiptData.updateTime || '--' }}</p>
      </el-row>

      <!-- 除待确认和待处理没有 floor4 -->
      <el-row class="mt20" v-show="status !== 5 || status !== 1">
        <el-row type="flex" v-if="
            receiptData.claimSalesName && receiptData.claimTime && status !== 2
          ">
          <el-col>
            <label>认领客户代表</label>
            <p>{{ receiptData.claimSalesName || '--' }}</p>
          </el-col>
          <el-col>
            <label>认领时间</label>
            <p>{{ receiptData.claimTime || '--' }}</p>
          </el-col>
        </el-row>
        <!-- table-->
        <el-table border class="w100 mt30" :data="receiptData.financeOrderVOS" v-if="
            (receiptData.claimSalesName &&
              receiptData.claimTime &&
              status === 6) ||
            (status === 3 &&
              receiptData.claimTime &&
              receiptData.financeType !== 2) ||
            (receiptData.claimSalesName &&
              receiptData.claimTime &&
              status === 1) ||
            (receiptData.claimSalesName &&
              receiptData.claimTime &&
              status === 4)
          ">
          <el-table-column type="index" align="center" label="#"></el-table-column>
          <el-table-column label="订单编号" align="center" prop="orderCode">
            <template slot-scope="scope">
              <span class="link" @click="orderCodeClick(scope.row.orderCode)">
                {{ scope.row.orderCode }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="客户名称" align="center" prop="customerName" show-overflow-tooltip></el-table-column>
          <el-table-column label="公司名称" align="center" prop="companyName" show-overflow-tooltip></el-table-column>
          <el-table-column label="所属地区" align="center" prop="area" show-overflow-tooltip></el-table-column>
          <el-table-column label="币种" align="center" prop="currency"></el-table-column>
          <el-table-column label="订单金额" align="center" prop="totlePrice">
            <template slot-scope="scope">
              <span>{{ scope.row.totlePrice | orderMoneyFormat}}</span>
            </template>
          </el-table-column>
          <el-table-column label="实收金额" align="center" prop="amountCollected">
            <template slot-scope="scope">
              <div :style="receiptData.entryType == 1 ? 'color:red' : ''">
                <span v-if="receiptData.entryType == 1" style="margin-right: -3px">
                  -
                </span>
                <span>{{ scope.row.amountCollected }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="手续费金额" align="center" prop="commissionAmount">
            <template slot-scope="scope">
              <span v-if="receiptData.entryType == 1">0</span>
              <span v-else>{{ scope.row.commissionAmount }}</span>
            </template>
          </el-table-column>
          <el-table-column label="结算手续费" align="center" v-if="settleFeeStatus">
            <template slot-scope="scope">
              <span v-if="receiptData.entryType == 1">0</span>
              <span v-else>{{ scope.row.settleFee || 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="应收金额" align="center" prop="amountReceivable">
            <template slot-scope="scope">
              <span v-if="
                  receiptData.entryType == 1 && scope.row.amountReceivable != 0
                " style="color: red">
                -
              </span>
              <span v-if="receiptData.entryType == 1" style="color: red">
                {{ scope.row.amountReceivable }}
              </span>
              <span v-else>
                {{ scope.row.amountReceivable || 0 }}
              </span>
            </template>
          </el-table-column>
        </el-table>

        <!-- 合计 -->
        <el-row v-show="
            (receiptData.claimSalesName &&
              receiptData.claimTime &&
              status === 6) ||
            (receiptData.updateTime &&
              status === 3 &&
              receiptData.financeType !== 2) ||
            (receiptData.claimSalesName &&
              receiptData.claimTime &&
              status === 1) ||
            (receiptData.claimSalesName &&
              receiptData.claimTime &&
              status === 4)
          ">
          <div v-if="
              receiptData.financeType == 1 && receiptData.financeStatus != 2
            " class="money-sum">
            <span>合计</span>
            <span>实收金额:</span>
            {{ moneySum3[0] | orderMoneyFormat }}
            <span>手续费:</span>
            <span>{{ moneySum3[1] | orderMoneyFormat }}</span>
            <span>结算手续费:</span>
            <span>{{ moneySum[2] | orderMoneyFormat}}</span>
            <span>应收金额:</span>
            <span>{{ moneySum[3] | orderMoneyFormat}}</span>
          </div>
        </el-row>
      </el-row>

      <el-row class="row-line" style="display: none"></el-row>

      <!-- 已作废  floor5-->
      <el-row :gutter="20" type="flex" class="mt30" v-show="status === 5">
        <el-col>
          <label>作废人</label>
          <p>{{ receiptData.updateName || '--' }}</p>
        </el-col>
        <el-col>
          <label>作废原因</label>
          <p>{{ receiptData.statusRemark || '--' }}</p>
        </el-col>
        <el-col>
          <label>作废时间</label>
          <p>{{ receiptData.updateTime || '--' }}</p>
        </el-col>
      </el-row>
      <!-- 已驳回 floor5-->
      <el-row :gutter="20" type="flex" class="mt30" v-show="status === 3">
        <el-col>
          <label>驳回人</label>
          <p>{{ receiptData.updateName || '--' }}</p>
        </el-col>
        <el-col>
          <label>驳回原因</label>
          <p>{{ receiptData.statusRemark || '--' }}</p>
        </el-col>
        <el-col>
          <label>驳回时间</label>
          <p>{{ receiptData.updateTime || '--' }}</p>
        </el-col>
      </el-row>

      <div v-show="receiptData.claimTime">
        <el-row :gutter="20" type="flex">
          <el-col>
            <label>认领附件</label>
          </el-col>
        </el-row>

        <div v-if="claimedFileList.length">
          <div class="flie-list" v-for="(item, i) in claimedFileList" :key="i">
            <i class="el-icon-document"></i>
            <a class="page-link ml5">
              <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
                <span class="ell">
                  <router-link :to="`/preview-file/index?name=${item.name}&url=${item.url}`" target="_blank">
                    {{ item.name }}
                  </router-link>
                </span>
              </el-tooltip>
            </a>
            <span class="size ml10">
              {{ utils.calculateFileSize(item.size) }}
            </span>
            <span class="ml10">{{ item.time }}</span>
          </div>
        </div>
        <div v-else>--</div>
      </div>

      <!-- 待处理-财务二次确认 -->
      <el-row v-if="status === 6 && isOperate">
        <RefundRealAccount
          v-if="receiptData.financeType == '1_1' "
          :accountInfo="realAccount"
          :financeMethod="receiptData.financeMethod"
          disabled
          />
        <div style="margin-top: 30px" class="t_a_c">
          <el-button type="primary" @click="confirmClick">确认</el-button>
          <el-button @click="rejectClick">驳回</el-button>
        </div>
      </el-row>
      <el-row v-else>
        <RefundRealAccount
          v-if="receiptData.financeType == '1_1'"
          :accountInfo="realAccount"
          :financeMethod="receiptData.financeMethod"
          disabled
          />
        <div style="margin-top: 30px" class="t_a_c">
          <el-button @click="close">关 闭</el-button>
        </div>


      </el-row>

      <!-- 新增收款单弹窗 -->
      <CreditNoteAddModel ref="CreditNoteAdd" @updateListNote="close" />
    </div>
  </el-dialog>
</template>

<script>
  import {
    detailByFinanceOrder,
    updateClaimStatus,
  } from '@/api/finance/credit-manage-list'
  import tooltipOver from '@/components/base-tooltip'
  import CreditNoteAddModel from './credit-note-add-model'
  import RefundRealAccount from '@/components/RefundRealAccount/index'

  export default {
    name: 'ReceiptDetailModel1',
    components: {
      tooltipOver,
      CreditNoteAddModel,
      RefundRealAccount
    },
    data() {
      return {
        dialogReceiptVisible: false,
        status: '', // 收款单状态
        receiptData: {}, // 收款详情数据
        receiptTableData: [], //收款table
        orderTableData: [], // 底部订单表格
        fileList: [], //附件
        claimedFileList: [],
        isOperate: true,
        settleFeeStatus: false, //结算手续费为 0 时不展示
      }
    },
    computed: {
      realAccount() {
        let detail = this.receiptData || {}
        return {
          ...(detail.realAccount || {}),
          realAccountFlag:detail.realAccountFlag
        }
      },
      moneySum() {
        let vos = this.orderTableData
        if (vos?.length) {
          const sum = []
          sum[0] = vos.reduce((prev, next) => {
            prev += +next.amountCollected * 1000
            return prev
          }, 0)
          sum[1] = vos.reduce((prev, next) => {
            prev += +next.commissionAmount * 1000
            return prev
          }, 0)
          sum[2] = vos.reduce((prev, next) => {
            prev += +next.settleFee * 1000
            return prev
          }, 0)
          sum[3] = vos.reduce((prev, next) => {
            prev += +next.amountReceivable * 1000
            return prev
          }, 0)
          return sum.map((item) => {
            if (Number.isNaN(item) || item === 0) {
              return '--'
            } else {
              return (item / 1000).toFixed(3)
            }
          })
        } else {
          return 0
        }
      },
      moneySum2() {
        //退款费用合计
        let vos = this.orderTableData
        if (vos?.length) {
          const sum = []
          sum[0] = vos.reduce((prev, next) => {
            prev += next.amountCollected * 1000
            return prev
          }, 0)
          sum[1] = vos.reduce((prev, next) => {
            prev += next.commissionAmount * 1000
            return prev
          }, 0)
          sum[2] = vos.reduce((prev, next) => {
            prev += next.settleFee * 1000
            return prev
          }, 0)
          sum[3] = vos.reduce((prev, next) => {
            prev += next.amountCollected * 1000 - next.commissionAmount * 1000
            return prev
          }, 0)
          return sum.map((item) => {
            if (Number.isNaN(item) || item === 0) {
              return '-'
            } else {
              return (item / 1000).toFixed(2)
            }
          })
        } else {
          return 0
        }
      },

      moneySum3() {
        //销售订单费用合计
        let vos = this.receiptData.financeOrderVOS
        if (vos?.length) {
          const sum = []
          sum[0] = vos.reduce((prev, next) => {
            prev += +next.amountCollected * 1000
            return prev
          }, 0)
          sum[1] = vos.reduce((prev, next) => {
            prev += +next.commissionAmount * 1000
            return prev
          }, 0)
          sum[2] = vos.reduce((prev, next) => {
            prev += +next.settleFee * 1000
            return prev
          }, 0)
          sum[3] = vos.reduce((prev, next) => {
            prev += +next.amountReceivable * 1000
            return prev
          }, 0)
          return sum.map((item) => {
            if (Number.isNaN(item) || item === 0) {
              return '-'
            } else {
              return (item / 1000).toFixed(2)
            }
          })
        } else {
          return 0
        }
      },
    },
    filters: {
      //收款单状态-tag样式
      statusFilter(status) {
        const statusMap = {
          0: 'warning',
          1: 'warning',
          2: 'danger',
          3: 'danger',
          4: 'success',
          5: 'info',
          6: 'warning',
          7: 'danger',
          8: 'danger',
          9: 'danger',
          10: 'info',
        }
        return statusMap[status]
      },
      //收款单状态
      statusTextFilter(status) {
        const statusTextMap = {
          1: '待银行入账',
          2: '待认领',
          3: '已驳回',
          4: '已完成',
          5: '已作废',
          6: '已认领',
          7: '待业务确认',
          8: '审核中',
          9: '审核驳回',
          10: '已撤销',
        }
        return statusTextMap[status]
      },
      //收款来源
      financeTypeFilter(status) {
        const statusTextMap = {
          1: '销售订单',
          2: '其他费用',
          3: '采购退款',
          '1_1': '销售退款',
        }
        return statusTextMap[status]
      },
    },
    methods: {
      showReceiptDetail(financeCode, financeStatus, str) {
        this.isOperate = str
        this.status = Number(financeStatus)
        this.getDetailByFinanceOrder(financeCode)
        this.dialogReceiptVisible = true
      },

      //收款单详情
      async getDetailByFinanceOrder(financeCode) {
        let response = await detailByFinanceOrder({ financeCode: financeCode })
        if (response.code === '000000') {

          this.receiptData = response.data
          if (this.receiptData.entryType === 1) {
            this.receiptData.financeType = '1_1'
          }

          //收款单table  ，缺少手续费金额
          let receiptTableObj = {
            financeMethod: this.receiptData.financeMethod || '--', //退款方式
            // exportsRise: this.receiptData.exportsRise || '--', //出口抬头
            ticketNumber: this.receiptData.ticketNumber || '--', //票据单号
            currency: this.receiptData.currency || '--', //币种
            amountCollected:
              this.toFixed2(this.receiptData.amountCollected) || '--', //退款金额
            amountTime: this.receiptData.amountTime || '--', //退款日期
          }
          this.receiptTableData.push(receiptTableObj)

          //销售单table
          this.orderTableData = response.data.financeOrderVOS
          this.settleFeeStatus = response.data.financeOrderVOS?.some(
            (item) => item.settleFee != 0
          )
          //附件
          this.fileList = this.receiptData.financeUrl
            ? JSON.parse(this.receiptData.financeUrl)
            : ''

          this.claimedFileList = this.receiptData.claimedAffixUrl
            ? JSON.parse(this.receiptData.claimedAffixUrl)
            : ''
        }
      },

      //已认领，二次确认
      confirmClick() {
        updateClaimStatus({ financeId: this.receiptData.financeId }).then(
          (res) => {
            if (res.code == '000000') {
              this.$message.warning('操作成功')
              this.$emit('updateList')
              this.close()
            }
          }
        )
      },

      //驳回
      rejectClick() {
        this.$refs['CreditNoteAdd'].showCreditAdd(this.receiptData.financeId)
        this.close()
      },

      //订单编号跳转
      orderCodeClick(orderCode) {
        let routeData = this.$router.resolve({
          path: '/order/orderList/orderDetail',
          query: {
            orderCode: orderCode,
            noReturn: true,
          },
        })
        window.open(routeData.href, '_blank')
      },

      close() {
        this.isOperate = false
        this.status = ''
        this.receiptTableData = []
        this.orderTableData = []
        this.receiptData = {}
        this.fileList = []
        this.dialogReceiptVisible = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .content {
    .el-row {
      label {
        color: #666;
      }

      p {
        color: #000;
        font-size: 16px;
        margin-top: 5px;
      }
    }

    .row-line {
      border-bottom: 1px solid #ededed;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .link {
    color: #46a6ff;
    cursor: pointer;
    text-decoration: underline;
  }

  .green-bold {
    color: green;
    font-weight: bold;
  }

  .no-text {
    color: #999 !important;
  }

  ::v-deep {
    .el-tooltip {
      span {
        color: #000;
        font-size: 16px;
        white-space: nowrap;
        margin-top: 5px;
      }
    }
  }

  .money-sum {
    color: #666;
    text-align: right;
    padding: 5px;

    span {
      margin: 0 5px;
    }
  }
</style>
