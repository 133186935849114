<template>
  <div class="ml10" style="display: inline-block">
    <el-tooltip v-if="type === 'icon'" :disabled="tooltip" content="驳回" placement="top">
      <el-button type="danger" plain icon="el-icon-close" circle size="mini" @click="reject"></el-button>
    </el-tooltip>
    <el-button v-if="type === 'button'" type="danger" plain @click="reject">
      驳回
    </el-button>

    <el-dialog title="驳回" :visible.sync="dialogFormVisible" width="500px" @click="close" :close-on-click-modal="false"
      :append-to-body="true">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="驳回原因" prop="statusRemark" label-width="80px">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" maxlength="500" show-word-limit
            v-model="form.statusRemark" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer text-center">
        <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

  export default {
    name: 'reject',
    props: {
      // 悬浮是否显示名称
      tooltip: {
        type: Boolean,
        default: false,
      },
      // type button icon
      type: {
        type: String,
        default: 'icon',
      },
      saveReject: {
        type: Function,
        default: () => { },
      },
    },
    data() {
      return {
        dialogFormVisible: false,
        form: {
          statusRemark: '',
        },
        rules: {
          statusRemark: [{ required: true, message: '请输入', trigger: 'blur' }],
        },
      }
    },
    methods: {
      reject() {
        this.dialogFormVisible = true
      },

      close() {
        this.dialogFormVisible = false
        this.$refs.form.resetFields()
      },
      save() {
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            let response = await this.saveReject(this.form)
            if (response.code == '000000') {
              this.$message.success('已驳回')
              this.close()
              // 刷新列表数据
              this.$emit('getList')
            }
          } else {
            return false
          }
        })
      },
    },
  }
</script>
