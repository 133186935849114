<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="550px"
    @close="close"
    v-if="dialogFormVisible"
    append-to-body
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-row class="mb15">
        订单收款总金额与收款单金额不符，请选择多出金额的分配方式
      </el-row>
      <el-radio-group v-model="form.overchargeType" @change="radioChange">
        <el-radio :label="1">当前订单收入</el-radio>
        <el-radio :label="2">其他订单抵扣</el-radio>
        <el-radio :label="3">存入客户账户</el-radio>
      </el-radio-group>

      <!-- 其他订单抵扣 -->
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="80px"
        class="mt20 mb30"
      >
        <el-form-item
          label="订单编号"
          prop="orderCode"
          v-show="form.overchargeType === 2"
          :rules="[
            {
              required: form.overchargeType === 2 ? true : false,
              message: '请选择订单编号',
              trigger: 'change',
            },
          ]"
        >
          <el-select
            v-model="form.orderCode"
            placeholder="请选择"
            class="w240"
            @change="orderCodeChange"
          >
            <el-option
              v-for="(item, index) in orderCodeData"
              :key="index"
              :label="item.orderCode"
              :value="item.orderId"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="客户账户"
          prop="customerOrder"
          v-show="form.overchargeType === 3"
          :rules="[
            {
              required: form.overchargeType === 3 ? true : false,
              message: '请选择客户账户',
              trigger: 'change',
            },
          ]"
        >
          <el-select
            v-model="form.customerOrder"
            placeholder="请选择"
            class="w240"
            @change="customerOrderChange"
          >
            <el-option
              v-for="(item, index) in customerOrderData"
              :key="index"
              :label="
                item.orderCode +
                '-' +
                item.customerName +
                '-' +
                item.businessName
              "
              :value="item.orderCode"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import {
    financeInsertPO,
    oederCodeFinanceByInfoIds,
    serviceCharge,
  } from '@/api/finance/credit-note-add'
  import { deepCopy } from 'kits'
  export default {
    name: 'ConfirmMoneyCatchModel',
    props: {
      verifyBeforeSend: { type: Function } // 发送接口前校验
    },
    data() {
      return {
        title: '金额出入提示',
        form: {
          overchargeType: 1,
          orderCode: '', //订单编号
          customerOrder: '', //客户账户
        },
        rules: {
          // orderCode: [
          //   { required: true, trigger: 'change', message: '请选择订单编号' },
          // ],
          // customerOrder: [
          //   { required: true, trigger: 'change', message: '请选择客户账户' },
          // ],
        },
        dialogFormVisible: false,
        orderCodeData: [], //订单编号下拉
        customerOrderData: [], //客户账户下拉
        paramsForm: {}, //新增收款单数据
        orderObj: {}, //其他订单obj
        alreadyOrder: [], //已填写的主订单号
      }
    },
    created() {},
    methods: {
      showAddEdit(form) {
        this.alreadyOrder = []
        this.paramsForm = form

        //其他订单抵扣，查询订单下拉
        if (form.fovos) {
          let infoIdArr = []
          form.fovos.forEach((item, index) => {
            infoIdArr.push(item.infoId)
            this.alreadyOrder.push(item.orderCode)
            let obj = {
              orderCode: item.orderCode,
              customerName: item.customerName,
              businessName: item.businessName,
              infoId: item.infoId,
            }
            this.customerOrderData.push(obj)
          })

          this.getOederCodeFinanceByInfoIds(infoIdArr.join(','))
        }
        this.dialogFormVisible = true
      },

      //其他订单抵扣，查询订单下拉
      async getOederCodeFinanceByInfoIds(infoIdStr) {
        let response = await oederCodeFinanceByInfoIds({ infoIds: infoIdStr })
        if (response.code === '000000') {
          let data = deepCopy(response.data)
          if (this.alreadyOrder.length) {
            let result = []
            for (var i = 0; i < data.length; i++) {
              var obj = data[i]
              var num = obj.orderCode
              var isExist = false
              for (var j = 0; j < this.alreadyOrder.length; j++) {
                var aj = this.alreadyOrder[j]
                if (aj === num) {
                  isExist = true
                  break
                }
              }
              if (!isExist) {
                result.push(obj)
              }
            }
            this.orderCodeData = result
          }
        }
      },

      //其他订单-订单编号change
      orderCodeChange(val) {
        this.orderObj = this.orderCodeData.filter(
          (item) => item.orderId == val
        )[0]
        this.orderObj.orderType = 2 //其他订单
      },

      //客户账户change
      customerOrderChange(val) {},

      //radio change
      radioChange(val) {
        this.form.customerOrder = ''
        this.form.orderCode = ''
        this.orderObj = {}
      },

      //关闭
      close() {
        this.alreadyOrder = []
        this.orderCodeData = []
        this.customerOrderData = []
        this.paramsForm = {}
        this.orderObj = {}
        this.$refs['form']?.resetFields?.()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },

      //手续费计算
      financeMethodChange(currency, amountCollected, financeMethodCode) {
        //计算手续费金额
        let params = {
          currency: currency,
          payMoney: amountCollected,
          financeMethodCode: financeMethodCode,
        }
        serviceCharge(params).then((res) => {
          if (res.code === '000000') {
            this.$set(this.paramsForm.fovos[0], 'commissionAmount', res.data)
            this.$set(
              this.paramsForm.fovos[0],
              'amountReceivable',
              Number(amountCollected) - Number(res.data)
            )
          }
        })
      },

      //保存
      save() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            if (this.paramsForm.fovos && this.paramsForm.fovos.length) {
              delete this.paramsForm.claimedSalesId
              delete this.paramsForm.claimedSalesName
            }
            let form = this.utils.deepCopy(this.form)
            if (form.overchargeType === 1) {
              let residueFee = 0 //剩余金额
              let totalFee = 0 //实收总金额
              this.paramsForm.fovos.forEach((item) => {
                totalFee += Number(item.amountCollected)
              })

              //剩余金额=实收金额-实收总金额
              residueFee = Number(this.paramsForm.amountCollected) - totalFee

              this.paramsForm.overchargeType = 1

              //将收款金额赋值给第一条单据的实收金额
              this.paramsForm.fovos[0].amountCollected =
                residueFee + Number(this.paramsForm.fovos[0].amountCollected)

              // let params = {
              //   currency: this.paramsForm.fovos[0].currency,
              //   payMoney: this.paramsForm.fovos[0].amountCollected,
              //   financeMethodCode: this.paramsForm.financeMethodCode,
              // }
              // serviceCharge(params).then((res) => {
              //   if (res.code === '000000') {
              //手续费金额
              // this.paramsForm.fovos[0].commissionAmount = res.data
              //应收金额(实收-手续费)
              //     this.paramsForm.fovos[0].amountReceivable =
              //       Number(this.paramsForm.fovos[0].amountCollected) -
              //       Number(res.data)
              //     let paramsForm = deepCopy(this.paramsForm)
              //     this.save2(paramsForm)
              //   }
              // })
              this.save2(this.paramsForm)
            } else if (form.overchargeType === 2) {
              this.paramsForm.overchargeType = 2
              this.paramsForm.fovos.push(this.orderObj)
              let paramsForm = deepCopy(this.paramsForm)
              this.save2(paramsForm)
            } else if (form.overchargeType === 3) {
              this.paramsForm.overchargeType = 3
              this.paramsForm.customerOrder = this.form.customerOrder
              let paramsForm = deepCopy(this.paramsForm)
              this.save2(paramsForm)
            }
          }
        })
      },
      //save
      async save2(paramsForm) {
        let dt = {
          ...paramsForm,
          entryType: 0,
        }
        const sendRequest = async () => {
          const { code } = await financeInsertPO(dt)
          this.close()
          if(code === '000000') {
            this.$emit('closeParent')
            this.$message.success('操作成功！')
          }
        }
        this.verifyBeforeSend(sendRequest, true)
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }

  .w240 {
    width: 240px;
  }
</style>
