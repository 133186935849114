<!--
  功能：付款详情底部公共展示内容
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年03月03日 16:15:39
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div>
    <el-row class="mt20 remark">
      <el-col :span="6">
        <p>审核单号</p>
        <span>
          {{ detail.auditNumber }}
        </span>
        <p class="mt10">
          审核通过时间：{{ utils.cutDate(detail.auditTime) || '--' }}
        </p>
      </el-col>
      <el-col :span="6" v-if="state == 2 && detail.publicPayment != -1">
        <p>公账付款</p>
        <span>
          {{ detail.publicPayment === 1 ? '是' : '否' }}
        </span>
      </el-col>

      <el-col :span="12" v-if="state == 2 || state == 7">
        <p>付款账号</p>
        <span>
          {{ detail.payee }}/{{ detail.bankDeposit }}/{{
            detail.paymentAccount
          }}
        </span>
      </el-col>
    </el-row>
    <el-row v-if="state == 2 || state == 7" class="mt20 remark">
      <el-col :span="6">
        <p>实际付款时间</p>
        <span>
          {{ utils.cutDate(detail.paymentDate) || '-' }}
        </span>
      </el-col>
      <el-col :span="6">
        <p>确认付款人</p>
        <span>
          {{ detail.confirmUser ? detail.confirmUser : '-' }}
        </span>
      </el-col>
      <el-col :span="6">
        <p>操作时间</p>
        <span>
          {{ utils.cutDate(detail.confirmTime) }}
        </span>
      </el-col>
      <el-col :span="6">
        <p>备注</p>
        <span>
          {{ detail.confirmRemark ? detail.confirmRemark : '-' }}
        </span>
      </el-col>
    </el-row>
    <el-row class="mt20 remark">
      <el-col :span="24">
        <p>凭证/附件</p>
        <div v-if="fileList.length && detail.state != 1">
          <div class="flie-list" v-for="(item, i) in fileList" :key="i">
            <i class="el-icon-document"></i>
            <a class="page-link ml5">
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.name"
                placement="top-start"
              >
                <span class="ell">
                  <router-link
                    :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                    target="_blank"
                  >
                    {{ item.name }}
                  </router-link>
                </span>
              </el-tooltip>
            </a>
            <span class="size ml10">
              {{ utils.calculateFileSize(item.size) }}
            </span>
            <span class="ml10">{{ item.time }}</span>
          </div>
        </div>
        <span style="color: #606266" v-else>无</span>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  export default {
    name: 'PaymentDetailModelCommon',
    props: {
      detail: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        uploadParamsCertif2: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        financeUrl: '',
      }
    },
    computed: {
      state() {
        return this.detail.state
      },

      fileList() {
        return this.detail.confirmFileAnnex
          ? JSON.parse(this.detail.confirmFileAnnex)
          : []
      },
    },
  }
</script>

<style scoped lang="scss">
  .remark {
    > .el-col {
      p {
        margin-bottom: 14px;
      }
      > span {
        font-size: 16px;
        color: #000;
      }
    }
  }
</style>
