import request from '@/core/services/request'
export function findWarehouseList(data = {}) {
  return request({
    url: '/storage/warehouse/findWarehouseList',
    method: 'post',
    data: {
      hasAuth: 0,
      hasCloudWarehouse: 1,
      ...data,
    },
  })
}

export function findWarehouseList2() {
  return request({
    url: '/storage/warehouse/findWarehouseList',
    method: 'post',
    data: {
      hasAuth: 0,
      hasCloudWarehouse: 0,
    },
  })
}

//入库单列表
export function getListPage(data) {
  return request({
    url: '/storage/warehouseIn/listPage',
    method: 'post',
    data,
  })
}

// 入库单详情
export function getDetail(params) {
  return request({
    url: `/storage/warehouseIn/getDetail`,
    method: 'post',
    params,
  })
}

// 入库单详情
export function getDetailNoAuth(params) {
  return request({
    url: `/storage/warehouseIn/getDetailNoAuth`,
    method: 'post',
    params,
  })
}

export function userLogin() {
  return request({
    url: '/auth/userLogin',
    method: 'post',
    data: {
      loginName: '',
    },
  })
}

// 获取产品列表
export function listProductForStock(data) {
  return request({
    url: '/product/product/listProductForStock',
    method: 'post',
    data,
  })
}

// 获取库位产品数量
export function getWarehouseLocationNum(params) {
  return request({
    url: '/storage/warehouse/getWarehouseLocationNum',
    method: 'post',
    params,
  })
}

// 新增入库单
export function insertWarehouseIn(data = {}) {
  return request({
    url: '/storage/warehouseIn/insertWarehouseIn',
    method: 'post',
    data,
  })
}
// 编辑入库单
export function updateWarehouseIn(data = {}) {
  return request({
    url: '/storage/warehouseIn/updateWarehouseIn',
    method: 'post',
    data,
  })
}

// 撤销入库单
// export function cancel(params = {}) {
//   return request({
//     url: '/storage/warehouseIn/cancel',
//     method: 'post',
//     params,
//   })
// }

export function cancel(data) {
  return request({
    url: '/audit/auditObject/audit',
    method: 'post',
    data,
  })
}

// 获取审核详情

export function getAduitDetail(businessId = '') {
  return request({
    url: '/audit/auditObject/getDetail',
    method: 'get',
    params: {
      businessId,
    },
  })
}

// 根据审核类型获取审核模板
export function getAuditTemplates(data = {}) {
  return request({
    url: '/audit/auditTemplate/getAuditTemplates',
    method: 'post',
    data,
  })
}

// 获取审核人
export function getAuditUser(businessId = '') {
  return request({
    url: '/audit/auditTemplate/getDetail',
    method: 'get',
    params: {
      businessId,
    },
  })
}

// 提交审核
export function submitAudit(data) {
  return request({
    url: '/audit/auditObject/submitAudit',
    method: 'post',
    data,
  })
}

// 入库单--确认入库
export function ensure(data) {
  return request({
    url: '/storage/warehouseIn/ensure',
    method: 'post',
    data,
  })
}

// (采购订单)通过采购单号查找详情
export function getDetailForShowByNumber(number) {
  return request({
    url: '/order/purchaseOrder/getDetailForShowByNumber',
    method: 'post',
    params: {
      number,
    },
  })
}

// 根据订单号回显订单信息
// export function getDetailByOrderCode(orderCode) {
//   return request({
//     url: '/order/salesOrder/getDetailByOrderCode',
//     method: 'post',
//     params: {
//       orderCode,
//     },
//   })
// }

export function getDetailByOrderCode(orderCode, orderType) {
  return request({
    url: '/order/salesOrder/getDelyveryOrderProductByOrderCode',
    method: 'post',
    params: {
      orderCode,
      orderType,
    },
  })
}

// 获取auditObjectId 根据审核审核类型和单据ID查找审核对象

export function getAuditObject(data) {
  return request({
    url: '/audit/auditObject/getAuditObject',
    method: 'post',
    data,
  })
}

//出库单--根据orderId查询所有出库单号
export function getWarehouseOutNumberByOrderNumber(data) {
  return request({
    url: '/storage/warehouseOut/getWarehouseOutNumberByOrderNumber',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//出库单--根据出库单号查询出库产品(废弃)
export function getProductByOutNumber(data) {
  return request({
    url: '/storage/warehouseOut/getProductByOutNumber',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//进销存统计--导出
export function warehouseStatisticsExport(data) {
  return request({
    url: '/storage/warehouseStatistics/export',
    method: 'get',
    data,
    responseType: 'blob',
  })
}

//根据分页查找列表
export function warehouseStatisticsList(data) {
  return request({
    url: '/storage/warehouseStatistics/listPage',
    method: 'post',
    data,
  })
}

// 仓库库位下拉列表

export function findWarehouseLocation(data) {
  return request({
    url: '/storage/warehouse/findWarehouseLocation',
    method: 'post',
    data,
  })
}

// 采购订单权限判断

export function roleDetailForShow(businessId) {
  return request({
    url: '/order/purchaseOrder/roleDetailForShow',
    method: 'post',
    params: {
      businessId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 销售订单权限

export function shippingLookOrderAuthority(orderCode) {
  return request({
    url: '/order/salesOrder/shippingLookOrderAuthority',
    method: 'post',
    params: {
      orderCode,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 入库详情查看成本单
export function costAdjustWarehouseIn(data) {
  return request({
    url: '/storage/costAdjust/costAdjustWarehouseIn',
    method: 'post',
    data,
  })
}
