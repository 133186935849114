<!-- 财务中心-费用管理-费用详情 -->
<template>
  <el-dialog
    title="费用单详情"
    :visible.sync="dialogReceiptDetailVisible"
    width="1100px"
    @close="close"
    append-to-body
    v-if="dialogReceiptDetailVisible"
    :close-on-click-modal="false"
  >
    <div class="content">
      <el-row :gutter="20" class="detail-header">
        <el-col :span="7">
          <p>费用单号</p>
          <span>{{ form.costNumber }}</span>
          <span class="ml10">
            <el-tag
              type="primary"
              plain
              v-if="form.orderStatus == '4'"
              size="small"
            >
              审核中
            </el-tag>
            <el-tag
              type="warning"
              plain
              v-if="form.orderStatus === '1'"
              size="small"
              class="ml10"
            >
              待处理
            </el-tag>
            <el-tag
              type="danger"
              plain
              v-if="form.orderStatus === '3'"
              size="small"
            >
              已驳回
            </el-tag>
            <el-tag
              type="danger"
              plain
              v-if="form.orderStatus === '5'"
              size="small"
            >
              已撤销
            </el-tag>
            <el-tag
              type="danger"
              plain
              v-if="form.orderStatus === '6'"
              size="small"
            >
              付款驳回
            </el-tag>
            <el-tag
              type="success"
              plain
              v-if="form.orderStatus === '2'"
              size="small"
            >
              已完成
            </el-tag>
          </span>
        </el-col>
        <el-col :span="12">
          <p>收款方</p>
          <span v-if="form.dutyAccount">
            {{ form.accountName }}/{{ form.branchName }}/{{
              form.bankAccount
            }}/{{ form.dutyAccount }}
          </span>
          <span v-else>
            {{ form.accountName }}/{{ form.branchName }}/{{ form.bankAccount }}
          </span>
        </el-col>
      </el-row>

      <el-row class="mt30">
        <el-table border :data="topTableData">
          <el-table-column
            prop="costTypeName"
            align="center"
            show-overflow-tooltip
          >
            <template slot="header">
              费用类型
              <el-tooltip class="item" effect="dark" placement="bottom">
                <template slot="content">
                  <p>国内物流费：包含国内的快递、货拉拉、国内货运费用</p>
                  <p class="mt5">人工费：包含临时工的打包费、验货人工费用</p>
                  <p class="mt5">
                    手续费：包含美金收款手续费、国内美金结汇手续费
                  </p>
                </template>
                <i class="iconfont el-icon-question c_pointer"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="payName"
            align="center"
            label="支付方式"
          ></el-table-column>
          <el-table-column
            prop="currency"
            align="center"
            label="币种"
          ></el-table-column>
          <el-table-column align="center" label="支付金额($)">
            <template slot-scope="scope">
              <div :class="scope.row.entryType == 1 ? 'red' : ''">
                <span
                  v-show="scope.row.entryType == 1"
                  style="margin-left: -3px"
                >
                  -
                </span>
                <span
                  class="bold"
                  :class="scope.row.entryType == 1 ? '' : 'green'"
                >
                  {{ utils.numberFormat(scope.row.totalPayAmount, 2) }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="totalPayRmbAmount"
            align="center"
            label="支付金额(￥)"
          >
            <template slot-scope="scope">
              <div
                class="bold"
                :class="scope.row.entryType == 1 ? 'red' : 'green'"
              >
                <span
                  v-show="scope.row.entryType == 1"
                  style="margin-right: -3px"
                >
                  -
                </span>
                <span>
                  {{ utils.numberFormat(scope.row.totalPayRmbAmount, 2) }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="payTime"
            align="center"
            label="付款时间"
          ></el-table-column>
        </el-table>
      </el-row>
      <!-- 底部表格 -->
      <el-row>
        <template v-if="form.orderType === '1'">
          <el-table border :data="footTableData">
            <el-table-column
              prop="salesOrderNumber"
              align="center"
              label="销售订单号"
            >
              <template slot-scope="scope">
                <router-link
                  class="link"
                  v-if="form.orderType === '1'"
                  target="_blank"
                  :to="`/order/orderList/orderDetail?orderCode=${
                    scope.row.salesOrderNumber
                  }&noReturn=${true}`"
                >
                  {{ scope.row.salesOrderNumber }}
                </router-link>
                <span
                  class="link"
                  v-if="form.orderType === '2'"
                  @click="
                    purchaseOrderNumberClick(scope.row.purchaseOrderNumber)
                  "
                >
                  {{ scope.row.purchaseOrderNumber }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="businessName"
              align="center"
              label="客户代表"
            ></el-table-column>
            <el-table-column prop="area" align="center" label="地区">
              <template slot-scope="scope">
                {{ scope.row.area || '--' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="currency"
              align="center"
              label="币种"
            ></el-table-column>
            <el-table-column
              prop="orderTotalAmount"
              align="center"
              label="订单总金额"
            >
              <template slot-scope="scope">
                <span>
                  {{ matchCurrency(scope.row.currency)
                  }}{{ scope.row.orderTotalAmount | orderMoneyFormat }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="collectionAmount"
              align="center"
              label="已收金额"
            >
              <template slot-scope="scope">
                <span>
                  {{ matchCurrency(scope.row.currency)
                  }}{{ scope.row.collectionAmount | orderMoneyFormat }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="uncollectedAmount"
              align="center"
              label="未收金额"
            >
              <template slot-scope="scope">
                <span>
                  {{ matchCurrency(scope.row.currency) }}
                  {{ scope.row.uncollectedAmount | orderMoneyFormat }}
                </span>
              </template>
            </el-table-column>

            <el-table-column prop="payAmount" align="center" label="支付金额">
              <template slot-scope="scope">
                <span v-if="scope.row.payAmount">
                  <span
                    :class="
                      topTableData[0] && topTableData[0].entryType == 1
                        ? 'red'
                        : ''
                    "
                  >
                    <span
                      v-show="topTableData[0] && topTableData[0].entryType == 1"
                      style="margin-left: -3px"
                    >
                      -
                    </span>
                    <span
                      class="bold"
                      :class="
                        topTableData[0] && topTableData[0].entryType == 1
                          ? ''
                          : 'green'
                      "
                    >
                      {{ matchCurrency(form.currency) }}
                      {{ utils.numberFormat(scope.row.payAmount, 2) }}
                    </span>
                  </span>
                </span>
                <span v-else>--</span>
              </template>
            </el-table-column>

            <el-table-column label="产品分摊金额" align="center">
              <template slot-scope="scope">
                <el-popover placement="bottom" trigger="click">
                  <product-expense
                    :expenseList.sync="expenseList"
                    :orderType="form.orderType"
                    :entryType="form.entryType"
                  ></product-expense>
                  <el-link
                    type="primary"
                    slot="reference"
                    @click="getExpense(scope.row)"
                  >
                    点击查看
                  </el-link>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template v-else>
          <!-- 采购订单表格 -->
          <div v-for="(item, index) in footTableData" :Key="index">
            <el-table border :data="[item]">
              <el-table-column
                prop="salesOrderNumber"
                align="center"
                label="采购订单号"
              >
                <template slot-scope="scope">
                  <router-link
                    class="link"
                    v-if="form.orderType === '1'"
                    target="_blank"
                    :to="`/order/orderList/orderDetail?orderCode=${
                      scope.row.salesOrderNumber
                    }&noReturn=${true}`"
                  >
                    {{ scope.row.salesOrderNumber }}
                  </router-link>
                  <span
                    class="link"
                    v-if="form.orderType === '2'"
                    @click="
                      purchaseOrderNumberClick(scope.row.purchaseOrderNumber)
                    "
                  >
                    {{ scope.row.purchaseOrderNumber }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="buyerName"
                align="center"
                label="采购开发"
              ></el-table-column>
              <el-table-column prop="area" align="center" label="地区">
                <template slot-scope="scope">
                  {{ scope.row.area || '--' }}
                </template>
              </el-table-column>
              <el-table-column
                prop="currency"
                align="center"
                label="币种"
              ></el-table-column>
              <el-table-column
                prop="orderTotalAmount"
                align="center"
                label="订单总金额"
              >
                <template slot-scope="scope">
                  <span>
                    {{ matchCurrency(scope.row.currency)
                    }}{{ utils.numberFormat(scope.row.orderTotalAmount, 2) }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="collectionAmount"
                align="center"
                label="已付金额"
              >
                <template slot-scope="scope">
                  <span>
                    {{ matchCurrency(scope.row.currency)
                    }}{{ utils.numberFormat(scope.row.collectionAmount, 2) }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="uncollectedAmount"
                align="center"
                label="未付金额"
              >
                <template slot-scope="scope">
                  <span>
                    {{ matchCurrency(scope.row.currency) }}
                    {{ utils.numberFormat(scope.row.uncollectedAmount, 2) }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="payAmount" align="center" label="支付金额">
                <template slot-scope="scope">
                  <span v-if="scope.row.payAmount">
                    <span
                      :class="
                        topTableData[0] && topTableData[0].entryType == 1
                          ? 'red'
                          : ''
                      "
                    >
                      <span
                        v-show="
                          topTableData[0] && topTableData[0].entryType == 1
                        "
                        style="margin-left: -3px"
                      >
                        -
                      </span>
                      <span
                        class="bold"
                        :class="
                          topTableData[0] && topTableData[0].entryType == 1
                            ? ''
                            : 'green'
                        "
                      >
                        {{ matchCurrency(form.currency) }}
                        {{ utils.numberFormat(scope.row.payAmount, 2) }}
                      </span>
                    </span>
                  </span>

                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column label="产品分摊金额" align="center">
                <template slot-scope="scope">
                  <el-popover placement="bottom" trigger="click">
                    <product-expense
                      :expenseList.sync="expenseList"
                      :orderType="form.orderType"
                      :entryType="form.entryType"
                    ></product-expense>
                    <el-link
                      type="primary"
                      slot="reference"
                      @click="getExpense(scope.row)"
                    >
                      点击查看
                    </el-link>
                  </el-popover>
                </template>
              </el-table-column>
            </el-table>
            <el-table
              :data="item.financeOrderVOList"
              border
              style="width: 100%"
            >
              <el-table-column align="center" type="index" width="55" label="#">
                <template slot-scope="scope">
                  <span v-if="scope.$index < 9">0{{ scope.$index + 1 }}</span>
                  <span v-else>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              <el-table-column
                width="140"
                prop="orderCode"
                align="center"
                label="销售订单号"
              >
                <template slot-scope="scope">
                  <span class="blue-text">
                    <router-link
                      target="_blank"
                      :to="`/order/orderList/orderDetail?orderCode=${
                        scope.row.orderCode
                      }&noReturn=${true}`"
                    >
                      {{ scope.row.orderCode }}
                    </router-link>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="businessName"
                align="center"
                label="客户代表"
              ></el-table-column>
              <el-table-column
                prop="area"
                align="center"
                label="地区"
              ></el-table-column>
              <el-table-column
                prop="currency"
                align="center"
                label="币种"
              ></el-table-column>
              <el-table-column
                width="100"
                prop="totlePrice"
                align="center"
                label="订单总金额"
              >
                <template slot-scope="scope" v-if="scope.row.totlePrice">
                  <span v-if="scope.row.currency === '人民币'">￥</span>
                  <span v-if="scope.row.currency === '美元'">$</span>
                  {{ scope.row.totlePrice | orderMoneyFormat }}
                </template>
              </el-table-column>
              <el-table-column
                width="100"
                prop="amountReceived"
                align="center"
                label="已收金额"
              >
                <template slot-scope="scope">
                  <span
                    v-if="
                      scope.row.currency === '人民币' &&
                      scope.row.amountReceived
                    "
                  >
                    ￥
                  </span>
                  <span
                    v-if="
                      scope.row.currency === '美元' && scope.row.amountReceived
                    "
                  >
                    $
                  </span>
                  {{ scope.row.amountReceived | orderMoneyFormat }}
                </template>
              </el-table-column>
              <el-table-column
                width="100"
                prop="outstandingAmount"
                align="center"
                label="未收金额"
              >
                <template slot-scope="scope" v-if="scope.row.outstandingAmount">
                  <span v-if="scope.row.currency === '人民币'">￥</span>
                  <span v-if="scope.row.currency === '美元'">$</span>
                  {{ scope.row.outstandingAmount | orderMoneyFormat }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </el-row>
      <el-row class="mt20 remark">
        <el-col :span="6">
          <p>备注</p>
          <tooltip-over
            class="c_pointer"
            v-if="form.remark"
            :content="form.remark || '--'"
            refName="tooltipOver5"
          ></tooltip-over>
          <span v-else>--</span>
        </el-col>
        <el-col :span="6">
          <p>创建人</p>
          <span>{{ form.creatorName }}</span>
        </el-col>
        <el-col :span="6">
          <p>创建时间</p>
          <span>{{ form.createTime }}</span>
        </el-col>
      </el-row>
      <el-row class="mt20">
        <el-col :span="12" v-if="form.orderStatus == 4">
          <div class="upload-item">
            <ErpUpload
              ref="erpUpload"
              :uploadParams="uploadParamsCertif2"
              @uploadList="uploadCertifFileList"
            ></ErpUpload>
          </div>
        </el-col>
        <el-col :span="12">
          <Files :fileList="fileList" v-if="form.orderStatus != 4" />
        </el-col>
        <el-col :span="12" v-if="form.receiptFile">
          <div>
            <p>电子回单</p>
            <router-link
              :to="`/preview-file/index?name=${form.number + '.pdf'}&url=${
                form.receiptFile
              }`"
              target="_blank"
            >
              回单附件.PDF
            </router-link>
          </div>
        </el-col>
      </el-row>

      <el-divider />
      <el-row class="mt20 remark">
        <el-col :span="6">
          <p>审核单号</p>
          <span v-if="form.auditNumber">
            {{ form.auditNumber || '--' }}
          </span>
          <span v-else>--</span>
          <p style="font-size: 12px; margin-top: 10px">
            审核通过时间：{{ form.auditPassTime || '--' }}
          </p>
        </el-col>
        <template v-if="showPayInfo">
          <el-col :span="6">
            <p>公账付款</p>
            <span>
              {{ form.publicPayment === 1 ? '是' : '否' }}
            </span>
          </el-col>
          <el-col :span="12">
            <p>付款账号</p>
            <span>
              {{ form.paymentInfo || '--' }}
            </span>
          </el-col>
        </template>
      </el-row>

      <el-row class="mt20 remark" v-if="showPayInfo">
        <el-col :span="6">
          <p>付款时间</p>
          <span>
            {{ form.payTime || '--' }}
          </span>
        </el-col>
        <el-col :span="6">
          <p>确认付款人</p>
          <span>
            {{ form.confirmPayer || '--' }}
          </span>
        </el-col>
        <el-col :span="6">
          <p>操作时间</p>
          <span>
            {{ form.operateTime || '--' }}
          </span>
        </el-col>
        <el-col :span="6">
          <p>备注</p>
          <span>
            {{ form.confirmRemark || '--' }}
          </span>
        </el-col>
      </el-row>
      <div class="mt20">
        <Files
          :fileList="financeFileList"
          label="凭证/附件"
          v-if="showPayInfo"
        />
      </div>
      <el-row>
        <el-col :span="24">
          <div
            style="margin-top: 40px; margin-bottom: 30px"
            class="text-center"
          >
            <el-button @click="close" class="pl20 pr20">关 闭</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
  import { getDetail } from '@/api/finance/finance-cost'
  import tooltipOver from '@/components/base-tooltip'
  import { dinggetDetailForShow } from '@/api/finance/finance-payment'
  import Files from './cost-file-list'
  import { CostManageInteractor } from '@/core/interactors/finance/cost-manage'
  import { PaymentInteractor } from '@/core'

  import productExpense from '@/components/productExpense'
  export default {
    name: 'costDetail',
    components: { tooltipOver, Files, productExpense },

    data() {
      return {
        amendRow: '',
        dialogReceiptDetailVisible: false, //费用单详情弹窗
        tagStatus: 1,
        form: {},
        topTableData: [{}],
        footTableData: [],
        fileList: [], //附件
        financeUrl: [],
        financeFileList: [],
        expenseList: [], // 费用分摊表格
        uploadParamsCertif2: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
      }
    },

    computed: {
      showPayInfo() {
        return this.form.orderStatus == 2
      },
    },

    methods: {
      _handleFiles(data) {
        const field2field = {
            fileList: 'annex',
            financeFileList: 'financeUrl',
          },
          keys = ['fileList', 'financeFileList']
        keys.forEach((item) => {
          this[item] = data[field2field[item]]
            ? JSON.parse(data[field2field[item]])
            : ''
        })
      },
      matchCurrency(str) {
        return str === '美元' ? '$' : '￥'
      },
      async showReceiptDetail() {
        this.fileList = []
        this.financeUrl = []
        let response = await getDetail({ costCode: this.amendRow })
        if (response?.code == '000000') {
          this.dialogReceiptDetailVisible = true
          this.$set(this.topTableData, 0, response.data)
          this.footTableData = response.data.costOrderList
          this._handleFiles(response.data)

          this.form = response.data
          setTimeout(() => {
            if (response.data.annex) {
              this.$refs?.erpUpload?.initUpload(response.data.annex)
            }
          }, 400)
        }
      },

      //采购单号跳转
      async purchaseOrderNumberClick(code) {
        // 采购订单号跳转
        let response = await dinggetDetailForShow({
          businessId: code,
        })
        let data = response.data
        if (response.code == '000000') {
          let routeData = this.$router.resolve({
            path: '/purchase/purchase/purchaseOrder/purchaseOrderDetail',
            query: {
              id: data.purchaseOrderId,
              noReturn: true,
            },
          })
          window.open(routeData.href, '_blank')
        }
      },

      close() {
        this.dialogReceiptDetailVisible = false
        this.topTableData = [{}]
        this.footTableData = [{}]
        if (this.form.orderStatus == 4) {
          this.saveFiles()
        }
        this.form = {}
      },
      // 查看费用分摊
      async getExpense(row) {
        this.expenseList =
          await CostManageInteractor.getListProductExpenseByCostOrderId(
            row.costOrderId
          )
      },
      // 文件上传
      uploadCertifFileList(fileList) {
        this.financeUrl = JSON.stringify(fileList)
      },
      saveFiles() {
        let files = this.financeUrl.length
          ? this.financeUrl
          : JSON.stringify(this.fileList?.length > 0 ? this.fileList : [])
        PaymentInteractor.updateCostFile(files, this.form.costId)
      },

      // 附件回显
      initUpload(data) {
        if (data) this.uploadFileList = JSON.parse(data)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .content {
    padding: 0 20px 0px;
    max-height: 70vh;
    overflow-y: scroll;

    .detail-header {
      > .el-col {
        p {
          margin-bottom: 14px;
        }

        > span {
          font-size: 16px;
          color: #000;
        }
      }
    }

    .remark {
      > .el-col {
        p {
          margin-bottom: 14px;
        }

        > span {
          font-size: 16px;
          color: #000;
        }
      }
    }

    /deep/ .el-divider--horizontal {
      margin: 30px 0;
    }
  }

  .link {
    text-decoration: underline;
    color: #409eff;
    cursor: pointer;
  }

  ::v-deep {
    .el-dialog__header {
      padding-bottom: 0;

      > .el-dialog__title {
        font-size: 16px;
      }
    }
  }

  .upload-item {
    position: relative;

    .submit {
      position: absolute;
      left: 350px;
    }
  }
</style>
