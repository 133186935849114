import request from '@/core/services/request'
// 获取列表
export function financeListPage(data) {
  return request({
    url: '/finance/finance/listPage',
    method: 'post',
    data,
  })
}
//获取下拉框数据
export function selectOptions(data) {
  return request({
    url: '/finance/financeSetup/listPage',
    method: 'post',
    data,
  })
}
//根据收款单号查看详情
export function detailByFinanceOrder(data) {
  return request({
    url: '/finance/finance/getDetailByFinanceOrder',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
//根据收款单号查看详情 编码
export function detailByFinanceOrderCode(data) {
  return request({
    url: '/finance/finance/getDetailByFinanceOrderEncode',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      encrypt: true,
    },
  })
}

//根据订单号查看订单支付情况（列表页）
export function orderFinanceByOrderCode(data) {
  return request({
    url: '/finance/finance/getOderFinanceByOrderCode',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
//确认收款 根据ids查找//多选
export function listBatchCollection(data) {
  return request({
    url: '/finance/finance/listBatchCollection',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
//确认收款 根据id查找
export function confirmReceiptDetail(data) {
  return request({
    url: '/finance/finance/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 模糊查询收款方
export function listShorffAccount(data) {
  return request({
    url: '/finance/costSheet/listShorffAccount',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 根据收款单id完成付款单(点击确定收款单)
export function completeFinanceStatus(data) {
  return request({
    url: '/finance/finance/completeFinanceStatus',
    method: 'post',
    data,
  })
}
// 驳回和批量驳回
export function updateFinanceStatusReject(data) {
  return request({
    url: '/finance/finance/updateFinanceStatus',
    method: 'post',
    data,
  })
}
// 作废
export function cancellation(data) {
  return request({
    url: '/finance/finance/cancellation',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 设置科目
export function updateCourseTitle(data) {
  return request({
    url: '/finance/finance/updateCourseTitle',
    method: 'post',
    data,
  })
}
// 获取账号下拉
export function getAllShroffAccount(data) {
  return request({
    url: '/finance/shroffAccount/getAllShroffAccount',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 收款账户下拉

export function listShorffAccountPay(params) {
  return request({
    url: '/finance/costSheet/listShorffAccountPay',
    method: 'post',
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 角色，根据角色名称查找用户
export function listUserByRoleName(data) {
  return request({
    url: '/system/role/listUserByRoleName',
    method: 'post',
    data,
  })
}

// 1.4.2版本 更新已认领状态
export function updateClaimStatus(data) {
  return request({
    url: '/finance/finance/updateClaimStatus',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 1.4.2版本 收款确认(采购退款)
export function purchaseRefundConfirm(data) {
  return request({
    url: '/finance/finance/purchaseRefundConfirm',
    method: 'post',
    data,
  })
}

// 1.4.2版本 收款确认(采购退款)详情
export function purchaseRefundDetail(data) {
  return request({
    url: '/finance/finance/getPurchaseRefundDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 费用管理-批量导入费用单
export function financeImport(data) {
  return request({
    url: '/finance/cost/costImport',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data;charset=UTF-8',
    },
  })
}

// 费用管理-下载模板
export function financeDownload(data) {
  return request({
    url: '/finance/费用单导入.xlsx',
    method: 'get',
    responseType: 'blob',
    data,
  })
}

// 收款导入有销售单
export function importReceiptWithOrder(data) {
  return request({
    url: '/finance/finance/importReceiptWithOrder',
    method: 'post',
    data,
  })
}

// 收款导入无销售单
export function importReceiptWithoutOrder(data) {
  return request({
    url: '/finance/finance/importReceiptWithoutOrder',
    method: 'post',
    data,
  })
}

// 收款导入有销售单导入模板
export function hasPIReceiptTemplate(data) {
  return request({
    url: `/finance/hasPIReceipt.xlsx`,
    method: 'get',
    responseType: 'blob',
    data,
  })
}

// 收款导入无销售单导入模板
export function noPIReceiptTemplate(data) {
  return request({
    url: `/finance/noPIReceipt.xlsx`,
    method: 'get',
    responseType: 'blob',
    data,
  })
}
