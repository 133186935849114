<template>
  <el-dialog title="收款单详情" :visible.sync="dialogReceiptVisible" width="1200px" @close="close" append-to-body
    :close-on-click-modal="false">
    <div class="content">
      <el-row :gutter="20" class="detail-header">
        <el-col :span="6">
          <p>收款单号</p>
          <span>{{ receiptData.financeCode }}</span>
          <span class="ml10">
            <el-tag :type="receiptData.financeStatus | statusFilter" plain size="small">
              {{ receiptData.financeStatus | statusTextFilter }}
            </el-tag>
          </span>
        </el-col>
        <el-col :span="6">
          <p>收款来源</p>
          <span v-if="isRefund">销售退款</span>
          <span v-else>销售订单</span>
        </el-col>
        <el-col :span="6" v-if="status !== '5'">
          <p v-if="isRefund">收款方</p>
          <p v-else>付款方</p>
          <span>{{ receiptData.payee }}</span>
        </el-col>
        <el-col :span="6" v-if="status === '4'">
          <p>银行流水号</p>
          <span>
            <i class="red">{{ receiptData.serialNumber }}</i>
          </span>
        </el-col>
        <el-col :span="6" v-if="status === '5'">
          <p>创建人</p>
          <span>{{ receiptData.creatorName }}</span>
        </el-col>
        <el-col :span="6" v-if="status === '5'">
          <p>创建时间</p>
          <span>{{ receiptData.createTime }}</span>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center" :gutter="60" style="margin-top: 20px">
        <el-col :span="6">
          <label v-if="isRefund">退款方式</label>
          <label v-else>收款方式</label>
          <p>{{ receiptData.financeMethod || '--' }}</p>
        </el-col>
        <el-col :span="6">
          <label>币种</label>
          <p>{{ receiptData.currency || '--' }}</p>
        </el-col>
        <el-col :span="6">
          <label v-if="isRefund">退款金额</label>
          <label v-else>收款金额</label>
          <div style="white-space: nowrap" class="f_s_12">
            <span :class="receiptData.entryType == 1 ? 'red' : ''">
              {{ receiptData.entryType == 1 ? '-' : '' }}
              {{ receiptData.amountCollected | orderMoneyFormat }}
            </span>
            <span class="ml10">汇率：{{ receiptData.exchangeRate }}</span>
            <span class="ml10">
              RMB：
              <span :class="receiptData.entryType == 1 ? 'red' : ''">
                {{ receiptData.entryType == 1 ? '-' : '' }}
                {{ receiptData.amountCollectedRMB | orderMoneyFormat}}
              </span>
            </span>
          </div>
        </el-col>
        <el-col :span="6">
          <label v-if="isRefund">退款申请日期</label>
          <label v-else>收款日期</label>
          <p>{{ receiptData.amountTime || '--' }}</p>
        </el-col>
      </el-row>
      <!-- 循环展示底部订单列表 -->
      <el-row v-if="status !== '2'" class="mt30">
        <el-table :data="orderTableData" style="width: 100%" class="mb10" border v-if="orderTableData">
          <el-table-column prop="orderCode" label="订单编号" align="center"></el-table-column>
          <el-table-column prop="customerName" label="客户姓名" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="companyName" label="公司名称" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="businessName" label="客户代表" align="center"></el-table-column>
          <el-table-column prop="area" label="地区" align="center"></el-table-column>
          <el-table-column prop="currency" label="币种" align="center"></el-table-column>
          <el-table-column prop="totlePrice" label="订单金额" align="center">
            <template slot-scope="scope">
              <div>
                {{ scope.row.totlePrice | orderMoneyFormat}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="amountCollected" :label="isRefund ? '退款金额' : '实收金额'" align="center">
            <template slot-scope="scope">
              <div :class="receiptData.entryType == 1 ? 'red' : ''">
                {{ receiptData.entryType == 1 ? '-' : '' }}
                {{ scope.row.amountCollected |orderMoneyFormat }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="commissionAmount" :label="isRefund ? '退还手续费' : '手续费金额'" align="center">
            <template slot-scope="scope">
              <div :class="receiptData.entryType == 1 ? 'red' : ''">
                {{ receiptData.entryType == 1 ? '-' : '' }}
                {{ scope.row.commissionAmount |orderMoneyFormat}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="amountReceivable" :label="isRefund ? '实退金额' : '应收金额'" align="center">
            <template slot-scope="scope">
              <div :class="receiptData.entryType == 1 ? 'red' : ''">
                {{ receiptData.entryType == 1 ? '-' : '' }}
                {{ scope.row.amountReceivable | orderMoneyFormat }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <!-- 待认领底部展示 -->
      <el-row v-if="status === '2'">
        <el-divider />
        <el-row class="mt20 remark">
          <el-col :span="20">
            <p>推送客户代表</p>
            <span>{{ receiptData.updateName || '--' }}</span>
          </el-col>
        </el-row>
      </el-row>
      <!-- 已驳回底部展示 -->
      <el-row v-if="status === '3'">
        <el-divider />
        <el-row class="remark">
          <el-col :span="6">
            <p>驳回人</p>
            <span>{{ receiptData.updateName || '--' }}</span>
          </el-col>
          <el-col :span="12">
            <p>驳回原因</p>
            <textarea v-model="receiptData.statusRemark" rows="6" cols="50"></textarea>
          </el-col>
          <el-col :span="6">
            <p>驳回时间</p>
            <span>{{ receiptData.updateTime || '--' }}</span>
          </el-col>
        </el-row>
      </el-row>
      <!-- 已完成底部展示 -->
      <el-row v-if="status === '4'">
        <el-divider />
        <el-row class="remark">
          <el-col :span="6">
            <p>收款账户</p>
            <span>{{ receiptData.accountCredited || '--' }}</span>
          </el-col>
          <el-col :span="6">
            <p>银行入账日期</p>
            <span>{{ receiptData.bankAcceptanceTime || '--' }}</span>
          </el-col>
          <el-col :span="6">
            <p>确认人</p>
            <span>{{ receiptData.updateName || '--' }}</span>
          </el-col>
          <el-col :span="6">
            <p>确认时间</p>
            <span>{{ receiptData.updateTime || '--' }}</span>
          </el-col>
        </el-row>
      </el-row>
      <!-- 已作废底部展示 -->
      <el-row v-if="status === '5'">
        <el-divider />
        <el-row class="remark">
          <el-col :span="6">
            <p>作废人</p>
            <span>{{ receiptData.updateName || '--' }}</span>
          </el-col>
          <el-col :span="12">
            <p>作废原因</p>
            <textarea v-model="receiptData.statusRemark" rows="6" cols="50"></textarea>
          </el-col>
          <el-col :span="6">
            <p>作废时间</p>
            <span>{{ receiptData.updateTime || '--' }}</span>
          </el-col>
        </el-row>
      </el-row>
      <el-row class="mt20 remark">
        <el-col :span="10">
          <p>{{ isRefund ? '退款原因' : '备注' }}</p>
          <tooltip-over class="c_pointer" v-if="receiptData.remark" :content="receiptData.remark || '--'"
            refName="tooltipOver5"></tooltip-over>
        </el-col>

        <el-col :span="6" v-if="status !== '5'">
          <p>创建人</p>
          <span>{{ receiptData.creatorName || '--' }}</span>
        </el-col>
        <el-col :span="6" v-if="status !== '5'">
          <p>创建时间</p>
          <span>{{ receiptData.createTime || '--' }}</span>
        </el-col>
      </el-row>
      <el-row class="mt10">
        <el-col :span="24">
          <p>附件</p>
          <div v-show="fileList.length">
            <div class="flie-list" v-for="(item, i) in fileList" :key="i">
              <i class="el-icon-document"></i>
              <a class="page-link ml5">
                <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
                  <span class="ell">
                    <router-link :to="`/preview-file/index?name=${item.name}&url=${item.url}`" target="_blank">
                      {{ item.name }}
                    </router-link>
                  </span>
                </el-tooltip>
              </a>
              <span class="size ml10">
                {{ utils.calculateFileSize(item.size) }}
              </span>
              <span class="ml10">{{ item.time }}</span>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" class="mt30">
        <el-col>
          <label>认领附件</label>
        </el-col>
      </el-row>
      <div v-if="claimedFileList.length">
        <div class="flie-list" v-for="(item, i) in claimedFileList" :key="i">
          <i class="el-icon-document"></i>
          <a class="page-link ml5">
            <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
              <span class="ell">
                <router-link :to="`/preview-file/index?name=${item.name}&url=${item.url}`" target="_blank">
                  {{ item.name }}
                </router-link>
              </span>
            </el-tooltip>
          </a>
          <span class="size ml10">
            {{ utils.calculateFileSize(item.size) }}
          </span>
          <span class="ml10">{{ item.time }}</span>
        </div>
      </div>
      <div v-else>--</div>
      <el-row>
        <div style="margin-top: 30px" class="t_a_c">
          <el-button @click="close">关 闭</el-button>
        </div>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
  import { CreditManageInteractor } from '@/core'
  import tooltipOver from '@/components/base-tooltip'
  export default {
    name: 'ReceiptDetailModel',
    components: {
      tooltipOver,
    },
    data() {
      return {
        footTableData: [{}],
        dialogReceiptVisible: false, //收款单详情弹窗
        status: '', // 收款单状态
        receiptData: '', // 收款详情数据
        orderTableData: [], // 底部订单表格
        fileList: [], //附件
        isRefund: false, // 是否销售退款
        claimedFileList: [],
      }
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          1: 'warning',
          2: 'warning',
          3: 'danger',
          4: 'success',
          5: 'info',
          6: 'primary',
          7: 'primary',
          8: 'warning',
          9: 'danger',
          10: 'info',
        }
        return statusMap[status]
      },
      statusTextFilter(status) {
        const statusTextMap = {
          1: '待处理',
          2: '待认领',
          3: '已驳回',
          4: '已完成',
          5: '已作废',
          6: '已认领',
          7: '待确认',
          8: '审核中',
          9: '审核驳回',
          10: '已撤销',
        }
        return statusTextMap[status]
      },
    },
    methods: {
      /* 1待处理 2待认领3已驳回 4已完成 5已作废 */
      showReceiptDetail(financeCode, financeStatus) {
        this.status = financeStatus
        this.dialogReceiptVisible = true
        this.getDetailByFinanceOrder(financeCode)
      },
      // 根据订单编号查看订单详情
      async getDetailByFinanceOrder(financeCode) {
        let response = await CreditManageInteractor.detailByFinanceOrder({
          financeCode: financeCode,
        })
        if (response.code === '000000') {
          this.receiptData = response.data
          this.orderTableData = response.data.financeOrderVOS
          this.isRefund =
            this.receiptData.financeType === 1 &&
              this.receiptData.entryType === 1
              ? true
              : false
          this.fileList = this.receiptData.financeUrl
            ? JSON.parse(this.receiptData.financeUrl)
            : ''
          this.claimedFileList = this.receiptData.claimedAffixUrl
            ? JSON.parse(this.receiptData.claimedAffixUrl)
            : ''
        }
      },

      close() {
        this.dialogReceiptVisible = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .content {
    .detail-header {
      >.el-col {
        p {
          margin-bottom: 14px;
        }

        >span {
          font-size: 16px;
          color: #000;
        }
      }
    }

    .remark {
      >.el-col {
        p {
          margin-bottom: 14px;
        }

        >span {
          font-size: 16px;
          color: #000;
        }
      }
    }

    /deep/ .el-divider--horizontal {
      margin: 20px 0;
    }
  }
</style>
