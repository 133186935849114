<template>
  <el-row v-if="detailMsg != {}">

    <el-col :span="24">
      <table class="table-custom" align="center" cellspacing="0" cellpadding="0" width="100%">
        <tr height="50px">
          <td width="94px">订单金额</td>
          <td width="94px" class="f_w_6">
            <span v-if="detailMsg.totalPrice">
              {{ matchCurrency(currency) }}
            </span>
            {{ detailMsg.totalPrice | orderMoneyFormat}}
          </td>
          <td width="94px">已收金额</td>
          <td width="94px" class="c_success f_w_6">
            <span v-if="detailMsg.amountCollected">
              {{ matchCurrency(currency) }}
            </span>
            {{ detailMsg.amountCollected | orderMoneyFormat}}
          </td>
          <td width="94px">未收金额</td>
          <td width="94px" class="c_danger f_w_6">
            <span v-if="detailMsg.outstandingAmount">
              {{ matchCurrency(currency) }}
            </span>
            {{ detailMsg.outstandingAmount | orderMoneyFormat }}
          </td>
        </tr>
      </table>
    </el-col>
    <el-col :span="24">
      <el-table :data="detailMsg.payNodeVOS" border style="width: 100%">
        <el-table-column prop="paymentLink" align="center" label="收款节点">
          <template slot-scope="scope">
            {{ scope.row.paymentLink || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="paymentPropor" align="center" width="100" label="比例（%）">
          <template slot-scope="scope">
            {{ scope.row.paymentPropor || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="receivablePrice" align="center" label="应收金额">
          <template slot-scope="scope">
            <span v-if="scope.row.receivablePrice">
              {{ matchCurrency(currency) }}
            </span>
            {{
            scope.row.receivablePrice | orderMoneyFormat

            }}
          </template>
        </el-table-column>
        <el-table-column prop="totalPrice" align="center" label="实收金额">
          <template slot-scope="scope">
            <div :style="scope.row.entryType == 1 ? 'color:red' : ''">
              <span v-if="scope.row.entryType == 1">-</span>
              <span v-if="scope.row.totalPrice">
                {{ matchCurrency(currency) }}
              </span>
              {{ scope.row.totalPrice | orderMoneyFormat }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="payTime" align="center" width="120" label="收款时间">
          <template slot-scope="scope">
            {{ scope.row.payTime || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="financeCode" align="center" label="收款单号" width="140">
          <template slot-scope="scope">
            <span :class="scope.row.financeCode ? 'blue-text' : ''" @click="showDetail(scope.row)">
              {{ scope.row.financeCode || '--' }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
</template>
<script>
  export default {
    name: 'order-money-detail',
    data() {
      return {
        orderAmountInfo: {},
      }
    },
    props: {
      currency: {
        default: '',
        type: String,
      },
      detailMsg: {
        default: () => { },
        type: Object,
      },
    },
    methods: {
      matchCurrency(str) {
        return str === '美元' ? '$' : '￥'
      },
      showDetail(val) {
        this.$emit('showDetail', val)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .table-custom {
    border-collapse: collapse;
    text-align: left;

    td {
      border: #ebeef5 1px solid;
      text-align: center;
    }

    td:nth-child(odd) {
      background-color: #f5f7fa;
    }
  }
</style>
