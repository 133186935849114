export const accountFormData = {
  realAccountFlag: 1, // 是否關聯到真實賬戶標記
  accountNumber: '', // 賬號
  bankName: '', //银行名称
  bankCode: '', //银行代码
  bankAddress: '', //银行地址
  companyName: '', //公司名称
  companyAddress: '', //公司地址
  telephoneNumber: '', //电话号码
  emailAddress: '', //邮箱
}
