<template>
  <el-dialog
    title="费用单产品分摊情况"
    :visible.sync="dialogVisible"
    width="1100px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="content">
      <el-row :gutter="20" class="detail-header">
        <el-col :span="8">
          <p>费用单号</p>
          <span>{{ form.transactionCode }}</span>
          <span class="ml10">
            <el-tag type="primary" plain v-if="form.status == '4'" size="small">
              审核中
            </el-tag>
            <el-tag
              type="warning"
              plain
              v-if="form.status === '1'"
              size="small"
              class="ml10"
            >
              待处理
            </el-tag>
            <el-tag type="danger" plain v-if="form.status === '3'" size="small">
              已驳回
            </el-tag>
            <el-tag type="danger" plain v-if="form.status === '5'" size="small">
              已撤销
            </el-tag>
            <el-tag type="danger" plain v-if="form.status === '6'" size="small">
              付款驳回
            </el-tag>
            <el-tag
              type="success"
              plain
              v-if="form.status === '2'"
              size="small"
            >
              已完成
            </el-tag>
          </span>
        </el-col>
        <el-col :span="8">
          <p>费用类型</p>
          <span>{{ form.typeMemo || '--' }}</span>
        </el-col>
        <el-col :span="8">
          <p>费用金额</p>
          <span class="green">
            {{ form.currency == '人民币' ? '¥' : '$'
            }}{{ form.amount | formatPrice2 }}
          </span>
        </el-col>
      </el-row>
      <el-table border :data="tableData">
        <el-table-column
          prop="sku"
          align="center"
          label="产品编码"
        ></el-table-column>
        <el-table-column
          prop="productEn"
          align="center"
          label="产品名称"
        ></el-table-column>
        <el-table-column
          align="center"
          label="规格"
          prop="specification"
        ></el-table-column>
        <el-table-column
          align="center"
          label="订单数量"
          prop="amount"
        ></el-table-column>
        <el-table-column align="center" label="订单金额">
          <template slot-scope="scope">
            {{
              scope.row.currency == '人民币'
                ? '¥' + scope.row.rmbTotle
                : '$' + scope.row.usTotle
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="costContribution"
          align="center"
          label="当前产品分摊金额"
        ></el-table-column>
      </el-table>
      <el-row>
        <el-col :span="24">
          <div style="margin-top: 40px" class="text-center">
            <el-button @click="close" class="pl20 pr20">关 闭</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import { getOederCostDetail } from '@/api/order'
export default {
  name: 'costDetail',
  data() {
    return {
      dialogVisible: false, //费用单详情弹窗
      form: {},
      tableData: [],
    }
  },
  methods: {
    show(data, orerId) {
      this.dialogVisible = true
      this.form = {
        ...data,
      }
      getOederCostDetail({
        orderId: orerId,
        reAmount: data.reAmount,
      }).then((res) => {
        if (res.code == '000000') {
          this.tableData = res.data
        }
      })
    },

    close() {
      this.dialogVisible = false
      this.form = {}
      this.tableData = [{}]
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding: 0 20px 0px;
  .detail-header {
    margin-bottom: 20px;
    > .el-col {
      p {
        margin-bottom: 14px;
      }
      > span {
        font-size: 16px;
        color: #000;
      }
      .green {
        color: green;
        font-weight: bold;
      }
    }
  }
}
::v-deep {
  .el-dialog__header {
    padding-bottom: 0;
    > .el-dialog__title {
      font-size: 16px;
    }
  }
}
</style>
