const mixin = {
  data() {
    return {
      defaultUser: {

      },
      defaultQC: {

      }
    }
  },


  methods: {
    initDefaultUser({
      userId,
      userName,
      englishName
    }) {
      this.defaultUser = {
        userId,
        userName,
        englishName
      }
    },
  }

}

export default mixin