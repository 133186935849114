<template>
  <el-dialog
    title="费用申请"
    width="1200px"
    class="custom-dialog"
    :visible.sync="costVisible"
    :close-on-click-modal="false"
    :before-close="close"
    append-to-body
  >
    <el-form ref="ruleForm" :model="form" :rules="rules">
      <div class="mb-10">
        <el-radio-group v-model="form.entryType">
          <el-radio :label="0">费用申请</el-radio>
          <el-radio :label="1">费用退款</el-radio>
        </el-radio-group>
      </div>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item
            class="label-left"
            label="费用类型"
            prop="costTypeId"
            label-width="90px"
          >
            <el-tooltip placement="top" effect="light">
              <i class="el-icon-warning-outline"></i>
              <div slot="content">
                <p>国内物流费：包括国内的快递、货拉拉、国内货运费用</p>
                <p>人工费：包含临时工的打包费、验货人工费用</p>
                <p>手续费：包含美金收款手续费、国内美金结汇手续费</p>
              </div>
            </el-tooltip>
            <el-select
              v-model="form.costTypeId"
              placeholder="请选择"
              @change="costTypeChange"
            >
              <el-option
                v-for="item in costypeOptions"
                :key="item.financeSetupId"
                :label="item.financeName"
                :value="item.financeSetupId"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="支付方式" prop="payId" label-width="80px">
            <el-select
              v-model="form.payId"
              placeholder="请选择"
              @change="payChange()"
            >
              <el-option
                v-for="item in paymentWay"
                :key="item.financeSetupId"
                :label="item.financeName"
                :value="item.financeSetupId"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="付款时间" prop="payTime" label-width="80px">
            <el-date-picker
              v-model="form.payTime"
              style="width: 100%"
              type="date"
              placeholder="年/月/日"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="16">
          <el-form-item
            label="收款方"
            prop="shroffAccountId"
            label-width="90px"
          >
            <el-autocomplete
              v-model.trim="codeOrName"
              :fetch-suggestions="querySearchAsync"
              placeholder="请输入"
              class="w100"
              @select="costtHandleSelect"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="费用币种" prop="currency" label-width="80px">
            <el-select
              v-model="form.currency"
              placeholder="请选择"
              @change="calcPayAmount"
            >
              <el-option label="人民币" value="人民币" />
              <el-option label="美元" value="美元" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-table
        class="input-table"
        border
        width="100%"
        :data="form.costOrderList"
      >
        <el-table-column label="#" width="60" align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="salesOrder"
          :label="form.orderType == 1 ? '销售订单号' : '采购订单号'"
          width="180"
          align="center"
        >
          <template slot-scope="scope">
            <span
              class="page-link"
              @click="
                openDetail(
                  scope.row.salesOrderNumber || scope.row.purchaseOrderNumber
                )
              "
            >
              {{ scope.row.salesOrderNumber || scope.row.purchaseOrderNumber }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :prop="form.orderType == 1 ? 'businessName' : 'buyerName'"
          :label="form.orderType == 1 ? '客户代表' : '采购开发'"
          width="120"
          align="center"
        />
        <el-table-column prop="area" label="所属地区" align="center" />
        <el-table-column prop="currency" label="币种" align="center" />
        <el-table-column
          prop="orderTotalAmount"
          label="订单总金额"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="isViewCost">
              {{ scope.row.orderTotalAmount | orderMoneyFormat }}
            </div>
            <NoViewCost v-else />
          </template>
        </el-table-column>
        <el-table-column
          prop="collectionAmount"
          :label="form.orderType == 1 ? '已收金额' : '已付金额'"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="isViewCost">
              {{ scope.row.collectionAmount | orderMoneyFormat }}
            </div>
            <NoViewCost v-else />
          </template>
        </el-table-column>
        <el-table-column
          prop="uncollectedAmount"
          :label="form.orderType == 1 ? '未收金额' : '未付金额'"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="isViewCost">
              {{ scope.row.uncollectedAmount | orderMoneyFormat }}
            </div>
            <NoViewCost v-else />
          </template>
        </el-table-column>
        <el-table-column
          :label="form.entryType == 0 ? '支付金额' : '退款金额'"
          align="center"
          label-class-name="required-th"
          width="180"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="'costOrderList.' + scope.$index + '.payAmount'"
              :rules="[
                {
                  required: true,
                  pattern: /^[0-9]+(.[0-9]{1,2})?$/,
                  message: '>=0最多两位小数',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                v-model="form.costOrderList[scope.$index].payAmount"
                maxlength="10"
                placeholder="请输入"
                clearable
                @input="calcPayAmount"
                @change="calcProductMoney(scope.row)"
              />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="form.costOrderList.length > 1"
              class="icon-r"
              icon="el-icon-delete"
              circle
              @click="deleteOrder(scope)"
            />
            <span v-else>--</span>
          </template>
        </el-table-column>
      </el-table>
      <p class="mt10 mb20 text-right">
        {{ form.entryType == 0 ? '支付' : '退款' }}总金额：
        <span class="f_s_16">
          ${{ utils.numberFormat(form.totalPayAmount, 2) }}
        </span>
        <span class="f_s_16 ml10">
          ￥{{ utils.numberFormat(form.totalPayRmbAmount, 2) }}
        </span>
      </p>
      <!-- 产品分摊设置 -->
      <div>
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div>
            <h4 style="display: inline-block; margin-right: 10px">
              产品分摊设置
            </h4>
            <small v-if="form.orderType == 1">
              Tips：若产品不分摊费用，请填写“0”
            </small>
            <small v-if="form.orderType == 2">
              Tips：费用审核通过后，按需采购的费用会关联到销售订单产品内，备货采购费用计入库存内；
            </small>
          </div>
          <el-form-item
            prop="expenseMethod"
            label="分摊方式"
            label-width="80px"
          >
            <el-select
              v-model="form.expenseMethod"
              placeholder="请选择"
              @change="changeExpense"
            >
              <el-option
                v-for="item in expenseMethodOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </div>

        <div class="product-table">
          <el-table
            class="input-table"
            :data="form.costProductList"
            style="width: 100%"
            border
          >
            <el-table-column label="#" width="60" align="center">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column
              v-if="form.orderType == 1"
              prop="salesOrder"
              label="销售单号"
              width="180"
              align="center"
            />
            <el-table-column
              v-if="form.orderType == 2"
              prop="purchaseOrder"
              label="采购单号"
              width="120"
              align="center"
            />
            <el-table-column
              v-if="form.orderType == 2"
              prop="purchasingDemandNumber"
              label="采购需求单号"
              width="180"
              align="center"
            />
            <el-table-column
              prop="name"
              label="产品"
              width="220"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div style="overflow: hidden">
                  {{ scope.row.sku }}/{{ scope.row.productNameCn }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="form.orderType == 2"
              prop="numPurchase"
              label="本次采购数量"
              align="center"
              width="120"
            />
            <el-table-column
              v-if="form.orderType == 2"
              prop="amountIncludingTax"
              label="总计含税"
              align="center"
              width="120"
            >
              <template slot-scope="scope">
                <div v-if="isViewCost">
                  {{ utils.numberFormat(scope.row.amountIncludingTax, 2) }}
                </div>
                <NoViewCost v-else />
              </template>
            </el-table-column>
            <el-table-column
              v-if="form.orderType == 2"
              prop="amountExcludingTax"
              label="总计未税"
              align="center"
              width="120"
            >
              <template slot-scope="scope">
                <div v-if="isViewCost">
                  {{ utils.numberFormat(scope.row.amountExcludingTax, 2) }}
                </div>
                <NoViewCost v-else />
              </template>
            </el-table-column>
            <el-table-column
              v-if="form.orderType == 1"
              prop="productNum"
              label="订单数量"
              align="center"
            />
            <el-table-column
              v-if="form.orderType == 1"
              prop="totalProductAmount"
              label="产品金额总计"
              align="center"
            >
              <template slot-scope="scope">
                <div v-if="isViewCost">
                  {{ scope.row.totalProductAmount | orderMoneyFormat }}
                </div>
                <NoViewCost v-else />
              </template>
            </el-table-column>
            <el-table-column label="分摊费用" align="center">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'costProductList.' + [scope.$index] + '.expense'"
                  :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsInput'),
                      trigger: 'blur, change',
                    },
                    {
                      pattern: /^\d+(\.\d{1,2})?$/,
                      message: $t('placeholder.decimals2'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input v-model="scope.row.expense" placeholder="请输入" />
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :span="12">
          <p>
            <span v-if="form.entryType == 1" style="color: red">*</span>
            备注
          </p>
          <el-form-item
            prop="remark"
            :rules="[
              {
                required: form.entryType == 1,
                message: '请输入',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="form.remark"
              type="textarea"
              maxlength="500"
              show-word-limit
              :rows="3"
              placeholder="请输入500个字符以内"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <p>凭证/附件</p>
          <ErpUpload
            ref="ErpUpload"
            :upload-params="uploadParams"
            @uploadList="uploadList"
          />
        </el-col>
      </el-row>
    </el-form>

    <div class="text-center mt30">
      <el-button type="primary" :loading="loading" @click="save">
        确认
      </el-button>
      <el-button @click="close()">取消</el-button>
    </div>

    <!-- 审核模板-->
    <AuditChoose
      ref="AuditChoose"
      @auditData="saveClick"
      @audit-close="auditClose"
    />
  </el-dialog>
</template>

<script>
  import {
    listShorffAccount,
    listShorffAccountFee,
  } from '@/api/finance/finance-cost'
  import { orderCostApply, preExpenseApplication } from '@/api/order'
  import AuditChoose from '@/components/AuditChoose'

  import NoViewCost from '@/views/purchase/purchaseOrder/components/no-view-cost'
  import { mapGetters } from 'vuex'
  import { CostManageInteractor } from '@/core/interactors/finance/cost-manage'
  import _ from 'lodash'
  export default {
    name: 'CostApply',
    components: {
      AuditChoose,
      NoViewCost,
    },
    props: {
      isViewCost: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        loading: false,
        form: {
          /**
           * 基本信息
           */
          orderType: '', // 订单类型(1销售订单，2采购订单)
          costTypeId: '', // 费用类型id
          costTypeName: '', // 费用类型名称
          payId: '', // 支付方式id
          payName: '', // 支付方式名称
          payTime: '', // 付款时间
          annex: '', // 附件
          remark: '', // 备注
          currency: '', // 币种
          shroffAccountId: '', // 收款方id
          costOrderList: [], // 费用申请列表
          totalPayAmount: 0, // 支付总金额
          totalPayRmbAmount: 0, //	支付总金额(人民币)
          auditObjectDTO: {}, // 审核模板
          entryType: 0,
          expenseMethod: '', // 分摊方式
          costProductList: [], //产品分摊列表
        },
        source: '', // 入口来源
        exchangeRate: null, // 汇率
        costypeOptions: [], // 费用类型
        paymentWay: [], // 支付方式
        payeeOptions: [], // 收款方下拉数据
        costVisible: false,
        rules: {
          currency: [
            { required: true, message: '请选择费用币种', trigger: 'change' },
          ],
          costTypeId: [
            { required: true, message: '请选择费用类型', trigger: 'change' },
          ],
          payId: [
            { required: true, message: '请选择支付方式', trigger: 'change' },
          ],
          payTime: [
            { required: true, message: '请选择付款时间', trigger: 'change' },
          ],
          shroffAccountId: [
            { required: true, message: '请选择收款方', trigger: 'change' },
          ],
          expenseMethod: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
        },
        uploadParams: {
          amount: 9,
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        codeOrName: '',
        triggerBol: false,
        expenseMethodOptions: [
          // 分摊方式
          {
            label: '数量比例',
            value: 1,
          },
          {
            label: '金额比例',
            value: 2,
          },
          {
            label: '手动分摊',
            value: 3,
          },
        ],
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },
    methods: {
      // 初始化加载数据
      initLoading(type, data) {
        this.form.orderType = type
        this.form.currency = '人民币'
        this.getCostList(type, data)
        this.costVisible = true

        // 获取费用类型
        this.getCostType()
        // 获取支付方式
        this.getPaymentWay()
        // 获取汇率
        this.getExchangeRate()
        // 获取收款人
        this.getPayeeOptions()
      },
      // 根据订单号查询订单信息
      async getCostList(type, data) {
        let res = await CostManageInteractor.getListCostApplyByOrders(
          type,
          data
        )
        if (res?.code === '000000') {
          this.form.costOrderList = res.data
          res.data.forEach((item) => {
            this.form.costProductList = this.form.costProductList.concat(
              item.costProductExpenseDTOList
            )
          })
        }
      },
      //收款方搜索
      async querySearchAsync(queryString, cb) {
        this.triggerBol = true
        //if (!this.codeOrName) return
        await listShorffAccountFee({ cusName: this.codeOrName }).then((res) => {
          if (res?.code === '000000') {
            let results = res.data?.map((item) => {
              return {
                ...item,
                value: item.assembleStr,
              }
            })
            cb(results)
          }
        })
      },

      // 选择付款方
      costtHandleSelect(item) {
        if (item.shroffAccountId) {
          this.form.shroffAccountId = item.shroffAccountId
        }
      },

      // 费用类型
      async getCostType() {
        this.costypeOptions = await this.Dicts.getFinanceData(5)
      },

      // 支付方式
      async getPaymentWay() {
        this.paymentWay = await this.Dicts.getFinanceData(3)
      },

      // 收款人
      async getPayeeOptions() {
        let response = await listShorffAccount({ cusName: '' })
        this.payeeOptions = response.data
      },

      // 汇率
      async getExchangeRate() {
        this.exchangeRate = await this.Dicts.getExchange('美元')
      },

      // 获取上传文件地址
      uploadList(fileList) {
        this.form.annex = JSON.stringify(fileList)
      },

      // 费用类型选择
      costTypeChange(val) {
        this.costypeOptions.find((item) => {
          if (item.financeSetupId == val) {
            this.form.costTypeName = item.financeName
            this.form.expenseMethod = item.expenseMethod
          }
        })
        this.changeExpense()
      },

      // 支付方式选择
      payChange() {
        this.paymentWay.find((item) => {
          if (item.financeSetupId == this.form.payId) {
            this.form.payName = item.financeName
          }
        })
      },

      // 计算支付总金额
      calcPayAmount() {
        if (this.form.currency == '美元') {
          this.form.totalPayAmount = this.utils.amountTotal(
            this.form.costOrderList,
            'payAmount',
            3
          )
          this.form.totalPayRmbAmount = (
            this.form.totalPayAmount * this.exchangeRate
          ).toFixed(3)
        } else {
          this.form.totalPayRmbAmount = this.utils.amountTotal(
            this.form.costOrderList,
            'payAmount',
            3
          )
          this.form.totalPayAmount = (
            this.form.totalPayRmbAmount / this.exchangeRate
          ).toFixed(3)
        }
      },

      // 跳转详情
      openDetail(code) {
        if (this.form.orderType == 1) {
          let routeData = this.$router.resolve({
            path: '/order/orderList/orderDetail',
            query: { orderCode: code, noReturn: true },
          })
          window.open(routeData.href, '_blank')
        } else {
          let routeData = this.$router.resolve({
            path: '/purchase/purchase/purchaseOrder/purchaseOrderDetail',
            query: { id: code, noReturn: true },
          })
          window.open(routeData.href, '_blank')
        }
      },

      // 保存
      save() {
        let self = this
        self.$refs.ruleForm.validate((valid) => {
          if (valid) {
            if (!self.form.costOrderList.length) {
              self.$message.warning('请至少添加一条数据！')
              return false
            }
            if (self.form.totalPayRmbAmount.length > 10) {
              self.$message.warning('支付总金额不能超过10位数！')
              return false
            }
            for (let i = 0; i < this.form.costOrderList.length; i++) {
              const element = this.form.costOrderList[i]
              let totalPay = 0
              element.costProductExpenseDTOList.forEach((item) => {
                totalPay += Number(item.expense)
              })
              if (element.payAmount != _.round(totalPay, 3)) {
                this.$message.warning(
                  `${
                    element.salesOrderNumber || element.purchaseOrderNumber
                  }产品费用分摊不相等！`
                )
                return
              }
            }

            this.loading = true

            let auditParams = {
              auditType: 8,
              status: 0,
              tenantId: self.userInfo.tenantId,
            }
            if (!this.form.entryType) {
              let amountNum = this.utils.numberFormat(
                this.form.totalPayRmbAmount,
                2
              )
              if (amountNum >= 2000) {
                auditParams.financialAudit = true
              } else {
                auditParams.financialAudit = false
              }
            } else {
              auditParams.financialAudit = false
            }
            self.pubApi.getAuditTemplates(auditParams).then((response) => {
              if (!response?.data?.length) {
                this.loading = false
                self.$baseMessage(
                  '请联系管理员配置审核模板',
                  'warning',
                  false,
                  'erp-hey-message-warning'
                )
              } else {
                self.$refs['AuditChoose'].showAddEdit(auditParams)
              }
            })
          }
        })
      },

      auditClose() {
        this.loading = false
      },

      // 保存审核模板信息
      saveClick(data) {
        this.form.auditObjectDTO = data
        this.saveRule()
      },

      // 保存校验
      saveRule() {
        const params = {
          ...this.form,
        }
        this.saveCost(params)
      },

      // 保存费用申请
      saveCost(params) {
        delete params.productList
        orderCostApply(params)
          .then((res) => {
            if (res && res.code == '000000') {
              this.$message.success('保存成功！')
              this.$emit('success')
              this.close()
              if (this.source == 'purchaseDetail') {
                this.$emit(
                  'saveCost',
                  this.form.costOrderList[0].orderBusinessId
                )
              } else if (this.source == 'list') {
                this.$parent.getList()
              }
              this.loading = false
            } else {
              this.loading = false
            }
          })
          .then(() => {
            this.loading = false
          })
      },

      // 删除产品
      deleteOrder(scope) {
        this.$confirm(`是否确认删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.form.costOrderList.splice(scope.$index, 1)
            this.$message.success('删除成功！')
            this.calcPayAmount()
            this.deleteProduct(scope)
          })
          .catch(() => {})
      },
      // 删除产品
      deleteProduct(scope) {
        if (this.form.orderType == 1) {
          this.form.costProductList = this.form.costProductList.filter(
            (item) => {
              return item.salesOrder != scope.row.salesOrderNumber
            }
          )
        }
        if (this.form.orderType == 2) {
          this.form.costProductList = this.form.costProductList.filter(
            (item) => {
              return item.purchaseOrder != scope.row.purchaseOrderNumber
            }
          )
        }
      },

      // 关闭弹窗重置表单
      close() {
        this.costVisible = false
        this.form = this.$options.data().form
        this.$refs.ruleForm.resetFields()
        this.$refs.ErpUpload.uploadListEmpty()
        this.codeOrName = ''
      },
      // 计算产品分摊的金额
      calcProductMoney(row) {
        // 销售订单和采购订单需要的字段不同
        // 产品分摊金额要等于产品总金额
        // 有误差：总金额-产品分摊金额只和；差值加到最后一个产品上面

        let numType = this.form.orderType == 1 ? 'productNum' : 'numPurchase'
        let moneyType =
          this.form.orderType == 1 ? 'totalProductAmount' : 'amountIncludingTax'
        // 当只有一个产品时，不管分摊方式
        if (row.costProductExpenseDTOList.length === 1) {
          this.$set(row.costProductExpenseDTOList[0], 'expense', row.payAmount)
        } else {
          // 产品超过一个
          if (this.form.expenseMethod == 1) {
            // 按数量分
            let totalAmount = 0
            row.costProductExpenseDTOList.forEach((ele) => {
              totalAmount += ele[numType]
            })
            totalAmount = _.round(totalAmount, 3)
            // (row.payAmount * (ele[numType] / totalAmount)).toFixed(3)
            row.costProductExpenseDTOList.forEach((ele) => {
              this.$set(
                ele,
                'expense',
                _.round(row.payAmount * (ele[numType] / totalAmount), 2)
              )
            })
            // 拿到所有产品的总的分摊金额
            let totalProductPay = 0
            row.costProductExpenseDTOList.forEach((item) => {
              totalProductPay += item.expense
            })
            totalProductPay = _.round(totalProductPay, 3)

            // 判断总金额是否等于所有产品的金额
            if (totalAmount != totalProductPay) {
              let mistake = _.round(row.payAmount - totalProductPay, 3)
              // 最后一项
              let ele =
                row.costProductExpenseDTOList[
                  row.costProductExpenseDTOList.length - 1
                ]
              this.$set(
                ele,
                'expense',
                _.round(
                  (row.payAmount * ele[numType]) / totalAmount + mistake,
                  2
                )
              )
            }
          }
          // 按金额分
          if (this.form.expenseMethod == 2) {
            let totalPay = 0
            row.costProductExpenseDTOList.forEach((ele) => {
              totalPay += ele[moneyType]
            })
            totalPay = _.round(totalPay, 3)
            // (row.payAmount * (ele[moneyType] / totalPay)).toFixed(3)
            row.costProductExpenseDTOList.forEach((ele) => {
              this.$set(
                ele,
                'expense',
                _.round(row.payAmount * (ele[moneyType] / totalPay), 2)
              )
            })
            // 拿到所有产品的总的分摊金额
            let totalProductPay = 0
            row.costProductExpenseDTOList.forEach((item) => {
              totalProductPay += item.expense
            })
            totalProductPay = _.round(totalProductPay, 3)
            // 判断总金额是否等于所有产品的金额
            if (totalPay != totalProductPay) {
              let mistake = _.round(row.payAmount - totalProductPay, 2)
              // 最后一项
              let ele =
                row.costProductExpenseDTOList[
                  row.costProductExpenseDTOList.length - 1
                ]
              this.$set(
                ele,
                'expense',
                _.round(
                  (row.payAmount * ele[moneyType]) / totalPay + mistake,
                  2
                )
              )
            }
          }
          // 手动分摊，清空
          if (this.form.expenseMethod == 3) {
            row.costProductExpenseDTOList.forEach((ele) => {
              this.$set(ele, 'expense', '')
            })
          }
        }
      },
      // 切换分摊方式
      changeExpense() {
        this.form.costOrderList.forEach((item) => {
          this.calcProductMoney(item)
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-select-dropdown {
    width: 1080px;
  }

  .custom-dialog {
    .el-input,
    .el-select {
      width: 100%;
    }

    .el-form-item {
      margin-bottom: 18px;
    }

    p {
      margin: 0 0 10px;
    }

    .el-tooltip {
      position: absolute;
      top: 9px;
      left: -19px;
      cursor: pointer;
    }
  }

  ::v-deep {
    // .el-dialog__body {
    //   max-height: 680px;
    //   overflow-y: scroll;
    // }
    .el-form {
      max-height: 550px;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .el-upload--picture-card {
      width: 100px;
      height: 100px;
    }

    .el-icon-plus {
      position: relative;
      top: -20px;
    }

    .el-upload-list__item {
      width: 100px;
      height: 100px;
    }

    .el-upload-list__item-thumbnail {
      width: 100px;
      height: 100px;
    }

    .el-upload-list__item-actions {
      width: 100px;
      height: 100px;
    }
  }

  .mb-10 {
    margin-bottom: 10px;
  }
</style>
