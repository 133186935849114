const data = {
  orderStatusList: [
    {
      label: '全部',
      labelEn: 'All',
      value: '0',
    },
    {
      label: '待处理',
      labelEn: 'To Be Processed',
      value: '1',
    },
    {
      label: '审核中',
      labelEn: 'In Review',
      value: '4',
    },
    {
      label: '已通过',
      labelEn: 'Passed',
      value: '5',
    },
    {
      label: '待发货',
      labelEn: 'Pending Shipment',
      value: '6',
    },
    {
      label: '待收货',
      labelEn: 'Pending Delivery',
      value: '8',
    },
    // {
    //   label: '已发货',
    //   value: '7',
    // },
    {
      label: '已收货',
      labelEn: 'Delivered',
      value: '9',
    },
    {
      label: '已完成',
      labelEn: 'Completed',
      value: '10',
    },
    {
      label: '已作废',
      labelEn: 'Voided',
      value: '11',
    },
    // {
    //   label: '已驳回',
    //   labelEn: 'Rejected',
    //   value: '2',
    // },
  ],
  columns: [
    {
      label: '订单编号',
      width: '140',
      prop: 'orderCode',
      isShow: true,
      checkable: false,
      langKey: 'orderList.OrderNumber',
      isSort: false
    },
    {
      label: '签约日期',
      prop: 'contractDate',
      isShow: true,
      checkable: false,
      langKey: 'orderList.DateOfSigning',
      isSort: false,
      sortable: true,
      with: 200
    },
    {
      label: '产品编号',
      prop: 'productCode',
      isShow: true,
      checkable: true,
      langKey: 'orderList.ProductCode',
      isSort: false
    },
    {
      label: '产品名称',
      prop: 'productEn',
      isShow: true,
      checkable: true,
      langKey: 'orderList.ProductName',
      isSort: false
    },
    // {
    //   label: '产品类别',
    //   prop: 'productType',
    //   isShow: false,
    //   checkable: true,
    //   langKey: 'orderList.ProductCategory'
    // },
    {
      label: '产品数量',
      prop: 'amount',
      isShow: false,
      checkable: true,
      langKey: 'orderList.ProductQuantity',
      isSort: false
    },
    {
      label: '订单总金额',
      prop: 'totlePrice',
      isShow: true,
      checkable: true,
      langKey: 'orderList.TotalOrderAmount',
      isSort: false
    },
    {
      label: '订单原金额',
      prop: 'fromTotlePrice',
      isShow: false,
      checkable: true,
      langKey: 'orderList.OriginalOrderAmount',
      isSort: false
    },
    {
      label: '实际收款金额',
      prop: 'actualReceivablePrice',
      isShow: false,
      checkable: true,
      langKey: 'orderList.ActualReceivePaymentTime',
      isSort: false
    },
    {
      label: '客户代表',
      prop: 'businessName',
      isShow: true,
      checkable: true,
      langKey: 'orderList.CustomerRepresentative',
      isSort: false
    },
    {
      label: '订单支持',
      prop: 'fromBusinessName',
      isShow: false,
      checkable: true,
      langKey: 'orderList.OrderSupport',
      isSort: false
    },
    {
      label: '公司名称',
      prop: 'companyName',
      isShow: true,
      langKey: 'orderList.CompanyName',
      isSort: false
    },
    {
      label: '客户订单号',
      prop: 'customerOrderCode',
      isShow: true,
      checkable: true,
      langKey: 'orderList.CustomerOrderNumber',
      isSort: false
    },
    {
      label: '订单来源',
      prop: 'orderSource',
      isShow: true,
      checkable: true,
      langKey: 'orderList.OrderSource',
      isSort: false
    },
    {
      label: '是否使用折扣',
      prop: 'useDisAccount',
      isShow: true,
      checkable: true,
      langKey: 'orderList.UseDisAccount',
      isSort: false,
    },
    {
      label: '约定装运日期',
      prop: 'shippingDate',
      isShow: false,
      checkable: true,
      langKey: 'orderList.AgreedShipmentDate',
      isSort: false,
      sortable: true,
      width: 200
    },
    {
      label: '约定收货日期',
      prop: 'deliveryDate',
      isShow: false,
      checkable: true,
      langKey: 'orderList.AgreedDeliveryDate',
      isSort: false,
      sortable: true,
      width: 200
    },
    {
      label: '签收日期',
      prop: 'praDeliveryDate',
      isShow: false,
      checkable: true,
      langKey: 'orderList.DateOfReceipt',
      isSort: false
    },
    {
      label: '最近收款时间',
      prop: 'recentPayDate',
      isShow: false,
      checkable: true,
      langKey: 'orderList.LastReceivePaymentTime',
      isSort: false
    },
    {
      label: '完结类型',
      prop: 'endStateDesc',
      isShow: true,
      checkable: true,
      langKey: 'orderList.FinishType',
      isSort: false
    },
    {
      label: '状态',
      prop: 'orderStatus',
      isShow: true,
      checkable: false,
      langKey: 'orderList.Status',
      isSort: false
    },
    {
      label: '收款进度',
      prop: 'paymentSchedule',
      isShow: false,
      checkable: true,
      langKey: 'orderList.ReceivePaymentProgress',
      isSort: false
    },
    {
      label: '结算状态',
      prop: 'settlementStatus',
      isShow: true,
      checkable: false,
      langKey: 'orderList.SettlementStatus',
      isSort: false
    },
    {
      label: '未结算原因',
      prop: 'noSettlementRemark',
      isShow: true,
      checkable: true,
      langKey: 'orderList.UnsettledReason',
      isSort: false
    },
    {
      label: '进程',
      prop: 'progress',
      isShow: true,
      checkable: true,
      langKey: 'orderList.Process',
      isSort: false
    },
    {
      label: '客评单号',
      prop: 'evaluationCodes',
      isShow: true,
      checkable: true,
      width: 220,
      langKey: 'orderList.CustomerEvaluationNumber',
      isSort: false
    },
    {
      label: '跟单进度',
      prop: 'nodeNameCn',
      isShow: true,
      checkable: true,
      langKey: 'orderList.DocumentaryProgress',
      isSort: false
    },
    {
      label: '地区',
      prop: 'area',
      isShow: false,
      checkable: true,
      langKey: 'orderList.Area',
      isSort: false
    },
    {
      label: '创建人',
      prop: 'creatorName',
      isShow: false,
      checkable: true,
      langKey: 'orderList.Creator',
      isSort: false
    },
    {
      label: '审核通过日期',
      prop: 'auditPassTime',
      isShow: false,
      checkable: true,
      langKey: 'orderList.ApprovalDate',
      isSort: false,
      sortable: true,
      width: 200
    },
    {
      label: '创建时间',
      prop: 'createTime',
      isShow: false,
      checkable: true,
      langKey: 'orderList.CreationTime',
      isSort: false,
      sortable: true,
    },
  ],
  statusFilter: {
    1: {
      name: '待处理',
      type: 'primary',
    },
    2: {
      name: '待认领',
      type: 'error',
    },
    3: {
      name: '已驳回',
      type: 'warning',
    },
    4: {
      name: '已完成',
      type: 'success',
    },
    5: {
      name: '已作废',
      type: 'error',
    },
    6: {
      name: '已认领',
      type: 'success',
    },
    7: {
      name: '待确认',
      type: 'error',
    },
    8: {
      name: '审核中',
      type: 'warning',
    },
    9: {
      name: '审核驳回',
      type: 'error',
    },
    10: {
      name: '已撤销',
      type: 'error',
    },
  },
}
module.exports = data
