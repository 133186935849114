var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-drawer',{attrs:{"size":800,"title":"物流进度追踪","visible":_vm.drawer,"before-close":_vm.handleClose,"wrapper-closable":false},on:{"update:visible":function($event){_vm.drawer=$event}}},[_c('div',{staticClass:"drawer-wrap",style:(_vm.isAbnormal ? 'height: calc(100vh - 120px)' : '')},[(_vm.shipOrderLogistics.length || _vm.shipNoLogistics.length || _vm.isAbnormal)?[(_vm.shipNoLogistics.length)?_vm._l((_vm.shipNoLogistics),function(item,i){return _c('el-timeline',{key:i},[_c('div',{staticClass:"top"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":8}},[_vm._v(" Courier Company: "),_c('span',{staticClass:"bold"},[_vm._v(" "+_vm._s(_vm.getTailNodesInfo('company', item))+" ")])]),_c('el-col',{attrs:{"span":14}},[_vm._v(" Tracking Number: "),_c('span',{staticClass:"bold"},[_vm._v(_vm._s(item.data.number))]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(item.recordType === 2),expression:"item.recordType === 2"}],staticClass:"ml10",attrs:{"type":"primary","plain":"","size":"mini"}},[_vm._v(" 补录 ")])],1)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":8}},[_vm._v(" Transport status: "),_c('span',{class:{
                    'red-sty':
                      _vm.getTailNodesInfo('status', item) === 'Exception',
                  }},[_vm._v(" "+_vm._s(_vm.getTailNodesInfo('status', item))+" ")])]),_c('el-col',{attrs:{"span":16}},[_vm._v(" Number of shipping cases: "+_vm._s(_vm.getTailNodesInfo('cases', item))+" ")])],1)],1),_vm._l((_vm.getTailNodesInfo('node', item)),function(node,n){return _c('el-timeline-item',{key:n},[_c('div',{staticClass:"time-line"},[_c('span',{staticClass:"l"},[_vm._v(_vm._s(_vm._f("timeFormat")(node.time_iso)))]),_c('span',{staticClass:"r"},[_vm._v(" "+_vm._s(node.location ? node.location + ',' : '')+" "+_vm._s(node.description ? node.description + ',' : '')+" "+_vm._s(node.stage ? node.stage + ',' : '')+" ")])])])})],2)}):_vm._e(),(_vm.shipOrderLogistics.length)?[_c('el-tabs',{attrs:{"type":"card"},on:{"tab-click":function($event){_vm.handleClick(_vm.shipOrderLogistics, Number(_vm.active))}},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l((_vm.shipOrderLogistics),function(item,i){return _c('el-tab-pane',{key:i,attrs:{"label":item.transportNumber,"name":String(i)}},[(item.tailTrackNodesDtos.length)?_c('el-timeline',{staticStyle:{"margin-bottom":"-10px"}},[_c('div',{staticClass:"top"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":8}},[_vm._v(" Courier Company: "),_c('span',{staticClass:"bold"},[_vm._v(" "+_vm._s(_vm.getTailNodesInfo('company'))+" ")])]),_c('el-col',{attrs:{"span":16}},[_vm._v(" Tracking Number: "),_vm._l((_vm.numbers),function(num,n){return _c('span',{key:n,staticClass:"blue-sty",class:{ bold: n === _vm.cIndex },on:{"click":function($event){return _vm.changeTrack(i, n)}}},[_vm._v(" "+_vm._s(num)+" ")])})],2)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":8}},[_vm._v(" Transport status: "),_c('span',{class:{
                        'red-sty': _vm.getTailNodesInfo('status') === 'Exception',
                      }},[_vm._v(" "+_vm._s(_vm.getTailNodesInfo('status'))+" ")])]),_c('el-col',{attrs:{"span":16}},[_vm._v(" Number of shipping cases: "+_vm._s(_vm.getTailNodesInfo('cases'))+" ")])],1)],1),_vm._l((_vm.getTailNodesInfo('node')),function(node,n){return _c('el-timeline-item',{key:n},[_c('div',{staticClass:"time-line"},[_c('span',{staticClass:"l"},[_vm._v(_vm._s(_vm._f("timeFormat")(node.time_iso)))]),_c('span',{staticClass:"r"},[_vm._v(" "+_vm._s(node.location ? node.location + ',' : '')+" "+_vm._s(node.description ? node.description + ',' : '')+" "+_vm._s(node.stage ? node.stage + ',' : '')+" ")])])])})],2):_vm._e(),_c('el-timeline',[_c('div',{staticClass:"top"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":8}},[_vm._v(" Courier Company: "),_c('span',{staticClass:"bold"},[_vm._v("奇凡物流")])]),_c('el-col',{attrs:{"span":16}},[_vm._v(" Number of shipping cases: "+_vm._s(item.cartons)+" ")])],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":8}},[_vm._v(" Transport status: "),_c('span',{class:{
                        'red-sty': item.headStatus === 'Exception',
                      }},[_vm._v(" "+_vm._s(item.headStatus)+" ")])]),_c('el-col',{attrs:{"span":16}},[_vm._v(" Estimated date of delivery: "+_vm._s(_vm.getExpectArriveDate(item.expectArriveDate))+" ")])],1),_c('el-row',{directives:[{name:"show",rawName:"v-show",value:(item.headExceptionText),expression:"item.headExceptionText"}],attrs:{"gutter":20}},[_c('el-col',{staticStyle:{"text-indent":"10px"},attrs:{"span":24}},[_vm._v(" Anomalous cause: "),_c('span',{staticClass:"red-sty"},[_vm._v(" "+_vm._s(item.headExceptionText)+" ")])])],1)],1),(item.trackNodeDtos.length)?_c('div',_vm._l((item.trackNodeDtos),function(node,n){return _c('el-timeline-item',{key:n},[_c('div',{staticClass:"time-line"},[_c('span',{staticClass:"l"},[_vm._v(_vm._s(node.nodeTime))]),_c('span',{staticClass:"r"},[_vm._v(" "+_vm._s('[' + (node.nodeName ? node.nodeName : '') + ']')+" "+_vm._s(node.nodeText)+" ")])])])}),1):_vm._e()])],1)}),1)]:_vm._e(),(_vm.isAbnormal)?[(_vm.abnormalShipOrderNodes.length)?_c('section',[_c('el-tabs',{attrs:{"type":"card"},on:{"tab-click":function($event){_vm.handleClick(_vm.abnormalShipOrderNodes, Number(_vm.active))}},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l((_vm.abnormalShipOrderNodes),function(item,i){return _c('el-tab-pane',{key:i,attrs:{"label":item.transportNumber,"name":String(i)}},[_c('el-timeline',[_c('div',{staticClass:"top"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":12}},[_vm._v(" Courier Company: "),_c('span',{staticClass:"bold"},[_vm._v(" 奇凡物流 ")])]),_c('el-col',{attrs:{"span":12}},[_vm._v(" Number of shipping cases: "+_vm._s(item.cartons)+" ")])],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":12}},[_vm._v(" Transport status: "),_c('span',{class:{
                          'red-sty': item.headStatus === 'Exception',
                        }},[_vm._v(" "+_vm._s(item.headStatus)+" ")])]),_c('el-col',{attrs:{"span":12}},[_vm._v(" Estimated date of delivery: "+_vm._s(_vm.getExpectArriveDate(item.expectArriveDate))+" ")])],1),_c('el-row',{directives:[{name:"show",rawName:"v-show",value:(item.headExceptionText),expression:"item.headExceptionText"}],attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_vm._v(" Anomalous cause: "),_c('span',{staticClass:"red-sty"},[_vm._v(" "+_vm._s(item.headExceptionText)+" ")])])],1)],1),(item.trackNodeDtos.length)?_c('div',_vm._l((item.trackNodeDtos),function(node,n){return _c('el-timeline-item',{key:n},[_c('div',{staticClass:"time-line"},[_c('span',{staticClass:"l"},[_vm._v(_vm._s(node.nodeTime))]),_c('span',{staticClass:"r"},[_vm._v(" "+_vm._s('[' + (node.nodeName ? node.nodeName : '') + ']')+" "+_vm._s(node.nodeText)+" ")])])])}),1):_vm._e()])],1)}),1)],1):_c('section',[(_vm.abnormalShipNoNodes.length)?_c('el-timeline',[_c('div',{staticClass:"top"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":12}},[_vm._v(" Courier Company: "),_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.getTailNodesInfo('company')))])]),_c('el-col',{attrs:{"span":12}},[_vm._v(" Tracking Number: "),_c('span',{staticClass:"bold"},[_vm._v(" "+_vm._s(_vm.abnormalShipNoNodes[0].data.number)+" ")])])],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":12}},[_vm._v(" Number of shipping cases: "+_vm._s(_vm.getTailNodesInfo('cases'))+" ")]),_c('el-col',{attrs:{"span":12}},[_vm._v(" Transport status: "),_c('span',{class:{
                      'red-sty': _vm.getTailNodesInfo('status') === 'Exception',
                    }},[_vm._v(" "+_vm._s(_vm.getTailNodesInfo('status'))+" ")])])],1)],1),_vm._l((_vm.getTailNodesInfo('node')),function(node,n){return _c('el-timeline-item',{key:n},[_c('div',{staticClass:"time-line"},[_c('span',{staticClass:"l"},[_vm._v(_vm._s(_vm._f("timeFormat")(node.time_iso)))]),_c('span',{staticClass:"r"},[_vm._v(" "+_vm._s(node.location ? node.location + ',' : '')+" "+_vm._s(node.description ? node.description + ',' : '')+" "+_vm._s(node.stage ? node.stage + ',' : '')+" ")])])])})],2):_vm._e()],1)]:_vm._e()]:[_c('el-empty',{attrs:{"description":"暂无数据，请稍后再试！"}})]],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAbnormal),expression:"isAbnormal"}],staticClass:"btn-close"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.abnormalClose}},[_vm._v("关闭")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }