<template>
  <el-dialog
    title="收款登记"
    width="800px"
    class="dialog"
    v-if="payeeVisible"
    :visible.sync="payeeVisible"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <div v-if="orderId">
      <h4>
        <span class="red">*&nbsp;</span>
        收款凭证
      </h4>
      <erp-upload-images
        class="mt15 mb15"
        :limit="2"
        :tips-visible="false"
        @change="changeUploadList($event)"
      ></erp-upload-images>

      <p>最多上传2张，仅用于收款登记，实际收款金额已财务到账为准</p>
    </div>
    <div class="img-wrap mt20" v-else>
      <img
        :src="item"
        v-for="(item, i) in receiptRegistrationUrl.split(',')"
        :key="i"
        @click="handlePreview(receiptRegistrationUrl.split(','), i)"
      />
    </div>

    <div class="text-center mt20">
      <el-button type="primary" @click="save()" v-show="orderId">
        确定
      </el-button>
      <el-button @click="close()">取消</el-button>
    </div>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />
  </el-dialog>
</template>

<script>
import { uploadReceiptRegistration } from '@/api/order'
export default {
  data() {
    return {
      orderId: '',
      orderCode: '',
      receiptRegistrationUrl: '',
      payeeVisible: false,
      showViewer: false, //图片预览参数
      imgUrl: [], //图片预览url
    }
  },
  methods: {
    // 上传 rendingsUrl
    changeUploadList(list, index) {
      let _urls = []
      let _len = list.length

      if (_len) {
        for (let i = 0; i < _len; i++) {
          _urls.push(list[i].url)
        }
      }
      this.receiptRegistrationUrl = _urls.toString()
    },

    // 确定
    save() {
      if (!this.receiptRegistrationUrl) {
        this.$message.warning('请上传收款凭证！')
        return false
      } else {
        uploadReceiptRegistration({
          orderId: this.orderId,
          receiptRegistrationUrl: this.receiptRegistrationUrl,
        }).then((res) => {
          if (res && res.code === '000000') {
            this.close()
            this.$message.success('保存成功')
            this.$parent.getDetail(this.orderCode, 2)
          }
        })
      }
    },

    // 大图预览
    handlePreview(item, i) {
      if (Array.isArray(item)) {
        this.imgUrl = [item[i]]
      } else {
        this.imgUrl = [item]
      }
      this.showViewer = true
    },

    closeViewer() {
      this.showViewer = false
    },

    // 关闭弹窗重置表单
    close() {
      this.orderId = ''
      this.payeeVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog {
  h4 {
    margin: 10px 0 0;
  }
  .img-wrap {
    img {
      width: 150px;
      height: 150px;
      object-fit: cover;
      margin-right: 20px;
      border-radius: 2px;
      cursor: pointer;
    }
  }
}
::v-deep {
  .el-dialog__body {
    padding-top: 0;
  }
}
</style>
