<template>
  <el-table :key="tablekey" :data="expenseList" :max-height="360" border width="100%">
    <el-table-column width="60" align="center" label="#">
      <template slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
    </el-table-column>
    <el-table-column v-if="orderType == 1" width="150" property="salesOrder" label="销售单号" align="center"
      show-overflow-tooltip />
    <el-table-column v-if="orderType == 2" width="150" property="purchaseOrder" label="采购订单" align="center"
      show-overflow-tooltip />
    <el-table-column v-if="orderType == 2" width="150" property="purchasingDemandNumber" label="采购需求单号" align="center"
      show-overflow-tooltip />
    <el-table-column width="150" label="产品" align="center" show-overflow-tooltip>
      <template slot-scope="scope">
        {{ scope.row.sku }}/{{ scope.row.productNameCn }}
      </template>
    </el-table-column>
    <el-table-column v-if="orderType == 1" width="120" property="productNum" label="订单数量" align="center"
      show-overflow-tooltip />
    <el-table-column v-if="orderType == 1" width="150" label="产品金额总计" align="center" show-overflow-tooltip>
      <template slot-scope="scope">
        <div v-if="!needViewCost">
          {{ scope.row.totalProductAmount | orderMoneyFormat }}
        </div>
        <div v-else>
          <div v-if="isViewCost">
            {{ scope.row.totalProductAmount | orderMoneyFormat }}
          </div>
          <NoViewCost v-else />
        </div>
      </template>
    </el-table-column>
    <el-table-column v-if="orderType == 2" width="120" property="numPurchase" label="本次采购数量" align="center"
      show-overflow-tooltip />
    <el-table-column v-if="orderType == 2" width="100" label="总计含税" align="center" show-overflow-tooltip>
      <template slot-scope="scope">
        <div v-if="!needViewCost">
          {{ scope.row.amountIncludingTax | orderMoneyFormat }}
        </div>
        <div v-else>
          <div v-if="isViewCost">
            {{ scope.row.amountIncludingTax | orderMoneyFormat }}
          </div>
          <NoViewCost v-else />
        </div>
      </template>
    </el-table-column>
    <el-table-column v-if="orderType == 2" width="100" label="总计未税" align="center" show-overflow-tooltip>
      <template slot-scope="scope">
        <div v-if="!needViewCost">
          {{ utils.numberFormat(scope.row.amountExcludingTax, 2) }}
        </div>
        <div v-else>
          <div v-if="isViewCost">
            {{ utils.numberFormat(scope.row.amountExcludingTax, 2) }}
          </div>
          <NoViewCost v-else />
        </div>
      </template>
    </el-table-column>
    <el-table-column width="100" label="分摊费用" align="center" show-overflow-tooltip>
      <!-- entryType == 1 是退款显示 负值 -->
      <template slot-scope="scope">
        <div v-if="!needViewCost" :style="entryType == 1 ? 'color:red' : ''">
          {{ entryType == 1 && scope.row.expense ? '-' : ''
          }}{{ utils.numberFormat(scope.row.expense, 2) }}
        </div>
        <div v-else>
          <div v-if="isViewCost" :style="entryType == 1 ? 'color:red' : ''">
            {{ entryType == 1 && scope.row.expense ? '-' : ''
            }}{{ utils.numberFormat(scope.row.expense, 2) }}
          </div>
          <NoViewCost v-else />
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
  import viewCost from '@/views/order/orderList/util/index'
  import NoViewCost from '@/views/purchase/purchaseOrder/components/no-view-cost'
  import { number } from 'mathjs'
  export default {
    name: 'ProductExpense',
    components: { NoViewCost },
    props: {
      expenseList: {
        type: Array,
        default: () => [],
      },
      orderType: {
        type: [Number, String],
        default: 1,
      },
      // false 不要权限判断  true 需要权限判断
      needViewCost: {
        type: Boolean,
        default: false,
      },
      entryType: {
        type: [String, Number],
        default: 0,
      },
    },
    data() {
      return {
        isViewCost: viewCost(), //查看成本
        tablekey: Math.random(),
      }
    },
    watch: {
      expenseList: {
        handler() {
          this.tablekey = Math.random()
        },
        deep: true,
      },
    },
  }
</script>
>
