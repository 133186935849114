import request from '@/core/services/request'

// 费用管理-根据分页查找列表
export function listPage(data) {
  return request({
    url: '/finance/costSheet/listPage',
    method: 'post',
    data,
  })
}

// 费用管理-新增实体
// export function insertPO(data) {
//   return request({
//     url: '/finance/costSheet/insertPO',
//     method: 'post',
//     data,
//   })
// }

export function insertPO(data) {
  return request({
    url: '/finance/costSheet/insertPODecode',
    method: 'post',
    data,
  })
}

// 模糊查询收款方
export function listShorffAccount(data) {
  return request({
    url: '/finance/costSheet/listShorffAccount',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 模糊查询付款方
export function listShorffAccountPay(data) {
  return request({
    url: '/finance/costSheet/listShorffAccountPay',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 根据订单编号获取订单详情
export function getOrderByOrderCode(data) {
  return request({
    url: '/finance/costSheet/getOrderByOrderCode',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 根据(费用单号)costCode查找
export function getDetail(data) {
  return request({
    url: '/finance/costSheet/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 汇率换算
export function conversionExchangeRate(data) {
  return request({
    url: '/finance/costSheet/conversionExchangeRate',
    method: 'post',
    data,
  })
}

// 驳回
export function updateCostStatus(data) {
  return request({
    url: '/finance/costSheet/updateCostStatus',
    method: 'post',
    data,
  })
}

// 完成付款回显
export function getDetialCost(data) {
  return request({
    url: '/finance/costSheet/getDetialCost',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 保存完成付款
export function insertCostPay(data) {
  return request({
    url: '/finance/costSheet/insertCostPay',
    method: 'post',
    data,
  })
}
// 保存批量完成付款
export function insertCostPays(data) {
  return request({
    url: '/finance/costSheet/insertCostPays',
    method: 'post',
    data,
  })
}

// 设置科目
export function updateCourseTitle(data) {
  return request({
    url: '/finance/costSheet/updateCourseTitle',
    method: 'post',
    data,
  })
}

// (采购订单)通过销售单号查找采购单号
export function listPurchaseNumberBySales(data) {
  return request({
    url: '/order/purchaseOrder/listPurchaseNumberBySales',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// （采购订单)通过采购单号查找销售单号
export function listSalesNumberByPurchase(data) {
  return request({
    url: '/order/purchaseOrder/listSalesNumberByPurchase',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 客户抵扣金额查询
export function getDeductionAmountList(data) {
  return request({
    url: '/finance/customerAccountBalance/getDeductionAmountList',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 1.4.2版本 模糊查询收款方(费用)
export function listShorffAccountFee(data) {
  return request({
    url: '/finance/costSheet/listShorffAccountFee',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

export function listFinanceVliedSheet(data) {
  return request({
    url: '/finance/finance/listFinanceVliedSheet',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
