<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px" label-position="left">
      <!-- 头部表格 -->
      <el-row class="pro-list">
        <div class="c-table-wrap">
          <el-table class="input-table" :data="[1]" style="width: 100%" :header-cell-style="{ textAlign: 'center' }"
            :cell-style="{ textAlign: 'center' }" border>
            <el-table-column prop="date">
              <template slot="header">
                <i style="color: red">*</i>
                收款方
              </template>
              <template slot-scope="scope">
                <el-form-item prop="shroffAccountId">
                  <el-input v-model="form.shroffAccountId" placeholder="请输入" clearable maxlength="50"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="date">
              <template slot="header">
                <i style="color: red">*</i>
                退款方式
              </template>
              <template slot-scope="scope">
                <el-form-item prop="financeMethodCode">
                  <el-select v-model="form.financeMethodCode" placeholder="请选择" @change="financeMethodChange" clearable>
                    <el-option v-for="(item, index) in paymentOptions" :key="index" :label="item.financeName"
                      :value="item.financeSetupId"></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="date">
              <template slot="header">
                <i style="color: red" v-if="required">*</i>
                票据单号
              </template>
              <template slot-scope="scope">
                <el-form-item prop="ticketNumber">
                  <el-input v-model="form.ticketNumber" placeholder="请输入" clearable maxlength="50"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="date">
              <template slot="header">
                <i style="color: red">*</i>
                币种
              </template>
              <template slot-scope="scope">
                <el-form-item prop="currency">
                  <el-select v-model="form.currency" placeholder="请选择" @change="currencyChange" clearable>
                    <el-option v-for="(item, index) in currencyOptions" :key="index" :label="item.financeName"
                      :value="item.financeName"></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="date">
              <template slot="header">
                <i style="color: red">*</i>
                退款总金额
              </template>
              <template slot-scope="scope">
                <el-form-item prop="amountCollected">
                  <el-input v-model.trim="form.amountCollected" controls-position="right" :min="0" :precision="3"
                    placeholder="请输入" @input="amountCollectedChange" clearable maxlength="10"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="date">
              <template slot="header">
                <i style="color: red">*</i>
                退款申请日期
              </template>
              <template slot-scope="scope">
                <el-form-item prop="amountTime">
                  <el-date-picker size="small" v-model="form.amountTime" type="date" value-format="yyyy-MM-dd"
                    placeholder="年/月/日" clearable @change="changeAmountTime"></el-date-picker>
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-row>
      <!-- 中部表格 -->
      <el-row class="pro-list mt20">
        <el-row>
          <div class="c-table-wrap">
            <el-table class="mt10 input-table" :data="msgData" :header-cell-style="{ textAlign: 'center' }"
              :cell-style="{ textAlign: 'center' }" border>
              <el-table-column prop="date" show-overflow-tooltip min-width="160">
                <template slot="header">
                  <i style="color: red">*</i>
                  订单编号
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'fovos.' + scope.$index + '.orderCode'" :rules="[
                      {
                        required: true,
                        message: '订单编号必填',
                        trigger: 'blur',
                      },
                    ]">
                    <el-input placeholder="请输入内容" style="height: 36px" clearable v-model.trim="scope.row.orderCode"
                      @change="getMsgByCode(scope.row.orderCode, scope.$index)"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="客户姓名" min-width="110" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ scope.row.customerName || '--' }}
                </template>
              </el-table-column>
              <el-table-column label="公司名称" show-overflow-tooltip min-width="140">
                <template slot-scope="scope">
                  <div class="ell">
                    {{ scope.row.companyName || '--' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="客户代表" min-width="110" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ scope.row.businessName || '--' }}
                </template>
              </el-table-column>
              <el-table-column label="所属地区" min-width="110" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ scope.row.area || '--' }}
                </template>
              </el-table-column>
              <el-table-column label="币种" min-width="110">
                <template slot-scope="scope">
                  {{ scope.row.currency || '--' }}
                </template>
              </el-table-column>
              <el-table-column label="订单金额" min-width="110">
                <template slot-scope="scope">
                  <el-popover placement="bottom" width="800" trigger="click">
                    <orderMoneyDetail :ref="'orderMoneyDetail' + scope.$index" :currency="scope.row.currency"
                      :detailMsg="detailMsg" @showDetail="showDetail"></orderMoneyDetail>

                    <el-link type="primary" slot="reference" @click="handelTotalPriceClick(scope.row, scope.$index)">
                      {{ scope.row.totlePrice || '--' }}
                    </el-link>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column label="已收金额" min-width="110">
                <template slot="header">
                  <p>已收金额</p>
                  <p>（不含手续费）</p>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.amountReceived || '--' }}
                </template>
              </el-table-column>
              <el-table-column label="" min-width="110">
                <template slot="header">
                  <p>实收金额</p>
                  <p>（含手续费）</p>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.actualReceivedAmount | orderMoneyFormat }}
                </template>
              </el-table-column>
              <el-table-column label="退款金额" min-width="150" show-overflow-tooltip>
                <template slot="header">
                  <i style="color: red">*</i>
                  退款金额
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'fovos.' + scope.$index + '.amountCollected'" :rules="[
                      {
                        required: true,
                        message: '退款金额必填',
                        trigger: 'blur',
                      },
                      {
                        pattern: /^\d+(\.\d{1,2})?$/,
                        message: $t('placeholder.decimals2'),
                        trigger: 'blur',
                      },
                    ]">
                    <el-input placeholder="请输入内容" style="height: 36px" v-model.trim="scope.row.amountCollected" :min="0"
                      :precision="3" controls-position="right" clearable @change="fefundChange(scope.$index, scope.row)"
                      maxlength="10"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="退还手续费" width="120">
                <template slot-scope="scope">
                  {{ toFixed(scope.row.commissionAmount,2,true) }}
                </template>
              </el-table-column>
              <el-table-column label="结算手续费" width="120">
                <template slot-scope="scope">
                  --
                </template>
              </el-table-column>
              <el-table-column label="实退金额" width="120">
                <template slot-scope="scope">
                  {{
                  toFixed (
                  (scope.row.amountCollected - scope.row.commissionAmount)
                  ,2,true)
                  }}
                </template>
              </el-table-column>
              <el-table-column label="操作" fixed="right" width="80">
                <template slot-scope="scope">
                  <el-button type="danger" plain icon="el-icon-delete" circle
                    @click="deleteMsgData(scope.$index, scope.row)"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-row>
        <div class="money-sum">
          <span>合计</span>
          <span>退款金额:</span>
          {{ toFixed(moneySum[0],2,true) }}
          <span>退还手续费:</span>
          <span>{{ toFixed(moneySum[1],2,true) }}</span>
          <span>结算手续费:</span>
          --
          <span>实退金额:</span>
          <span>{{ toFixed(moneySum[3],2,true) }}</span>
        </div>
        <el-row>
          <el-col class="pt10 f_s_16 text-center">
            <span style="color: #409eff; cursor: pointer" @click="addOneLine">
              新增一行
            </span>
          </el-col>
        </el-row>
      </el-row>
      <!-- 退款原因 -->
      <el-row :gutter="20" class="mt30">
        <el-col :span="10" class="mr20">
          <el-form-item label="退款原因" prop="remark">
            <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" v-model="form.remark" maxlength="500"
              show-word-limit placeholder="请填写退款原因，并提供客户收款信息" :rows="3"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="ml30">
          <el-form-item label="凭证/附件">
            <el-col :span="24" style="padding: 0">
              <ErpUpload ref="ErpUpload" :uploadParams="uploadParams" @uploadList="uploadList" @initUpload="initUpload">
              </ErpUpload>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 真实账户 -->
      <RefundRealAccount :accountInfo="realAccount" :financeMethod="form.financeMethod" ref="realAccount" />
      <!-- 底部操作按钮 -->
      <el-row>
        <div class="mt50">
          <div slot="footer" class="dialog-footer text-center">
            <el-button type="primary" @click="save">确 认</el-button>
            <el-button @click="close2">取 消</el-button>
          </div>
        </div>
      </el-row>
    </el-form>

    <AuditChoose ref="AuditChoose" @auditData="saveAuditClick2" @auditData1="saveAuditClick2" />
    <!--收款单详情弹窗 -->
    <ReceiptDetailModelOut ref="ReceiptDetail" />

  </div>
</template>
<script>
  import {
    getOrderByOrderCode,
    financeOrderPayVO,
    calculationServiceCharge,
  } from '@/api/finance/credit-note-add'
  import orderMoneyDetail from './order-money-detail.vue'
  import AuditChoose from '@/components/AuditChoose'
  import { mapGetters } from 'vuex'
  import ReceiptDetailModelOut from '@/views/finance-center/credit-manage/components/receipt-detail-model'
  import RefundRealAccount from '@/components/RefundRealAccount/index'
  import { accountFormData } from '@/components/RefundRealAccount/data'
  import {deepCopy} from 'kits'
  const math = require('mathjs')

  export default {
    name: 'sale-refund',
    components: { orderMoneyDetail, AuditChoose, ReceiptDetailModelOut, RefundRealAccount },
    props: {
      // 获取详情
      financeObj: {
        type: Object,
        default: () => { },
      },
      financeType: {
        type: Number,
        default: 0,
      },
      // 退款方式
      paymentOptions: {
        type: Array,
        default: () => [],
      },
      // 币种
      currencyOptions: {
        type: Array,
        default: () => [],
      },
      // 0票据单号不必填 1票据单号必填
    },
    data() {
      return {
        required: 0, // 0票据单号不必填 1票据单号必填
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
          amount: 9,
        },

        form: {
          shroffAccountId: '',
          financeMethodCode: '',
          ticketNumber: '',
          currency: '',
          amountCollected: '',
          amountTime: '',
          orderCode: '',
          remark: '',
          refundMoney: 0, //amountCollected
          financeUrl: '', // 附件
          financeType: 1,
          overchargeType: 1,
          shroffAccountId: '', // 收款方id
          ticketNumber: '', // 票据单号
          ticketNumberRequired: 0,
        },
        realAccount: {
          ...accountFormData
        },

        rules: {
          shroffAccountId: [
            { required: true, message: '请输入收款方', trigger: 'change' },
          ],
          financeMethodCode: [
            { required: true, message: '请选择退款方式', trigger: 'change' },
          ],
          currency: [
            { required: true, message: '请选择币种', trigger: 'change' },
          ],
          amountCollected: [
            { required: true, message: '请输入退款总金额', trigger: 'blur' },
            {
              pattern: /^\d+(\.\d{1,2})?$/,
              message: this.$t('placeholder.decimals2'),
              trigger: 'blur',
            },
          ],
          amountTime: [
            { required: true, message: '请选择退款日期', trigger: 'blur' },
          ],
          ticketNumber: [
            { required: true, message: '请输入票据单号', trigger: 'blur' },
          ],
          remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
          refundMoney: [
            { required: true, message: '退款金额金额必填', trigger: 'blur' },
            {
              pattern: /(^[1-9](\d+)?(\.\d{1,3})?$)|(^\d\.\d{1,3}$)/,
              message: '格式不正确',
              trigger: 'blur',
            },
          ],
        },

        msgData: [],
        currentChartType: '',
        detailMsg: {},
        commissionAmount: 0, // 国际手续费
        settleFee: 0, // 结算手续费
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      moneySum() {
        let vos = this.msgData
        const sum = []
        sum[0] = vos.reduce((prev, next) => {
          prev += +next.amountCollected * 1000
          return prev
        }, 0)
        sum[1] = vos.reduce((prev, next) => {
          prev += +next.commissionAmount * 1000
          return prev
        }, 0)

        sum[2] = vos.reduce((prev, next) => {
          prev += next.settleFee * 1000
          return prev
        }, 0)
        sum[3] = vos.reduce((prev, next) => {
          prev += next.amountCollected * 1000 - next.commissionAmount * 1000
          return prev
        }, 0)
        return sum.map((item) => {
          if (Number.isNaN(item) || item === 0) {
            return '-'
          } else {
            return item / 1000
          }
        })
      },
    },
    created() {
      if (this.financeObj != {}) {
        this.form = this.financeObj
        this.msgData = this.form.fovos
        this.currentChartType = this.form.currency
      }
    },
    mounted() {
      this.$nextTick(() => {
        if (this.form.financeMethodCode) {
          this.financeMethodChange(this.form.financeMethodCode)
        }
        if (this.form.financeUrl) {
          this.initUpload(this.form.financeUrl)
        }
        this.$refs.form.rules.amountCollected[0].required = false
        this.$refs.form.rules.amountTime[0].required = false
      })
    },
    methods: {
      // 保留指定位数
      toFixed(num, decimal, isNeed0 = false) {
        if (num == '-') {
          return num
        }
        num = num * 1
        const strfi = String(num)
        const dotPos1 = strfi.indexOf('.')
        if (dotPos1 === -1) {
          return isNeed0 ? num.toFixed(decimal) : num
        }
        const len1 = strfi.substring(dotPos1 + 1).length
        if (len1 <= decimal) {
          return isNeed0 ? num.toFixed(decimal) : num
        }
        // num的小数位超过保留位数，则进行四舍五入
        const numStr = strfi.substring(0, dotPos1 + decimal + 1 + 1)
        const dotPos2 = numStr.indexOf('.')
        const len2 = numStr.substring(dotPos2 + 1).length
        const times = Math.pow(10, len2)
        const intNum = Number(numStr.replace('.', ''))
        const t = times / 10
        const n = intNum / 10
        const f = Math.round(n) / t
        return isNeed0 ? f.toFixed(decimal) : f
      },
      // 新增一行
      addOneLine() {
        this.$refs.form.rules.remark[0].required = false
        this.$refs.form.rules.refundMoney[0].required = false

        this.$refs.form.validate((valid) => {
          if (valid) {
            let obj = {}
            this.msgData.push(obj)
            this.$refs.form.rules.remark[0].required = true
            this.$refs.form.rules.refundMoney[0].required = true
          } else {
            return false
          }
        })
      },
      deleteMsgData(index, val) {
        this.$confirm('此操作将该条数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })

            this.msgData.splice(index, 1)
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      },
      // 文件上传
      uploadList(fileList) {
        this.form.financeUrl = JSON.stringify(fileList)
      },
      initUpload(data) {
        this.$refs.ErpUpload.initUpload(data)
      },
      // 保存
      save() {
        this.$refs.form.rules.amountCollected[0].required = true
        this.$refs.form.rules.amountTime[0].required = true
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.$refs.realAccount.validateAccountForm().then(valid2 => {
              if (valid2) {
                if (this.msgData.length < 1) {
                  this.$message.warning('请输入订单信息！')
                  return
                }
                this.msgData = this.msgData.map((item) => {
                  //应收金额 与退款金额一样，页面上不展示
                  return {
                    ...item,
                    amountReceivable: (
                      item.amountCollected - item.commissionAmount
                    ).toFixed(3),
                    orderType: 1,
                  }
                })
                this.form.fovos = this.msgData
                this.form.overchargeType = 1
                this.form.entryType = 1

                // 判断币种是否一致
                let st = this.msgData.every((item) => {
                  return item.currency == this.currentChartType
                })
                if (!st) {
                  this.$message.warning('订单币种与收款单币种不一致')
                  return
                }
                let totalAmountCollected = 0
                if (this.form.fovos.length > 1) {
                  let arr = this.form.fovos.map((item) => {
                    return item.amountCollected
                  })
                  totalAmountCollected = arr.reduce((pre, cur) => {
                    return Number(pre) + Number(cur)
                  })
                } else {
                  totalAmountCollected = Number(this.form.fovos[0].amountCollected)
                }
                if (
                  Number(totalAmountCollected) != Number(this.form.amountCollected)
                ) {
                  this.$message.warning('订单退款金额与退款总金额不一致！')
                  return
                }
                // 实退金额不能大于已收金额
                let boo = this.form.fovos.every(
                  (item) =>
                    Number(
                      (item.amountCollected - item.commissionAmount).toFixed(3)
                    ) > Number(item.amountReceived).toFixed(3)
                )
                if (boo) {
                  this.$message.warning('实退金额不能大于已收金额')
                  return false
                }

                // 显示审核弹窗
                this.showFlowSet()
              }
            })

          } else {
            return false
          }
        })
      },
      // 取消
      close2() {
        this.$refs.form.resetFields()
        this.form = {}
        this.form.fovos = []
        this.msgData = []
        this.$emit('saleRefundClose')
      },
      // 退款方式的选择
      financeMethodChange(val) {
        let arr = this.paymentOptions.find((item) => {
          return item.financeSetupId === val
        })
        this.form.financeMethod = arr?.financeName
        if (arr?.ticketNumberRequired == 1) {
          this.$refs.form.rules.ticketNumber[0].required = true
          this.required = 1
        } else {
          this.$refs.form.rules.ticketNumber[0].required = false
          this.required = 0
        }

        this.amountCollectedChange()
        // this.$emit('financeMethodChange', val, 'sale-refund')
      },
      //币种选择
      currencyChange(val) {
        this.currentChartType = val

        this.amountCollectedChange()
      },
      // 收款金额获取手续费
      amountCollectedChange() {
        if (!this.form.amountTime) return
        let params = {
          amountCollected: this.form.amountCollected,
          currency: this.form.currency,
          financeSetupId: this.form.financeMethodCode,
        }

        let bool = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/.test(
          params.amountCollected
        )
        if (Object.values(params).every((item) => !!item) && bool) {
          calculationServiceCharge({
            ...params,
            entryType: 1,
          }).then((res) => {
            this.commissionAmount = res.data.commissionAmount // 国际手续费
            this.settleFee = res.data.settleFee // 结算手续费

            this.calcCommissionAmount()
          })
        }
      },
      // 修改时间
      changeAmountTime() {
        this.amountCollectedChange()
      },

      // 计算手续费金额
      calcCommissionAmount() {
        let commissionAmount = this.commissionAmount
        let settleFee = this.settleFee
        let fovos = this.form.fovos
        if (fovos.length && fovos.every((item) => item.amountCollected != '')) {
          const total = this.utils.amountTotal(fovos, 'amountCollected', 3)
          for (let i = 0; i < fovos.length; i++) {
            // total作为分母时，不能为0
            const n = total != 0 ? fovos[i].amountCollected / total : 0
            // 赋值国际手续费
            this.$set(
              fovos[i],
              'commissionAmount',
              commissionAmount > 0 ? (n * commissionAmount).toFixed(3) : 0
            )
            let dt = math.format(n * settleFee)
            this.$set(
              fovos[i],
              'settleFee',
              settleFee > 0 ? Number(dt).toFixed(3) : 0
            )
          }
        }
      },

      getMsgByCode(val, index) {
        getOrderByOrderCode({ orderCode: val }).then((res) => {
          if (res.code === '000000') {
            this.msgData.splice(index, 1)
            res.data.amountCollected = 0
            this.msgData.splice(index, 0, res.data)
            if (res.data.currency !== this.currentChartType) {
              this.$message.warning('订单币种与收款单币种不一致')
            }
          }
        })
      },
      // 审核信息 确定
      saveAuditClick2(val) {
        this.form.auditObjectDTO = val
        this.form.overchargeType = 1
        delete this.form.claimedSalesId
        delete this.form.claimedSalesName
        let form = deepCopy(this.form)
        // 处理真实账户信息
        form.realAccountFlag = this.realAccount.realAccountFlag
        form.realAccount = this.realAccount
        this.$emit('refundSave', form)
      },
      //处理点击订单金额显示详情
      handelTotalPriceClick(e, index) {
        this.detailMsg = {}
        let refName = `orderMoneyDetail${index}`
        financeOrderPayVO({ orderCode: e.orderCode }).then((res) => {
          if (res.code === '000000') {
            this.detailMsg = res.data
          }
        })
      },
      // 退款金额变动 退款金额不能大于已收金额
      fefundChange(index, val) {
        // let num = this.msgData[index].amountReceived
        // if (num < val.amountCollected) {
        //   this.$message.warning('退款金额不能大于已收金额')
        //   val.amountCollected = 0
        //   return
        // }
        // this.amountCollectedChange()

        this.calcCommissionAmount()
      },
      // 显示流程设置
      showFlowSet() {
        let params = {
          auditType: 13,
          status: 0,
          tenantId: this.userInfo.tenantId,
        }
        this.pubApi.getAuditTemplates(params).then((response) => {
          if (!response.data.length) {
            this.$baseMessage(
              '请联系管理员配置审核模板',
              'warning',
              false,
              'erp-hey-message-warning'
            )
          } else {
            this.$refs['AuditChoose'].showAddEdit(params)
          }
        })
      },
      showDetail(val) {
        this.$refs['ReceiptDetail'].showReceiptDetail(
          val.financeCode,
          val.financeStatus
        )
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .top-table {
      td {
        .cell {
          height: 50px;
        }
      }
    }

    .middle-table {
      .el-tooltip {
        height: 50px;
        line-height: 50px;
      }
    }

    .el-input,
    .el-select {
      height: 36px !important;
    }

    .money-sum {
      color: #666;
      text-align: right;
      padding: 5px;

      span {
        margin: 0 5px;
      }
    }
  }
</style>
