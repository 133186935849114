<template>
  <el-dialog :title="lang === 'en' ? 'upload image' : '上传图片'" :visible.sync="visible" :before-close="handleClose"
    width="815px" append-to-body :close-on-click-modal="false">
    <erp-upload-images :default-file-list="defaultFileList" @change="changeUploadList" v-if="visible && isFile"
      :compress="true"></erp-upload-images>
    <div class="wrapper-status">
      <label class="label">{{ $t('orderList.RenderingsStatus') }}：</label>
      <el-radio-group v-model="rendingsStatus">
        <!-- 已确认 -->
        <el-radio label="1" :disabled="isEdit">
          {{ $t('orderList.HasBeenConfirmed') }}
        </el-radio>
        <!-- 没有文件 -->
        <el-radio label="2" :disabled="isEdit">
          {{ $t('orderList.NoFile') }}
        </el-radio>
        <!-- 客户确认中 -->
        <el-radio label="3" v-show="defaultPlan" :disabled="isEdit">
          {{ $t('orderList.CustomerConfirmation') }}
        </el-radio>
        <!-- 修改中 -->
        <el-radio label="4" v-show="defaultPlan" :disabled="isEdit">
          {{ $t('orderList.BeingRevised') }}
        </el-radio>
      </el-radio-group>
    </div>
    <el-row slot="footer" class="dialog-footer text-center">
      <!-- 确认 -->
      <el-button type="primary" @click="confirmChoose">
        {{ $t('inquiryDetails.Confirm') }}
      </el-button>
      <!-- 取消 -->
      <el-button @click="handleClose">
        {{ $t('inquiryDetails.Cancel') }}
      </el-button>
    </el-row>
  </el-dialog>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { encode } from 'kits'
  export default {
    name: 'chooseImages',
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      // 是否编辑
      isEdit: {
        type: Boolean,
        default: false,
      },
      // 默认回显图片
      defaultFileList: {
        type: Array,
        default: () => [],
      },
      // 效果图状态
      defaultRendingsStatus: {
        type: String,
        default: '3',
      },
      // 效果图当前行数据
      defaultRow: {
        type: Object,
        default: () => { },
      },
      // 效果图待安排已安排状态
      defaultPlan: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        isFile: true,
        imageList: [],
        rendingsStatus: '3',
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    watch: {
      visible(newVal, oldVal) {
        if (newVal) {
          this.imageList = JSON.parse(JSON.stringify(this.defaultFileList))
          this.rendingsStatus = JSON.parse(
            JSON.stringify(this.defaultRendingsStatus)
          )
        }
      },
      rendingsStatus(val) {
        if (val == 2) {
          this.isFile = false
        } else {
          this.isFile = true
        }
      },
    },
    methods: {
      // 关闭 dialog
      handleClose() {
        this.$emit('update:visible', false)
      },
      // 上传印刷效果图
      changeUploadList(list) {
        this.imageList = list
      },
      // 格式化数据
      formatData() {
        let __urls = [], rendingsName = []
        const __imageList = this.imageList

        for (let i = 0; i < __imageList.length; i++) {
          __urls.push(__imageList[i].url)

          let name = __imageList[i].name || ''
          if (__imageList[i].name && __imageList[i].encode === false) {
            name = encode(__imageList[i].name)
          }
          rendingsName.push({
            rendingsIndex: i,
            name
          })
        }
        return {
          urls: this.rendingsStatus != 2 ? __urls.toString() : '',
          status: this.rendingsStatus,
          ...this.defaultRow,
          rendingsName,
        }
      },
      // 确认
      confirmChoose() {
        if (this.imageList.length || this.rendingsStatus == 2) {
          this.handleClose()
          this.$emit('confirm', this.formatData())
        } else {
          this.$message.warning(this.$t('reqmsg.G005'))
          return false
        }
      },
    },
  }
</script>
<style scoped lang="scss">
  .wrapper-status {
    margin-top: 10px;
  }
</style>