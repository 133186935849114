<template>
  <div class="mt10">
    <el-form>
      <el-form-item label="" label-position="left" :disabled="disabled">
        <el-radio-group v-model="accountInfo.realAccountFlag">
          <el-radio :label="1">退款至客户</el-radio>
          <el-radio :label="0">收款退回调整</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <el-form label-position="top" :model="accountInfo" ref="accountForm" v-if="accountInfo.realAccountFlag == 1">

      <div class="account-info">
        <div class="mb10">退款账户信息</div>
        <el-row :gutter="12">
          <el-col :span="8">
            <el-form-item label="退款方式 Payment Method">
              <el-input type="textarea" disabled :autosize="{ minRows: 1,maxRows: 99}" placeholder="请输入内容"
                v-model="financeMethod">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :rules="[{
            required:['电汇'].includes(financeMethod),
            message:'请输入账户号码 Account Number'
          }]" prop="accountNumber">
              <template slot="label">
                <span>账户号码 Account Number</span>
              </template>
              <el-input type="textarea" :disabled="disabled" :autosize="{ minRows: 1,maxRows: 99}" placeholder="请输入内容"
                v-model="accountInfo.accountNumber" maxlength="100">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="companyName" :rules="[{
            required:['支票','电汇'].includes(financeMethod),
            message:'请输入公司名称 Company Name'
          }]">
              <template slot="label">

                <span>公司名称 Company Name</span>
              </template>
              <el-input type="textarea" :disabled="disabled" maxlength="100" :autosize="{ minRows: 1,maxRows: 99}"
                placeholder="请输入内容" v-model="accountInfo.companyName">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="12">
          <el-col :span="8">
            <el-form-item prop="bankName">
              <template slot="label">

                <span>银行名称 Bank Name</span>
              </template>
              <el-input type="textarea" :disabled="disabled" maxlength="50" :autosize="{ minRows: 1,maxRows: 99}"
                placeholder="请输入内容" v-model="accountInfo.bankName">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :rules="[{
            required:['电汇'].includes(financeMethod),
            message:'请输入银行代码 Routing Number / Swift Code'
          }]" prop="bankCode">
              <template slot="label">
                <span>银行代码 Routing Number / Swift Code</span>
              </template>
              <el-input type="textarea" :disabled="disabled" maxlength="20" :autosize="{ minRows: 1,maxRows: 99}"
                placeholder="请输入内容" v-model="accountInfo.bankCode">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :rules="[{
            required:['支票'].includes(financeMethod),
            message:'请输入公司地址 Company Address'
          }]" prop="companyAddress">
              <template slot="label">
                <span>公司地址 Company Address</span>
              </template>
              <el-input type="textarea" :disabled="disabled" maxlength="100" :autosize="{ minRows: 1,maxRows: 99}"
                placeholder="请输入内容" v-model="accountInfo.companyAddress">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="12">
          <el-col :span="8">
            <el-form-item>
              <template slot="label">

                <span>银行地址 Bank Address</span>
              </template>
              <el-input type="textarea" :disabled="disabled" maxlength="100" :autosize="{ minRows: 1,maxRows: 99}"
                placeholder="请输入内容" v-model="accountInfo.bankAddress">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <template slot="label">

                <span>电话 Telephone Number</span>
              </template>
              <el-input type="textarea" :disabled="disabled" maxlength="50" :autosize="{ minRows: 1,maxRows: 99}"
                placeholder="请输入内容" v-model="accountInfo.telephoneNumber">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <template slot="label">
                <span>邮箱 Email Address</span>
              </template>
              <el-input type="textarea" :disabled="disabled" maxlength="100" :autosize="{ minRows: 1,maxRows: 99}"
                placeholder="请输入内容" v-model="accountInfo.emailAddress">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>


      </div>
    </el-form>
  </div>
</template>
<script>

  export default {
    name: "RefundRealAccount",
    props: {

      financeMethod: {
        type: String,
        default: ''
      },

      accountInfo: {
        type: Object,
        default: () => ({})
      },

      disabled: {
        type: Boolean,
        default: false
      }


    },

    methods: {
      // 校验
      validateAccountForm(callback) {
        return new Promise((resolve, reject) => {
          if (!this.accountInfo.realAccountFlag) {  //不需要校验
            resolve(true)
          } else {
            this.$refs.accountForm.validate(validate => {
              if (validate) {
                resolve(true)
              } else {
                resolve(false)
              }
            })
          }
        })


      },

      clearValidate() {
        this.$refs.accountForm?.clearValidate()
      },

    }


  }
</script>

<style lang="scss">
  .account-info {
    padding: 10px;
    border: 1px solid #eee;
  }
</style>
