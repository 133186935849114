<template>
  <el-dialog class="update-transport-plan" title="修改出运计划" v-if="dialogVisible" :visible.sync="dialogVisible"
    @before-close="close" :close-on-click-modal="false" width="1360px">
    <div class="container no-space">
      <el-form :model="form" ref="form" :rules="rules">
        <table class="custom-table mb10">
          <thead>
            <tr>
              <th>
                <i class="required">*</i>
                国家
              </th>
              <th>
                <i class="required">*</i>
                收货地址
              </th>
              <th>
                <i class="required">*</i>
                收货公司
              </th>
              <th>
                <i class="required">*</i>
                收货人
              </th>
              <th>
                <i class="required">*</i>
                联系电话
              </th>
              <th>
                <i class="required">*</i>
                运输方式
              </th>
              <th>
                <i class="required">*</i>
                装运日期
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <el-form-item prop="country">
                  <el-input :disabled="isShipment" v-model.trim="form.country" placeholder="" maxlength="50"></el-input>
                </el-form-item>
              </td>
              <td>
                <el-form-item prop="destination">
                  <el-input :disabled="isShipment" v-model.trim="form.destination" placeholder=""
                    maxlength="250"></el-input>
                </el-form-item>
              </td>
              <td>
                <el-form-item prop="receivingCompany">
                  <el-input :disabled="isShipment" v-model.trim="form.receivingCompany" placeholder=""
                    maxlength="50"></el-input>
                </el-form-item>
              </td>
              <td>
                <el-form-item prop="receivingPerson">
                  <el-input :disabled="isShipment" v-model.trim="form.receivingPerson" placeholder=""
                    maxlength="50"></el-input>
                </el-form-item>
              </td>
              <td>
                <el-form-item prop="receivingPhone">
                  <el-input :disabled="isShipment" v-model.trim="form.receivingPhone" placeholder=""
                    maxlength="50"></el-input>
                </el-form-item>
              </td>
              <td>
                <el-form-item prop="shippingMethodId">
                  <el-select :disabled="isShipment" v-model="form.shippingMethodId" placeholder="请选择" value-key="id"
                    @change="changeShipMethod">
                    <el-option v-for="item in shippingMethodList" :key="item.id" :label="item.transportWayCn"
                      :value="item.id" />
                  </el-select>
                </el-form-item>
              </td>
              <td>
                <el-form-item prop="shippingDate">
                  <el-date-picker :disabled="isShipment" v-model="form.shippingDate" type="date" format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                </el-form-item>
              </td>
            </tr>
          </tbody>
        </table>
        <el-table border v-if="form.shippingPlanOrderProductEditDTOS.length"
          :data="form.shippingPlanOrderProductEditDTOS" :row-class-name="getRowClass">
          <el-table-column type="expand" width="45">
            <template slot-scope="scope" v-if="scope.row.mainFlag == 1 && scope.row.splitFlag == 1">
              <el-table :data="scope.row.childProduct" :ref="scope.row.id" align="center" :show-header="false" border
                :header-cell-style="{ 'text-align': 'center' }">
                <el-table-column align="center" width="45" />
                <el-table-column label="产品" prop="productCn" align="center" width="200">
                  <template slot-scope="scope">
                    {{ scope.row.sku }}
                    <span>
                      /
                      {{
                      scope.row.sku.includes('FL')
                      ? scope.row.productCn
                      : scope.row.productEn
                      }}
                    </span>
                    <span>
                      /
                      {{ scope.row.productSpecs ? scope.row.productSpecs : '' }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="产品类型" prop="standardCustomized" align="center">
                  <template slot-scope="scope">
                    {{
                    scope.row.standardCustomized == '辅料'
                    ? '辅料'
                    : scope.row.standardCustomized == 2
                    ? '订制品'
                    : '标准品'
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="是否需要印刷" align="center" width="120">
                  <template slot-scope="scope">
                    {{ scope.row.isPrint == 1 ? '是' : '否' }}
                  </template>
                </el-table-column>
                <el-table-column label="可用库存" align="center" width="160">
                  <template slot-scope="scope">
                    <div v-if="
                        scope.row.shippingPlanOrderProductStockVOS &&
                        scope.row.shippingPlanOrderProductStockVOS.length
                      ">
                      <div v-for="stock in scope.row
                          .shippingPlanOrderProductStockVOS" :key="stock.storageId">
                        {{ stock.storageName }}:{{ stock.stockNum }}
                      </div>
                    </div>
                    <span v-else>--</span>
                  </template>
                </el-table-column>
                <el-table-column label="订单数量" prop="totalNum" align="center"></el-table-column>
                <el-table-column label="待出运数量" prop="unShippedNum" align="center"></el-table-column>
                <el-table-column label="是否退税" label-class-name="required-th" width="110">
                  <template slot-scope="scope">
                    <el-form-item :prop="
                        'shippingPlanOrderProductEditDTOS' +
                        [scope.row.parentIndex] +
                        '.childProduct' +
                        [scope.$index] +
                        '.drawback'
                      ">
                      <el-select v-model="
                          form.shippingPlanOrderProductEditDTOS[
                            scope.row.parentIndex
                          ].childProduct[scope.$index].drawback
                        " clearable>
                        <el-option label="是" value="1"></el-option>
                        <el-option label="否" value="0"></el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="总箱数" width="120" label-class-name="required-th">
                  <template slot-scope="scope">
                    <el-form-item :prop="
                        'shippingPlanOrderProductEditDTOS.' +
                        [scope.row.parentIndex] +
                        '.childProduct.' +
                        [scope.$index] +
                        '.caseNum'
                      " :rules="[
                        {
                          required: true,
                          message: '总箱数必填',
                          trigger: 'blur',
                        },
                        {
                          pattern: /^[0-9]*[1-9][0-9]*$/,
                          message: '请输入>0的整数',
                          trigger: 'blur',
                        },
                      ]">
                      <el-input v-model="
                          form.shippingPlanOrderProductEditDTOS[
                            scope.row.parentIndex
                          ].childProduct[scope.$index].caseNum
                        " placeholder="" maxlength="10"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="总体积(M³)" width="120" label-class-name="required-th">
                  <template slot-scope="scope">
                    <el-form-item :prop="
                        'shippingPlanOrderProductEditDTOS.' +
                        [scope.row.parentIndex] +
                        '.childProduct.' +
                        [scope.$index] +
                        '.volume'
                      " :rules="[
                        {
                          required: true,
                          message: '总体积必填',
                          trigger: 'blur',
                        },
                        {
                          pattern: /^\d+(\.\d{1,3})?$/,
                          message: '>=0最多三位小数',
                          trigger: 'blur',
                        },
                      ]">
                      <el-input v-model="
                          form.shippingPlanOrderProductEditDTOS[
                            scope.row.parentIndex
                          ].childProduct[scope.$index].volume
                        " placeholder="" maxlength="10"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="总重量(KGS)" width="120" label-class-name="required-th">
                  <template slot-scope="scope">
                    <el-form-item :prop="
                        'shippingPlanOrderProductEditDTOS.' +
                        [scope.row.parentIndex] +
                        '.childProduct.' +
                        [scope.$index] +
                        '.grossWeight'
                      " :rules="[
                        {
                          required: true,
                          message: '总重量必填',
                          trigger: 'blur',
                        },
                        {
                          pattern: /^\d+(\.\d{1,3})?$/,
                          message: '>=0最多三位小数',
                          trigger: 'blur',
                        },
                      ]">
                      <el-input v-model="
                          form.shippingPlanOrderProductEditDTOS[
                            scope.row.parentIndex
                          ].childProduct[scope.$index].grossWeight
                        " placeholder="" maxlength="10"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>

          <el-table-column label="产品" prop="productCn" align="center" width="200">
            <template slot-scope="scope">
              {{
              `${scope.row.sku} / ${scope.row.productEn} / ${scope.row.productSpecs}`
              }}
            </template>
          </el-table-column>
          <el-table-column label="产品类型" prop="standardCustomized" align="center">
            <template slot-scope="scope">
              {{
              scope.row.standardCustomized == '辅料'
              ? '辅料'
              : scope.row.standardCustomized == 2
              ? '订制品'
              : '标准品'
              }}
            </template>
          </el-table-column>
          <el-table-column label="是否需要印刷" align="center" width="120">
            <template slot-scope="scope">
              {{ scope.row.isPrint == 1 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column label="可用库存" align="center" width="160">
            <template slot-scope="scope">
              <div v-if="
                  scope.row.shippingPlanOrderProductStockVOS &&
                  scope.row.shippingPlanOrderProductStockVOS.length
                ">
                <div v-for="stock in scope.row.shippingPlanOrderProductStockVOS" :key="stock.storageId">
                  {{ stock.storageName }}:{{ stock.stockNum }}
                </div>
              </div>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="订单数量" prop="totalNum" align="center"></el-table-column>
          <el-table-column label="待出运数量" prop="unShippedNum" align="center"></el-table-column>
          <el-table-column label="是否退税" label-class-name="required-th" width="110">
            <template slot-scope="scope">
              <el-form-item :prop="
                  'shippingPlanOrderProductEditDTOS.' +
                  [scope.$index] +
                  '.drawback'
                ">
                <el-select v-model="
                    form.shippingPlanOrderProductEditDTOS[scope.$index].drawback
                  ">
                  <el-option label="是" value="1"></el-option>
                  <el-option label="否" value="0"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="总箱数" width="120" label-class-name="required-th">
            <template slot-scope="scope">
              <el-form-item :prop="
                  'shippingPlanOrderProductEditDTOS.' +
                  [scope.$index] +
                  '.caseNum'
                " :rules="[
                  { required: true, message: '总箱数必填', trigger: 'blur' },
                  {
                    pattern: /^[0-9]*[1-9][0-9]*$/,
                    message: '请输入>0的整数',
                    trigger: 'blur',
                  },
                ]">
                <el-input v-model.trim="
                    form.shippingPlanOrderProductEditDTOS[scope.$index].caseNum
                  " placeholder="" maxlength="10"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="总体积(M³)" width="120" label-class-name="required-th">
            <template slot-scope="scope">
              <el-form-item :prop="
                  'shippingPlanOrderProductEditDTOS.' +
                  [scope.$index] +
                  '.volume'
                " :rules="[
                  {
                    required: true,
                    message: '总体积必填',
                    trigger: 'blur',
                  },
                  {
                    pattern: /^\d+(\.\d{1,3})?$/,
                    message: '>=0最多三位小数',
                    trigger: 'blur',
                  },
                ]">
                <el-input v-model.trim="
                    form.shippingPlanOrderProductEditDTOS[scope.$index].volume
                  " placeholder="" maxlength="10"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="总重量(KGS)" width="120" label-class-name="required-th">
            <template slot-scope="scope">
              <el-form-item :prop="
                  'shippingPlanOrderProductEditDTOS.' +
                  [scope.$index] +
                  '.grossWeight'
                " :rules="[
                  {
                    required: true,
                    message: '总重量必填',
                    trigger: 'blur',
                  },
                  {
                    pattern: /^\d+(\.\d{1,3})?$/,
                    message: '>=0最多三位小数',
                    trigger: 'blur',
                  },
                ]">
                <el-input v-model.trim="
                    form.shippingPlanOrderProductEditDTOS[scope.$index]
                      .grossWeight
                  " placeholder="" maxlength="10"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </div>
    <div class="text-center mt20">
      <el-button type="primary" @click="save()" :loading="isLoading">
        {{ isLoading ? '加载中' : '确认' }}
      </el-button>
      <el-button @click="close()">取消</el-button>
    </div>

    <!-- 添加成员 -->
    <!-- <UserChoose ref="UserChoose" @choose-user="chooseUser" /> -->
  </el-dialog>
</template>
<script>
  import { shippingPlanUpdate } from '@/api/order'
  import {
    transpotwayList,
    getShipmentPlanDetailV1,
    queryGenShipping,
  } from '@/api/shipment/shipment-plan'
  export default {
    data() {
      return {
        form: {
          country: '',
          destination: '',
          receivingCompany: '',
          receivingPerson: '',
          receivingPhone: '',
          shippingDate: '',
          shippingMethod: '',
          shippingMethodId: '',
          shippingPlanOrderId: '',
          shippingMethodType: '',
          shippingPlanOrderProductEditDTOS: [],
        },
        isLoading: false,
        dialogVisible: false,
        shippingMethodList: [],
        rules: {
          country: [{ required: true, message: '请输入', trigger: 'blur' }],
          destination: [{ required: true, message: '请输入', trigger: 'blur' }],
          receivingCompany: [
            { required: true, message: '请输入', trigger: 'blur' },
          ],
          receivingPerson: [
            { required: true, message: '请输入', trigger: 'blur' },
          ],
          receivingPhone: [
            {
              required: true,
              message: '请输入',
              trigger: 'blur',
            },
          ],
          shippingMethodId: [
            { required: true, message: '请输入', trigger: 'change' },
          ],
          shippingDate: [
            { required: true, message: '请输入', trigger: 'change' },
          ],
          drawback: [{ required: true, message: '请选择', trigger: 'change' }],
        },
        isShipment: false, // 是否生成委托单
      }
    },
    created() {
      this.getShipList()
    },
    methods: {
      show(data) {
        this.dialogVisible = true
        queryGenShipping({
          shippingPlanOrderId: data.shippingPlanOrderId,
        }).then((res) => {
          if (res.code == '000000') {
            this.isShipment = res.data ? true : false
          }
        })

        // let ids = data.productScrollVOS.map(
        //   (item) => item.shippingPlanOrderProductId
        // )
        getShipmentPlanDetailV1({
          shippingPlanOrderId: data.shippingPlanOrderId,
          shippingPlanOrderProductIds: '',
        }).then((res) => {
          if (res.code == '000000') {
            let result = res.data
            this.form = {
              shippingPlanOrderId: result.shippingPlanOrderId,
              country: result.country,
              destination: result.destination,
              receivingCompany: result.receivingCompany,
              receivingPerson: result.receivingPerson,
              receivingPhone: result.receivingPhone,
              shippingDate: result.shippingDate,
              shippingMethod: result.shippingMethod,
              shippingMethodId: result.shippingMethodId
                ? Number(result.shippingMethodId)
                : '',
              shippingMethodType: result.shippingMethodType,
              shippingPlanOrderProductEditDTOS: [...result.productDetails].map(
                (item, i) => {
                  return {
                    ...item,
                    childProduct:
                      item.childProduct && item.childProduct.length
                        ? this.getParentIndex(item.childProduct, i)
                        : [],
                  }
                }
              ),
            }
          }
        })
      },
      // 获取运输方式
      getShipList() {
        transpotwayList().then((res) => {

          if(res?.code === '000000') {
            this.shippingMethodList = res.data
          }

        })
      },

      // 子产品添加索引
      getParentIndex(arr, i) {
        return arr.map((item) => {
          return {
            ...item,
            parentIndex: i,
          }
        })
      },

      // 提交
      save() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            shippingPlanUpdate(this.form).then((res) => {
              if (res.code === '000000') {
                this.$message.success('修改成功')
                this.dialogVisible = false
                this.$emit('success')
              }
            })
          }
        })
      },

      close() {
        this.dialogVisible = false
      },

      //切换运输方式
      changeShipMethod() {
        this.shippingMethodList.forEach((item) => {
          if (this.form.shippingMethodId == item.id) {
            this.form.shippingMethodType = item.transportType
            this.form.shippingMethod = item.transportWayCn
          }
        })
      },

      // 使用getRowClass针对每一行添加类
      getRowClass(row) {
        let data = row.row
        let res = []
        if (data.childProduct && data.childProduct.length > 0) {
          res.push('row-expand-has')
          return res
        } else {
          res.push('row-expand-unhas')
          return res
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .custom-table .el-form-item {
    margin-bottom: 4px;
  }

  ::v-deep {
    .required-th {
      text-align: center;
    }

    .row-expand-unhas .el-table__expand-column {
      pointer-events: none;
    }

    .row-expand-unhas .el-table__expand-column .el-icon {
      visibility: hidden;
    }
  }
</style>
