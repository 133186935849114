import request from '@/core/services/request'

//  产品列表
export function getList(data) {
  return request({
    url: '/product/product/listPage',
    method: 'post',
    data,
  })
}

//  产品新增
export function insertPO(data) {
  return request({
    url: '/product/product/insertPO',
    method: 'post',
    data,
  })
}
//  产品更新
export function updatePO(data) {
  return request({
    url: '/product/product/updatePO',
    method: 'post',
    data,
  })
}
//  产品保存(录入中状态)
export function savePO(data) {
  return request({
    url: '/product/product/savePO',
    method: 'post',
    data,
  })
}
//  产品保存更新(录入中状态)
export function saveUpdatePO(data) {
  return request({
    url: '/product/product/saveUpdatePO',
    method: 'post',
    data,
  })
}
// 产品查看回显
export function getDetailForShow(data) {
  return request({
    url: '/product/product/getDetailForShow',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
//  产品编辑回显
export function getDetailForEdit(data) {
  return request({
    url: '/product/product/getDetailForEdit',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//  产品删除
export function deleteById(data) {
  return request({
    url: '/product/product/deleteById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 产品批量删除
export function logicDeleteByIds(data) {
  return request({
    url: '/product/product/logicDeleteByIds',
    method: 'post',
    data,
  })
}

// 产品批量删除
export function checkSkuDataInfoByDelete(data) {
  return request({
    url: '/product/product/checkSkuDataInfoByDelete',
    method: 'post',
    data,
  })
}

// 产品新增供应商
export function saveSupplier(data) {
  return request({
    url: '/product/product/saveSupplier',
    method: 'post',
    data,
  })
}

// 操作记录
export function operationRecord(data) {
  return request({
    url: '/product/operationRecord/listPage',
    method: 'post',
    data,
  })
}
// 根据产品类目ID获取关联供应商列表
export function listSupplierByProductCategoryId(data) {
  return request({
    url: '/merchants/supplier/listSupplierVOS',
    method: 'post',
    data,
  })
}

// 查询最低运费数据（新）
// export function getMinData(data) {
//   return request({
//     url: '/product/product/getMinFreight',
//     method: 'post',
//     data,
//   })
// }
//旧
export function getMinData(data) {
  return request({
    url: '/product/product/getMinFreight',
    method: 'post',
    data,
  })
}
// export function getMinData(data) {
//   return request({
//     url: '/product/product/getMinData',
//     method: 'post',
//     data,
//   })
// }
// 查询运输方式
export function listTransportMethod(data) {
  return request({
    url: '/product/product/listTransportMethod',
    method: 'post',
    data,
  })
}

// 产品详情-价格计算
export function calcProudctPrice(data) {
  return request({
    url: '/product/product/calcTotalProudctPrice',
    method: 'post',
    data,
  })
}
// export function calcProudctPrice(data) {
//   return request({
//     url: '/product/product/calcProudctPrice',
//     method: 'post',
//     data,
//   })
// }
// 产品详情-根据数量查询产品运输方式
export function getAreaSelect(data) {
  return request({
    url: '/product/product/getAreaSelect',
    method: 'post',
    data,
  })
}

// 产品详情-收货地址
export function listFreightArea(data) {
  return request({
    url: '/product/product/listFreightArea',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 产品管理-转让归属权
export function transferBelong(data) {
  return request({
    url: '/product/product/transferBelong',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 产品管理-批量转让归属权
export function transferBelongBatch(data) {
  return request({
    url: '/product/product/transferBelongBatch',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 产品列表-根据产品ID获取sku信息及库存
export function productGetSkuByProductId(data) {
  return request({
    url: '/product/product/getSkuByProductId',
    method: 'post',
    data,
  })
}

// 产品列表-根据产品ID获取sku信息及库存
export function selectProductList(data) {
  return request({
    url: '/product/product/selectProductList',
    method: 'post',
    data,
  })
}
// 产品列表-根据skuId或者类别查询详情
export function producGetProductInfoBySku(data) {
  return request({
    url: '/product/product/getProductInfoBySku',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 产品列表-根据产品名称或编码查询详情
export function getDetailByCodeOrName2(data) {
  return request({
    url: '/product/product/getDetailByCodeOrName2',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 产品列表-根据产品名称或编码查询详情
export function getEnquiryProductDetail(data) {
  return request({
    url: '/product/product/getEnquiryProductDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 快速报价-产品模糊查询
export function listProductByParam(data) {
  return request({
    url: '/product/product/listProductByParam',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 快速报价-产品模糊查询新
export function listEnquiryProductByParam(data) {
  return request({
    url: '/product/product/listEnquiryProductByParam',
    method: 'post',
    data,
  })
}

// 产品设置-查询产线中的成员及负责人（用于产品新增编辑）
export function listUserForProductionLine(data) {
  return request({
    url: '/product/productSetting/listUserForProductionLine',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 查询多个产线成员及其负责人
export function listSameUserForProductionLine(data) {
  return request({
    url: '/product/productSetting/listSameUserForProductionLine',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

export function productClonePO(data) {
  return request({
    url: '/product/product/clonePO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
//查询sku列表
export function skuList(data) {
  return request({
    url: '/product/product/getSkuList',
    method: 'post',
    data,
  })
}
//根据运输方式查地区
export function listDeliveryArea(data) {
  return request({
    url: '/product/product/listDeliveryArea',
    method: 'post',
    data,
  })
}

//根据产品编码查询产品id
export function productIdByCode(data) {
  return request({
    url: '/product/product/getProductIdByCode',
    method: 'post',
    data,
  })
}

// 产品设置-根据产品规格ID获取排序后的规格值
export function listSettingValueSorted(data) {
  return request({
    url: '/product/productSetting/listSettingValueSorted',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 辅料管理列表及搜索接口
export function subProductList(data) {
  return request({
    url: '/product/accessories/list',
    method: 'post',
    data,
  })
}
// 新增辅料接口
export function addSubProduct(data) {
  return request({
    url: '/product/accessories/insert',
    method: 'post',
    data,
  })
}
// 编辑辅料接口
export function updateSubProduct(data) {
  return request({
    url: '/product/accessories/modify',
    method: 'post',
    data,
  })
}
// 辅料详情接口
export function detailSubProduct(data) {
  return request({
    url: `/product/accessories/detail/${data}`,
    method: 'post',
    // data,
  })
}
// 根据父节点SKU获取辅料
export function listByParentSku(data) {
  return request({
    url: `/order/bomProduct/listByParentSku`,
    method: 'post',
    data,
  })
}

// 更换供应商
export function changeSupplier(data) {
  return request({
    url: `/order/purchaseOrder/changeSupplier`,
    method: 'post',
    data,
  })
}

// 获取当前租户运输周期、运费
export function getTransportDayAndPriceListByTenantId() {
  return request({
    url: `/storage/taskTransportDayPrice/getTransportDayAndPriceListByTenantId`,
    method: 'post',
  })
}
// 更改商城产品状态
export function changeMallStatus(data) {
  return request({
    url: `/product/product/changeMallStatus`,
    method: 'post',
    data,
  })
}
