<template>
  <el-dialog width="600" :visible.sync="linkVisible">
    <el-form class="mt20" :model="form" ref="ruleForm" label-width="60px">
      <el-form-item :label="$t('orderList.link')" prop="paymentLink">
        <el-input
          :disabled="true"
          v-model="form.paymentLink"
          maxlength="50"
        ></el-input>
      </el-form-item>
    </el-form>

    <div class="text-center mt20">
      <el-button
        v-clipboard:copy="form.paymentLink"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
      >
        <!-- 复制 -->{{ $t('orderList.copy') }}
      </el-button>
      <el-button type="primary" @click="sendEmail"><!-- 发送邮件 -->{{ $t('orderList.sendEmail') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      form: {
        paymentLink: '',
      },
      mail: '',
      linkVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      lang: 'settings/language',
    }),
  },
  methods: {
    // 初始化
    init(link, mail) {
      this.mail = mail
      this.form.paymentLink = link
      this.linkVisible = true
    },

    // 复制成功回调
    onCopy: function (e) {
      this.$message.success(this.lang === 'en' ? 'Success': '复制成功！')
      this.linkVisible = false
    },

    // 复制失败回调
    onError: function (e) {
      this.$message.error(this.lang === 'en' ? 'Failed': '复制失败！')
      this.linkVisible = false
    },

    // 发送邮件
    sendEmail() {
      let url = this.$router.resolve({
        // path: '/mail/my-mail/write',
        path: '/write',
        query: {
          from: 'business',
          email: this.mail,
          content: this.form.paymentLink,
        },
      })
      this.utils.openWindow(url.href)
    },

    // 关闭弹窗重置表单
    close() {
      this.$refs.ruleForm.resetFields()
      this.payVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog {
  .el-form-item {
    margin-bottom: 18px;
  }
}
</style>
