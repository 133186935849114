<template>
  <el-dialog
    title="异常处理"
    width="650px"
    class="dialog"
    :visible.sync="refundVisible"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <div class="money-wrapper">
      <!-- <div> -->
      <p>
        <span>应收金额</span>
        <span class="money">
          {{ currency === '美元' ? '$' : '¥' }}&nbsp;&nbsp;{{
            utils.moneyFormat(receivablePrice)
          }}
        </span>
      </p>
      <p>
        <span>实收金额</span>
        <span class="money">
          {{ currency === '美元' ? '$' : '¥' }}&nbsp;&nbsp;{{
            utils.moneyFormat(netReceipts)
          }}
        </span>
      </p>
      <!-- </div> -->
    </div>
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="处理金额" prop="amount" label-width="90px">
            <el-input
              v-model.trim="form.amount"
              maxlength="20"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item label="处理类型" prop="serviceType" label-width="90px">
            <el-radio-group v-model="form.serviceType">
              <el-radio :label="1">仅退款</el-radio>
              <el-radio :label="4">存入客户账户</el-radio>
              <el-radio :label="5">当前订单收入</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item
            label="退款账户"
            prop="refundAccount"
            label-width="90px"
            v-if="form.serviceType === 1"
          >
            <el-input v-model.trim="form.refundAccount" clearable></el-input>
          </el-form-item>

          <el-form-item
            label="原因说明"
            prop="refundReason"
            label-width="90px"
            v-if="form.serviceType === 1"
          >
            <el-input
              type="textarea"
              v-model.trim="form.refundReason"
              maxlength="500"
              show-word-limit
              :rows="3"
            ></el-input>
          </el-form-item>
          <el-form-item label="" v-if="form.serviceType === 1">
            <ErpUpload
              showLabel
              style="margin-left: 90px"
              :uploadParams="uploadParams"
              @uploadList="uploadList"
            ></ErpUpload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="text-center mt30">
      <el-button type="primary" @click="save">确认</el-button>
      <el-button @click="close()">取消</el-button>
    </div>

    <!-- 审核模板-->
    <AuditChoose ref="AuditChoose" @auditData="saveClick" />
  </el-dialog>
</template>

<script>
import { refundOrderInsertPO } from '@/api/order'
import AuditChoose from '@/components/AuditChoose'
import { mapGetters } from 'vuex'
let self
export default {
  components: {
    AuditChoose,
  },
  data() {
    self = this
    return {
      netReceipts: '',
      receivablePrice: '',
      orderId: '',
      orderCode: '',
      currency: '', // 币种
      form: {
        amount: '', // 退款金额
        refundAccount: '', // 退款账户
        refundReason: '', // 退款原因
        enclosureUrl: '', // 附件
        exchangeRate: null, // 汇率
        auditObjectDTO: [], // 审核人员
        serviceType: 1,
      },
      // simpleOrders: [],
      // storageList: [], // 出库仓库列表
      refundVisible: false,
      rules: {
        amount: [
          { required: true, message: '请输入处理金额', trigger: 'blur' },
          {
            pattern: /(^[1-9](\d+)?(\.\d{1,3})?$)|(^\d\.\d{1,3}$)/,
            message: '>0且最多保留三位小数',
            trigger: 'blur',
          },
          // {
          //   message: '处理金额 ≤（实收金额 - 应收金额）',
          //   trigger: 'blur',
          //   validator: (rule, value, callback) => {
          //     if (
          //       value * 1000 >
          //       self.netReceipts * 1000 - self.receivablePrice * 1000
          //     ) {
          //       callback(new Error('处理金额 ≤（实收金额 - 应收金额）'))
          //     } else {
          //       callback()
          //     }
          //   },
          // },
        ],
        refundReason: [
          { required: true, message: '请输入退款原因', trigger: 'blur' },
        ],
        serviceType: [
          { required: true, message: '请选择处理类型', trigger: 'blur' },
        ],
        refundAccount: [
          { required: true, message: '请输入退款账户', trigger: 'blur' },
          {
            max: 100,
            message: '字符长度不能超过100',
            trigger: 'blur',
          },
        ],
      },
      uploadParams: {
        size: 1024 * 1024 * 20, //单个文件上传大小
      },
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },
  created() {
    // 获取出库仓库下拉框
    // storageFindWarehouseList2({
    //   hasAuth: 0,
    //   hasCloudWarehouse: 1,
    // }).then((res) => {
    //   this.storageList = res.data
    // })
  },
  methods: {
    // 获取详情
    getDetail({ receivablePrice, netReceipts, currency, orderId, orderCode }) {
      this.orderCode = orderCode
      this.receivablePrice = receivablePrice
      this.netReceipts = netReceipts
      this.currency = currency
      this.refundVisible = true
      this.form.orderId = orderId
      this.form.orderCode = orderCode
    },

    // 获取上传文件地址
    uploadList(fileList) {
      this.form.enclosureUrl = JSON.stringify(fileList)
    },

    // 保存
    save() {
      let self = this
      if (this.form.serviceType !== 1) {
        self.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.saveClick()
          }
        })
        return
      }

      self.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 配置审核模板
          let auditParams = {
            auditType: 7,
            status: 0,
            tenantId: self.userInfo.tenantId,
          }
          self.pubApi.getAuditTemplates(auditParams).then((response) => {
            if (!response.data.length) {
              self.$baseMessage(
                '请联系管理员配置审核模板',
                'warning',
                false,
                'erp-hey-message-warning'
              )
            } else {
              self.$refs['AuditChoose'].showAddEdit(auditParams)
            }
          })
        } else {
          return false
        }
      })
    },

    // 保存审核模板信息
    saveClick(data) {
      let self = this
      if (data) {
        self.form.auditObjectDTO = data
      } else {
        self.form.auditObjectDTO = {}
      }

      self.$refs['AuditChoose'].close()
      const params = {
        type: 1,
        orderId: self.orderId,
        orderCode: self.orderCode,
        currency: self.currency,
        ...self.form,
      }
      refundOrderInsertPO(params).then((res) => {
        if (res && res.code == '000000') {
          self.$message.success('保存成功')
          // 异常处理，保存成功直接调详情接口交易状态不更新
          setTimeout(() => {
            self.$parent.getDetail(self.orderCode, 2)
          }, 1000)
          self.close()
        }
      })
    },

    // 退回数量限制
    refundAmountChange(i) {
      let list = this.form.refundOrderProductDTOS
      if (list[i].refundProductAmount > list[i].amount) {
        list[i].refundProductAmount = list[i].amount
        this.$message.warning('退回数量不能大于订单数量')
      }
    },

    // 选择退回仓库
    warehousechange(id, i) {
      let self = this
      self.storageList.forEach((item) => {
        if (item.warehouseId == id) {
          self.form.refundOrderProductDTOS[i].warehouseName = item.name
        }
      })
    },

    // 关闭弹窗重置表单
    close() {
      this.$refs.ruleForm.resetFields()
      this.refundVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog {
  .el-form-item {
    margin-bottom: 18px;
  }
  .money-wrapper {
    display: flex;
    padding: 15px 14px;
    padding-top: 0px;
    p {
      display: flex;
      flex-direction: column;
      flex: 1;
      span.money {
        margin-top: 10px;
        font-weight: bold;
      }
    }
  }
}
</style>
