import { openPage } from 'kits'
import {
  roleDetailForShow,
  shippingLookOrderAuthority,
} from '@/api/stock-center/inbound-sheet'
import { checkGetDetailAuth } from '@/api/stock-center/stock'
import { OutProcessing } from '@/core'
export const PAGE_TYPES = {
  salesOrderDetail: 'salesOrderDetail', //销售订单详情
  editSalesOrder: 'editSalesOrder', //编辑销售订单
  purchaseOrderDetail: 'purchaseOrderDetail', //采购订单详情
  shipmentPlanDetail: 'shipmentPlanDetail', //出运计划单详情
  outboundSheetDetail: 'outboundSheetDetail', //出库单详情
  previewFile: 'previewFile', // 文件预览
  orderContract: 'orderContract', // 订单合同
  orderShipMark: 'orderShipMark', //销售订单mark
  trackDetail: 'trackDetail', //跟单详情
  trackUpdate: 'trackUpdate', //跟踪单编辑
  productDetail: 'productDetail', // 产品详情
  allotInquireDetail: 'allotInquireDetail', //调拨单详情
  stockCheckDetail: 'stockCheckDetail', //盘点详情
  outprocessingDetail: 'outprocessingDetail',
  powerGroupIndex: 'powerGroupIndex', // 权限分组
}
const baseOptions = {
  isNewTab: true, // 是否在新窗口打开
}

export function mergeOptions(options) {
  return {
    ...baseOptions,
    ...options,
  }
}
export default function navigateTo(ctx, options) {
  const navigateStrat = strats[options.pageType]
  if (typeof navigateStrat === 'function') {
    navigateStrat(ctx, mergeOptions(options))
  } else {
    throw new Error(
      `navigateStrat is not a function ${JSON.stringify(options)}`
    )
  }
}

const strats = {},
  warningMsg = '暂无查看权限'

strats.salesOrderDetail = function (ctx, options) {
  // roleJudge为true时，需要判断权限后才能跳转
  const { orderNumber, isNewTab, roleJudge } = options
  if (roleJudge === false) {
    openPage(
      ctx,
      {
        path: '/order/orderList/orderDetail',
        query: {
          orderCode: orderNumber,
          noReturn: true,
        },
      },
      isNewTab
    )
  } else {
    shippingLookOrderAuthority(orderNumber).then((res) => {
      if (res && res.code === '000000') {
        if (res && res.data === 1) {
          openPage(
            ctx,
            {
              path: '/order/orderList/orderDetail',
              query: {
                orderCode: orderNumber,
                noReturn: true,
              },
            },
            isNewTab
          )
        } else {
          ctx.$message.warning(warningMsg)
        }
      }
    })
  }
}

strats.purchaseOrderDetail = function (ctx, options) {
  const { orderNumber, isNewTab, roleJudge } = options
  if (!roleJudge) {
    return openPage(
      ctx,
      {
        path: '/purchase/purchase/purchaseOrder/purchaseOrderDetail',
        query: {
          id: orderNumber, // 采购订单详情id和number都可查询，参数统一为id
          noReturn: true,
        },
      },
      isNewTab
    )
  }
  roleDetailForShow(orderNumber).then((res) => {
    if (res && res.data === 1) {
      openPage(
        ctx,
        {
          path: '/purchase/purchase/purchaseOrder/purchaseOrderDetail',
          query: {
            id: orderNumber, // 采购订单详情id和number都可查询，参数统一为id
            noReturn: true,
          },
        },
        isNewTab
      )
    } else {
      ctx.$message.warning(warningMsg)
    }
  })
}

strats.shipmentPlanDetail = function (ctx, options) {
  const { orderNumber, isNewTab } = options
  openPage(
    ctx,
    {
      path: '/order/shipmentManage/shipment-plan-detail',
      query: {
        shippingPlanOrderId: orderNumber, // 采购订单详情id和number都可查询，参数统一为id
        noReturn: true,
      },
    },
    isNewTab
  )
}

strats.outboundSheetDetail = function (ctx, options) {
  const { orderNumber, isNewTab } = options
  checkGetDetailAuth(orderNumber).then((res) => {
    if (res && res.data === 1) {
      openPage(
        ctx,
        {
          path: '/stock-center/stock-center/outbound-sheet/outbound-sheet-detail',
          query: {
            warehouseOutId: orderNumber, // 采购订单详情id和number都可查询，参数统一为id
            noReturn: true,
          },
        },
        isNewTab
      )
    } else {
      ctx.$message.warning(warningMsg)
    }
  })
}
strats.previewFile = function (ctx, options) {
  const { name, url, isNewTab } = options

  openPage(
    ctx,
    {
      path: '/preview-file/index',
      query: {
        name, // 采购订单详情id和number都可查询，参数统一为id
        url,
      },
    },
    isNewTab
  )
}

strats.orderContract = function (ctx, options) {
  const { orderId, isNewTab, isInvoice } = options
  openPage(
    ctx,
    {
      path: '/print/orderContract',
      query: {
        orderId, // 采购订单详情id和number都可查询，参数统一为id\
      },
    },
    isNewTab
  )
}

strats.orderShipMark = function (ctx, options) {
  const { orderId, isNewTab, QRCode } = options
  openPage(
    ctx,
    {
      path: '/print/orderShipMark',
      query: {
        orderId,
        QRCode,
        settingtype: 0,
      },
    },
    isNewTab
  )
}

// 编辑销售订单
strats.editSalesOrder = function (ctx, options) {
  const { orderId, orderCode, isNewTab } = options

  openPage(
    ctx,
    {
      path: '/order/orderList/orderAdd',
      query: {
        orderId, // 采购订单详情id和number都可查询，参数统一为id
        orderCode,
      },
    },
    isNewTab
  )
}

// 跟踪单详情
strats.trackDetail = function (ctx, options) {
  const { orderTrackingId, isNewTab } = options
  openPage(
    ctx,
    {
      path: '/track/orderTracking/trackDetail',
      query: {
        // 查询参数，确定了做调整
        orderTrackingId,
      },
    },
    isNewTab
  )
}

// 跟踪单编辑
strats.trackUpdate = function (ctx, options) {
  const { orderTrackingId, isNewTab } = options
  openPage(
    ctx,
    {
      path: '/track/orderTracking/trackUpdate',
      query: {
        // 查询参数，确定了做调整
        orderTrackingId,
      },
    },
    isNewTab
  )
}
// 产品详情
strats.productDetail = function (ctx, options) {
  const { proId, productCode, isNewTab } = options
  openPage(
    ctx,
    {
      path: '/product/ProductView',
      query: {
        // 查询参数，确定了做调整
        proId,
        productCode,
        noReturn: true,
      },
    },
    isNewTab
  )
}

// 盘点详情
strats.allotInquireDetail = function (ctx, options) {
  const { isNewTab, orderNumber } = options
  openPage(
    ctx,
    {
      path: '/stock-center/allot-inquire/detail',
      query: {
        id: orderNumber,
        noReturn: true,
      },
    },
    isNewTab
  )
}

/**
 * 盘点详情
 */
strats.stockCheckDetail = function (ctx, options) {
  const { orderNumber, isNewTab, showType } = options
  openPage(
    ctx,
    {
      path: '/stock-center/stock-check/detail',
      query: {
        stockTakingId: orderNumber,
        showType,
        noReturn: true,
      },
    },
    isNewTab
  )
}

/**
 * 委外加工单详情
 */
strats.outprocessingDetail = async function (ctx, options) {
  const { orderNumber, isNewTab, showType } = options
  const id = await OutProcessing.getProcessOrderCodeInfo(orderNumber)
  openPage(
    ctx,
    {
      path: '/purchase/outsourceProcess/outsource-detail',
      query: {
        code: orderNumber,
        id,
        showType,
        noReturn: true,
      },
    },
    isNewTab
  )
}

/**
 * 从组织架构跳转至权限分组
 */
strats.powerGroupIndex = function (ctx, options) {
  const { roleId, isNewTab } = options
  openPage(
    ctx,
    {
      path: '/system-manage/power-group/index',
      query: { roleId },
    },
    isNewTab
  )
}

/**
 * 根据委外加工单code获取委外加工单业务id
 */

function getIdByCode(orderNumber) {}
