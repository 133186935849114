<template>
  <el-drawer
    :size="800"
    title="物流进度追踪"
    :visible.sync="drawer"
    :before-close="handleClose"
    :wrapper-closable="false"
  >
    <div
      class="drawer-wrap"
      :style="isAbnormal ? 'height: calc(100vh - 120px)' : ''"
    >
      <template
        v-if="shipOrderLogistics.length || shipNoLogistics.length || isAbnormal"
      >
        <!-- 运单号物流信息 -->
        <template v-if="shipNoLogistics.length">
          <el-timeline v-for="(item, i) in shipNoLogistics" :key="i">
            <div class="top">
              <el-row :gutter="20">
                <el-col :span="8">
                  Courier Company:
                  <span class="bold">
                    {{ getTailNodesInfo('company', item) }}
                  </span>
                </el-col>
                <el-col :span="14">
                  Tracking Number:
                  <span class="bold">{{ item.data.number }}</span>
                  <el-button
                    v-show="item.recordType === 2"
                    type="primary"
                    plain
                    class="ml10"
                    size="mini"
                  >
                    补录
                  </el-button>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="8">
                  Transport status:
                  <span
                    :class="{
                      'red-sty':
                        getTailNodesInfo('status', item) === 'Exception',
                    }"
                  >
                    {{ getTailNodesInfo('status', item) }}
                  </span>
                </el-col>
                <el-col :span="16">
                  Number of shipping cases:
                  {{ getTailNodesInfo('cases', item) }}
                </el-col>
              </el-row>
            </div>

            <el-timeline-item
              v-for="(node, n) in getTailNodesInfo('node', item)"
              :key="n"
            >
              <div class="time-line">
                <span class="l">{{ node.time_iso | timeFormat }}</span>
                <span class="r">
                  {{ node.location ? node.location + ',' : '' }}&nbsp;{{
                    node.description ? node.description + ',' : ''
                  }}&nbsp;{{ node.stage ? node.stage + ',' : '' }}
                </span>
              </div>
            </el-timeline-item>
          </el-timeline>
        </template>

        <!-- 委托单信息 -->
        <template v-if="shipOrderLogistics.length">
          <el-tabs
            v-model="active"
            type="card"
            @tab-click="handleClick(shipOrderLogistics, Number(active))"
          >
            <el-tab-pane
              v-for="(item, i) in shipOrderLogistics"
              :label="item.transportNumber"
              :key="i"
              :name="String(i)"
            >
              <!-- 委托单尾程 -->
              <el-timeline
                v-if="item.tailTrackNodesDtos.length"
                style="margin-bottom: -10px"
              >
                <div class="top">
                  <el-row :gutter="20">
                    <el-col :span="8">
                      Courier Company:
                      <span class="bold">
                        {{ getTailNodesInfo('company') }}
                      </span>
                    </el-col>
                    <el-col :span="16">
                      Tracking Number:
                      <span
                        v-for="(num, n) in numbers"
                        class="blue-sty"
                        :class="{ bold: n === cIndex }"
                        :key="n"
                        @click="changeTrack(i, n)"
                      >
                        {{ num }}
                      </span>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="8">
                      Transport status:
                      <span
                        :class="{
                          'red-sty': getTailNodesInfo('status') === 'Exception',
                        }"
                      >
                        {{ getTailNodesInfo('status') }}
                      </span>
                    </el-col>
                    <el-col :span="16">
                      Number of shipping cases: {{ getTailNodesInfo('cases') }}
                    </el-col>
                  </el-row>
                </div>
                <el-timeline-item
                  v-for="(node, n) in getTailNodesInfo('node')"
                  :key="n"
                >
                  <div class="time-line">
                    <span class="l">{{ node.time_iso | timeFormat }}</span>
                    <span class="r">
                      {{ node.location ? node.location + ',' : '' }}&nbsp;{{
                        node.description ? node.description + ',' : ''
                      }}&nbsp;{{ node.stage ? node.stage + ',' : '' }}
                    </span>
                  </div>
                </el-timeline-item>
              </el-timeline>

              <!-- 委托单头程 -->
              <el-timeline>
                <div class="top">
                  <el-row :gutter="20">
                    <el-col :span="8">
                      Courier Company:
                      <span class="bold">奇凡物流</span>
                    </el-col>
                    <el-col :span="16">
                      Number of shipping cases: {{ item.cartons }}
                    </el-col>
                    <!-- <el-col :span="16">
                      Tracking Number:
                      <span class="bold">
                        {{ item.businessNumber }}
                      </span>
                    </el-col> -->
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="8">
                      Transport status:
                      <span
                        :class="{
                          'red-sty': item.headStatus === 'Exception',
                        }"
                      >
                        {{ item.headStatus }}
                      </span>
                    </el-col>
                    <el-col :span="16">
                      Estimated date of delivery:
                      {{ getExpectArriveDate(item.expectArriveDate) }}
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-show="item.headExceptionText">
                    <el-col :span="24" style="text-indent: 10px">
                      Anomalous cause:
                      <span class="red-sty">
                        {{ item.headExceptionText }}
                      </span>
                    </el-col>
                  </el-row>
                </div>
                <div v-if="item.trackNodeDtos.length">
                  <el-timeline-item
                    v-for="(node, n) in item.trackNodeDtos"
                    :key="n"
                  >
                    <div class="time-line">
                      <span class="l">{{ node.nodeTime }}</span>
                      <span class="r">
                        {{
                          '[' + (node.nodeName ? node.nodeName : '') + ']'
                        }}&nbsp;{{ node.nodeText }}
                      </span>
                    </div>
                  </el-timeline-item>
                </div>
              </el-timeline>
            </el-tab-pane>
          </el-tabs>
        </template>

        <!-- 异常待办物流信息 -->
        <template v-if="isAbnormal">
          <section v-if="abnormalShipOrderNodes.length">
            <el-tabs
              v-model="active"
              type="card"
              @tab-click="handleClick(abnormalShipOrderNodes, Number(active))"
            >
              <el-tab-pane
                v-for="(item, i) in abnormalShipOrderNodes"
                :label="item.transportNumber"
                :key="i"
                :name="String(i)"
              >
                <el-timeline>
                  <div class="top">
                    <el-row :gutter="20">
                      <el-col :span="12">
                        Courier Company:
                        <span class="bold">
                          奇凡物流
                          <!-- {{ item.carrier }} -->
                        </span>
                      </el-col>
                      <el-col :span="12">
                        Number of shipping cases:
                        {{ item.cartons }}
                      </el-col>
                      <!-- <el-col :span="12">
                        Tracking Number:
                        <span class="bold">
                          {{ item.businessNumber }}
                        </span>
                      </el-col> -->
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="12">
                        Transport status:
                        <span
                          :class="{
                            'red-sty': item.headStatus === 'Exception',
                          }"
                        >
                          {{ item.headStatus }}
                        </span>
                      </el-col>
                      <el-col :span="12">
                        Estimated date of delivery:
                        {{ getExpectArriveDate(item.expectArriveDate) }}
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" v-show="item.headExceptionText">
                      <el-col :span="24">
                        Anomalous cause:
                        <span class="red-sty">
                          {{ item.headExceptionText }}
                        </span>
                      </el-col>
                    </el-row>
                  </div>

                  <div v-if="item.trackNodeDtos.length">
                    <el-timeline-item
                      v-for="(node, n) in item.trackNodeDtos"
                      :key="n"
                    >
                      <div class="time-line">
                        <span class="l">{{ node.nodeTime }}</span>
                        <span class="r">
                          {{
                            '[' + (node.nodeName ? node.nodeName : '') + ']'
                          }}&nbsp;{{ node.nodeText }}
                        </span>
                      </div>
                    </el-timeline-item>
                  </div>
                </el-timeline>
              </el-tab-pane>
            </el-tabs>
          </section>
          <section v-else>
            <el-timeline v-if="abnormalShipNoNodes.length">
              <div class="top">
                <el-row :gutter="20">
                  <el-col :span="12">
                    Courier Company:
                    <span class="bold">{{ getTailNodesInfo('company') }}</span>
                  </el-col>
                  <el-col :span="12">
                    Tracking Number:
                    <span class="bold">
                      {{ abnormalShipNoNodes[0].data.number }}
                    </span>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    Number of shipping cases: {{ getTailNodesInfo('cases') }}
                  </el-col>
                  <el-col :span="12">
                    Transport status:
                    <span
                      :class="{
                        'red-sty': getTailNodesInfo('status') === 'Exception',
                      }"
                    >
                      {{ getTailNodesInfo('status') }}
                    </span>
                  </el-col>
                </el-row>
              </div>
              <el-timeline-item
                v-for="(node, n) in getTailNodesInfo('node')"
                :key="n"
              >
                <div class="time-line">
                  <span class="l">{{ node.time_iso | timeFormat }}</span>
                  <span class="r">
                    {{ node.location ? node.location + ',' : '' }}&nbsp;{{
                      node.description ? node.description + ',' : ''
                    }}&nbsp;{{ node.stage ? node.stage + ',' : '' }}
                  </span>
                </div>
              </el-timeline-item>
            </el-timeline>
          </section>
        </template>
      </template>
      <template v-else>
        <el-empty description="暂无数据，请稍后再试！"></el-empty>
      </template>
    </div>

    <div class="btn-close" v-show="isAbnormal">
      <el-button type="primary" @click="abnormalClose">关闭</el-button>
    </div>
  </el-drawer>
</template>

<script>
  import { ShipmentMangeInteractor } from '@/core/interactors/shipment/shipmentManage'
  export default {
    data() {
      return {
        active: '0',
        shipNoLogisticsId: '',
        shipNoLogistics: [], // 运单号物流信息
        shipOrderLogistics: [], // 委托单物流信息
        abnormalShipOrderNodes: [], // 异常待办运单号节点
        abnormalShipNoNodes: [], // 异常待办委托单节点
        tailTrackNodesDtos: [], // 物流信息节点
        numbers: [], // 委托单尾程单号集合
        cIndex: 0, // 委托单尾程单号索引
        drawer: false,
        isAbnormal: false,
      }
    },
    filters: {
      timeFormat(val) {
        const _arr = val.split('T')
        return (
          _arr[0] +
          ' ' +
          (_arr[1].includes('-')
            ? _arr[1].split('-')[0]
            : _arr[1].split('+')[0])
        )
      },
    },
    methods: {
      /**
       * 初始化
       * @param {params} 入参
       * @param {type} 入口来源 1:委托单 2:运单号 3:异常待办
       * @param {number} 运单号 用于首页物流状态通知定位
       */
      async init(params, type, number) {
        if (type === 1) {
          const res = await ShipmentMangeInteractor.queryLogisticByShipOrder(
            params
          )

          if (res.code === '000000') {
            const data = res.data

            this.shipNoLogistics = data?.shipNoLogistics || []
            this.shipOrderLogistics = data?.shipOrderLogistics || []

            if (data?.shipOrderLogistics?.length) {
              this.handleClick(data.shipOrderLogistics, 0)
            }
          }
        } else if (type === 2) {
          const res = await ShipmentMangeInteractor.queryLogisticByShipNo(
            params
          )

          if (res.code === '000000') {
            this.shipNoLogistics = res.data || []
          }
        } else {
          const res = await ShipmentMangeInteractor.queryLogisticByUnusual(
            params
          )

          if (res.code === '000000') {
            const data = res.data

            this.shipNoLogisticsId = params
            this.abnormalShipOrderNodes = data?.shipOrderLogistics || []
            this.abnormalShipNoNodes = data?.shipNoLogistics || []
            if (this.abnormalShipNoNodes.length) {
              this.tailTrackNodesDtos = this.abnormalShipNoNodes[0]
            }
            if (this.abnormalShipOrderNodes.length) {
              // 定位到异常位置
              let index = 0
              for (let i = 0; i < this.abnormalShipOrderNodes.length; i++) {
                if (this.abnormalShipOrderNodes[i].transportNumber === number) {
                  index = i
                  break
                }
              }
              this.active = String(index)
              this.handleClick(this.abnormalShipOrderNodes, index)
            }
            // 是否是异常待办
            if (
              this.abnormalShipOrderNodes.length ||
              this.abnormalShipNoNodes.length
            ) {
              this.isAbnormal = true
            }
          }
        }

        this.drawer = true
      },

      /**
       * 获取委托单尾程节点信息
       * @param {node} 节点名称
       * @param {item} 源数据
       */
      getTailNodesInfo(node, item) {
        const _info = item
          ? item?.data?.track_info
          : this.tailTrackNodesDtos?.data?.track_info

        if (_info) {
          if (node === 'company') {
            return _info?.tracking?.providers[0]?.provider?.name
          } else if (node === 'status') {
            return _info?.latest_status?.status
          } else if (node === 'cases') {
            return _info?.misc_info?.pieces
          } else {
            return _info?.tracking?.providers[0]?.events || []
          }
        } else {
          return ''
        }
      },

      // 切换尾程信息
      changeTrack(i, n) {
        this.cIndex = n
        this.tailTrackNodesDtos =
          this.shipOrderLogistics[i].tailTrackNodesDtos[n]
      },

      // 异常关闭
      async abnormalClose() {
        const res = await ShipmentMangeInteractor.logisticsUnusualHandle(
          this.shipNoLogisticsId
        )

        if (res.code === '000000') {
          this.$message.success('已关闭')
          this.handleClose()
          this.$emit('refresh')
        }
      },

      handleClose() {
        this.cIndex = 0
        this.active = '0'
        this.numbers = []
        this.shipNoLogistics = []
        this.shipOrderLogistics = []
        this.abnormalShipOrderNodes = []
        this.abnormalShipNoNodes = []
        this.tailTrackNodesDtos = []
        this.drawer = false
      },

      // tabs切换
      handleClick(source, n) {
        const logistics = source[n]

        this.tailTrackNodesDtos = logistics?.tailTrackNodesDtos?.[0] || []
        // 尾程单号集合
        if (logistics?.tailTrackNodesDtos?.length) {
          this.numbers = []
          for (let i = 0; i < logistics.tailTrackNodesDtos.length; i++) {
            this.numbers.push(logistics.tailTrackNodesDtos[i]?.data?.number)
          }
        }
      },

      getExpectArriveDate(expectArriveDate) {
        if (!expectArriveDate) return '--'
        expectArriveDate = expectArriveDate.split(' ')
        return expectArriveDate[0] || '--'
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-tabs {
    padding: 0 20px;
    .el-timeline {
      padding: 0 10px !important;
    }
  }
  .drawer-wrap {
    overflow-y: scroll;
    .top {
      margin: 10px 0 10px 0;
      color: #333;
      line-height: 26px;
      border-bottom: 1px solid #ccc;
      padding-bottom: 10px;
      p {
        span {
          margin-right: 30px;
        }
      }
    }
    .el-timeline {
      padding: 0 30px;
    }
    .el-timeline-item {
      padding-bottom: 5px;
    }
    .blue-sty {
      color: #409eff;
      cursor: pointer;
    }
    .red-sty {
      color: red;
    }
    .time-line {
      display: flex;
      .l {
        width: 180px;
      }
      .r {
        width: calc(100% - 180px);
      }
    }
  }
  .btn-close {
    position: sticky;
    width: 100%;
    height: 60px;
    background: white;
    z-index: 999;
    text-align: center;
    bottom: 0;
    padding-top: 15px;
  }
  ::v-deep {
    .el-timeline-item__content {
      color: #666;
    }
    .el-divider {
      margin: 20px 0 !important;
      background-color: #ededed;
    }
  }
</style>
